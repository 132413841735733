import Button from "@mui/material/Button"
import Box from "@mui/material/Box"
import Stack from "@mui/material/Stack"
import { PricesInputSimple } from "../createPage/PricesInput"
import PaperContainer from "../global/PaperContainer"
import Divider from "@mui/material/Divider"
import SelectWithCheckMark from "./inputs/SelectWithCheckMark"
import DealerActivity from "./dealerActivity"
import { Typography } from "@mui/material"
import { useState } from "react"
import { URL } from "../../config/apis"
import axios from "axios"
import { useParams } from "react-router-dom"
import Snackbar from "@mui/material/Snackbar"
import Alert from "@mui/material/Alert"
import SentToBox from "./SentToBox"
import useAuth from "../../context/Auth"
import AgnetBox from "../global/AgnetBox"
import { claimLead, unClaimLead } from "../../helppers/leadActions"
import PendingCounterOffers from "./PendingCounterOffers"
import AppointmentBox from "./appointmnets"
import { isAllowed } from "../../helppers/checkRoles"

export default function RightSide({
  lead,
  dActivity,
  setActivity,
  setLead,
  setDealerActivity,
  counters,
  setCounters,
  appointmnets,
  setAppointments,
}) {
  const { id } = useParams()
  const { user } = useAuth()
  const [prePrice, setPrePrice] = useState(lead?.prePrice || null)
  const [counterPrice, setCounterPrice] = useState(
    lead?.lastCounter?.amount || null
  )
  const [selected, setSelected] = useState([])
  const [selectedDealers, setSelectedDealers] = useState([])

  const [loading, setLoading] = useState(false)
  const [claiming, setClaiming] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [sending, setSending] = useState(false)
  const [show, setShow] = useState(false)
  const [err, setErr] = useState(null)

  const sendCounterOffer = async () => {
    if (!counterPrice || counterPrice === 0) {
      setErr("Please Type valid amount")
      return setTimeout(() => {
        setErr(null)
      }, 2000)
    }
    // if (counterPrice === lead?.lastCounter?.amount) {
    //   setErr("the same amount already sent, Please type different amount!")
    //   return setTimeout(() => {
    //     setErr(null)
    //   }, 2000)
    // }
    setErr(null)
    setLoading(true)
    setShow(false)
    try {
      const res = await axios.put(
        `${URL}/leads/item/${id}/counter`,
        { amount: counterPrice },
        {
          headers: {
            "Content-Type": "application/json",
          },
          withCredentials: true,
        }
      )
      if (res.data.ok) {
        setLead((prev) => ({
          ...prev,
          lastCounter: { ...res.data?.lastCounter },
        }))
        setActivity((prev) => [res.data.activity, ...prev])
        setCounters([])
      }
      setShow(true)
      setLoading(false)
      setTimeout(() => {
        setShow(false)
      }, 2000)
    } catch (error) {
      setLoading(false)
      setErr(error?.response?.data?.message)
      setTimeout(() => {
        setErr(null)
      }, 2000)
    }
  }

  const savePreNegPrice = async () => {
    if (!prePrice || prePrice === 0) {
      setErr("Please Type valid amount")
      return setTimeout(() => {
        setErr(null)
      }, 3000)
    }
    if (prePrice === lead?.prePrice) {
      setErr("the same amount already saved, Please type different amount!")
      return setTimeout(() => {
        setErr(null)
      }, 3000)
    }
    setErr(null)
    setIsLoading(true)
    setShow(false)
    try {
      const res = await axios.put(
        `${URL}/leads/item/${id}/pre-neg`,
        { prePrice: prePrice },
        {
          headers: {
            "Content-Type": "application/json",
          },
          withCredentials: true,
        }
      )
      if (res.data.ok) {
        setLead((prev) => ({
          ...prev,
          prePrice: res.data?.prePrice?.prePrice,
          sentAt: res.data?.prePrice?.sentAt,
        }))
        setActivity((prev) => [res.data.activity, ...prev])
      }
      setShow(true)
      setIsLoading(false)
      setTimeout(() => {
        setShow(false)
      }, 3000)
    } catch (error) {
      setIsLoading(false)
      setErr(error?.response?.data?.message)
      setTimeout(() => {
        setErr(null)
      }, 3000)
    }
  }

  const sendToDealers = async () => {
    if (selected?.length === 0) {
      setErr("Please select at least one dealership")
      return setTimeout(() => {
        setErr(null)
      }, 3000)
    }
    setErr(null)
    setSending(true)
    setShow(false)
    try {
      const res = await axios.put(
        `${URL}/leads/item/${id}/send-to-dealers`,
        { selectedDealers: selected },
        {
          headers: {
            "Content-Type": "application/json",
          },
          withCredentials: true,
        }
      )
      if (res.data.ok) {
        setLead((prev) => ({
          ...prev,
          lastActivity: Date.now(),
          sentTo: [...prev?.sentTo, ...res.data.dealers],
        }))
        setActivity((prev) => [res.data.activity, ...prev])
      }
      setShow(true)
      setSending(false)
      setSelected([])
      setSelectedDealers([])
      setTimeout(() => {
        setShow(false)
      }, 3000)
    } catch (error) {
      setSending(false)
      setErr(error?.response?.data?.message)
      setTimeout(() => {
        setErr(null)
      }, 3000)
    }
  }

  const unSendToDealers = async (did) => {
    setErr(null)
    setSending(true)
    setShow(false)
    try {
      const res = await axios.put(
        `${URL}/leads/item/${id}/unsend`,
        { dealership: did },
        {
          headers: {
            "Content-Type": "application/json",
          },
          withCredentials: true,
        }
      )
      if (res.data.ok) {
        setLead((prev) => ({
          ...prev,
          lastActivity: Date.now(),
          sentTo: [...prev?.sentTo?.filter((d) => d !== did)],
        }))
        setActivity((prev) => [res.data.activity, ...prev])
      }
      setShow(true)
      setSending(false)
      setTimeout(() => {
        setShow(false)
      }, 3000)
    } catch (error) {
      setSending(false)
      setErr(error?.response?.data?.message)
      setTimeout(() => {
        setErr(null)
      }, 3000)
    }
  }

  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: 2,
          width: "100%",
          paddingY: "10px",
        }}
      >
        <PaperContainer minHeight="max-content">
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: 2,
              width: "100%",
            }}
          >
            <Stack
              sx={{ width: "100%", paddingX: "20px" }}
              direction="row"
              spacing={2}
              alignItems="center"
            >
              {lead?.claimer ? (
                <>
                  {lead?.claimer === user?._id ? (
                    <>
                      <Button
                        onClick={() =>
                          unClaimLead(
                            lead?._id,
                            setLead,
                            setErr,
                            setClaiming,
                            setShow,
                            setActivity
                          )
                        }
                        disabled={claiming}
                        variant="contained"
                        color="error"
                      >
                        {claiming ? "UnClaiming..." : "UnClaim"}
                      </Button>
                    </>
                  ) : (
                    <>
                      <span style={{ width: "100%", flexGrow: 1 }}>
                        Claimed by: <AgnetBox id={lead?.claimer} />
                      </span>
                      <Button
                        sx={{ flex: 0 }}
                        color="success"
                        onClick={() =>
                          claimLead(
                            lead?._id,
                            setLead,
                            setErr,
                            setClaiming,
                            setShow,
                            setActivity
                          )
                        }
                        disabled={claiming}
                        variant="contained"
                      >
                        Claim
                      </Button>
                    </>
                  )}
                </>
              ) : (
                <>
                  <Button
                    color="success"
                    onClick={() =>
                      claimLead(
                        lead?._id,
                        setLead,
                        setErr,
                        setClaiming,
                        setShow,
                        setActivity
                      )
                    }
                    disabled={claiming}
                    variant="contained"
                  >
                    {claiming ? "Claiming..." : "Claim"}
                  </Button>
                </>
              )}
            </Stack>
            {/* <Divider />
            <Stack
              sx={{ width: "100%", paddingX: "20px" }}
              direction="row"
              spacing={2}
            >
              <PricesInputSimple
                lable="Negotiated Price"
                value={prePrice}
                onValueChange={setPrePrice}
              />
              <Button
                onClick={savePreNegPrice}
                disabled={isLoading}
                variant="contained"
                sx={{ minWidth: "max-content", flex: 0 }}
              >
                {isLoading ? "Saving ..." : "Save"}
              </Button>
            </Stack> */}
            {lead?.countredBy?.length > 0 && (
              <>
                <Divider />
                <Stack
                  sx={{ width: "100%", paddingX: "20px" }}
                  direction="row"
                  spacing={2}
                >
                  <PricesInputSimple
                    lable="Counter Offer"
                    value={counterPrice}
                    onValueChange={setCounterPrice}
                    disabled={
                      !isAllowed(user?.role, [
                        "super-admin",
                        "manager",
                        "admin",
                        "agent",
                      ])
                    }
                  />
                  <Button
                    onClick={sendCounterOffer}
                    disabled={
                      loading ||
                      !isAllowed(user?.role, [
                        "super-admin",
                        "manager",
                        "admin",
                        "agent",
                      ])
                    }
                    variant="contained"
                    sx={{ minWidth: "max-content", flex: 0 }}
                  >
                    {loading ? "Sending..." : "Send"}
                  </Button>
                </Stack>
              </>
            )}
            <Divider />

            <Stack
              sx={{ width: "100%", paddingX: "20px" }}
              direction="column"
              spacing={2}
            >
              {lead?.sentTo && lead?.sentTo?.length > 0 && (
                <SentToBox
                  list={lead?.sentTo}
                  unSendToDealers={unSendToDealers}
                  disabled={
                    !isAllowed(user?.role, [
                      "super-admin",
                      "manager",
                      "admin",
                      "agent",
                    ])
                  }
                />
              )}
              {isAllowed(user?.role, [
                "super-admin",
                "manager",
                "admin",
                "agent",
              ]) && (
                <>
                  <SelectWithCheckMark
                    selected={selected}
                    setSelected={setSelected}
                    selectedDealers={selectedDealers}
                    setSelectedDealers={setSelectedDealers}
                    list={lead?.sentTo}
                    lead={lead}
                  />
                  <div
                    style={{
                      display: "flex",
                      width: "100%",
                      justifyContent: "flex-end",
                      alignItems: "center",
                      gap: 10,
                    }}
                  >
                    {selected?.length > 0 && (
                      <span style={{ fontSize: "13px" }}>
                        Selected: {selected?.length} Dealerships
                      </span>
                    )}
                    <Button
                      sx={{ width: "max-content !important", flex: 0 }}
                      variant="contained"
                      onClick={sendToDealers}
                      disabled={sending}
                    >
                      {sending ? "Sending..." : "Send"}
                    </Button>
                  </div>
                </>
              )}
            </Stack>
          </Box>
        </PaperContainer>

        {!lead?.isDeal &&
          !lead?.acceptedBy &&
          counters &&
          counters?.length > 0 && (
            <PaperContainer minHeight="max-content">
              <Typography
                sx={{
                  display: "inline",
                  fontWeight: 600,
                  paddingX: "10px",
                  paddingY: "10px",
                }}
                component="span"
                variant="body1"
                color="text.primary"
              >
                Incoming Counter Offers
              </Typography>
              <PendingCounterOffers
                counters={counters}
                setCounters={setCounters}
                setLead={setLead}
                setActivity={setActivity}
                disabled={
                  !isAllowed(user?.role, [
                    "super-admin",
                    "manager",
                    "admin",
                    "agent",
                  ])
                }
              />
            </PaperContainer>
          )}
        {appointmnets && appointmnets?.length > 0 && (
          <PaperContainer minHeight="max-content">
            <Typography
              sx={{
                display: "inline",
                fontWeight: 600,
                paddingX: "10px",
                paddingY: "10px",
              }}
              component="span"
              variant="body1"
              color="text.primary"
            >
              Appointmnets
            </Typography>
            <AppointmentBox
              appointmnets={appointmnets}
              setAppointments={setAppointments}
              setActivity={setActivity}
              lead={lead}
            />
          </PaperContainer>
        )}

        {dActivity && dActivity?.length > 0 && (
          <PaperContainer minHeight="max-content">
            <Typography
              sx={{
                display: "inline",
                fontWeight: 600,
                paddingX: "10px",
                paddingY: "10px",
              }}
              component="span"
              variant="body1"
              color="text.primary"
            >
              Dealers Activity
            </Typography>
            <DealerActivity
              dActivity={dActivity}
              setActivity={setActivity}
              setDealerActivity={setDealerActivity}
              setLead={setLead}
            />
          </PaperContainer>
        )}
      </Box>
      <>
        <Snackbar
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
          open={loading || isLoading || sending}
          message="Please wait ..."
        />
        <Snackbar
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          autoHideDuration={3000}
          open={show}
          sx={{ minWidth: "200px" }}
        >
          <Alert
            color="success"
            severity="success"
            variant="filled"
            sx={{ width: "100%" }}
          >
            Success
          </Alert>
        </Snackbar>
        <Snackbar
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          autoHideDuration={3000}
          open={err !== null}
          sx={{ minWidth: "200px", maxWidth: "400px" }}
          severity="error"
        >
          <Alert
            color="error"
            severity="error"
            variant="filled"
            sx={{ width: "100%" }}
          >
            {err}
          </Alert>
        </Snackbar>
      </>
    </>
  )
}
