import * as React from "react"
import Button from "@mui/material/Button"
import Dialog from "@mui/material/Dialog"
import DialogActions from "@mui/material/DialogActions"
import DialogContent from "@mui/material/DialogContent"
import DialogContentText from "@mui/material/DialogContentText"
import DialogTitle from "@mui/material/DialogTitle"

export default function ConfirmModal({
  open,
  setOpen,
  handleDisable,
  loading,
}) {
  return (
    <Dialog
      open={open}
      //   onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        {"Are you sure you want to delete this agent?"}
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          By clicking on delete you will disable all agent access!
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button disabled={loading} onClick={() => setOpen(false)}>
          Cancel
        </Button>
        <Button
          disabled={loading}
          variant="contained"
          color="error"
          onClick={handleDisable}
        >
          {loading ? "Deleting..." : "Delete"}
        </Button>
      </DialogActions>
    </Dialog>
  )
}
