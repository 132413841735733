import { Avatar, ListItem, ListItemAvatar, ListItemText } from "@mui/material"
import { green, grey, red } from "@mui/material/colors"
import React, { useMemo } from "react"
import moment from "moment"
import CancelIcon from "@mui/icons-material/Cancel"
import BookmarkAddedIcon from "@mui/icons-material/BookmarkAdded"
import AssignmentIcon from "@mui/icons-material/Assignment"

export default function DefaultView({ item }) {
  const action = useMemo(() => {
    return JSON.parse(item?.action)
  }, [item?.action])
  return (
    <ListItem key={item?._id} alignItems="flex-start">
      <ListItemAvatar>
        <Avatar
          sx={{
            bgcolor: item?.type === "Deny" ? red[500] : grey[300],
            color: item?.type === "Deny" ? "white" : grey[700],
          }}
        >
          {item?.type === "Deny" ? (
            <CancelIcon />
          ) : item?.type === "Interested" ? (
            <BookmarkAddedIcon />
          ) : (
            <AssignmentIcon />
          )}
        </Avatar>
      </ListItemAvatar>
      <ListItemText
        primary={`${item?.createdBy} / ${item?.delaership?.dname}`}
        secondary={
          <React.Fragment>{`${action?.txt || action?.note} at ${moment(
            item?.createdAt
          ).format("MM/DD hA")}`}</React.Fragment>
        }
      />
    </ListItem>
  )
}
