import React from "react"
import { Avatar, Grid, Stack } from "@mui/material"
import moment from "moment"
import {
  AcceptCounter,
  AppointmentCreated,
  BidsSentLine,
  CounterSent,
  DealLine,
  FollowUpLine,
  GenerateOfferLine,
  GenerateOfferLineAPI,
  LeadLostLine,
  PreNegSent,
  RsentToDealers,
  SentToDealers,
  UnSentToDealers,
} from "./ActivityLines"
import Lightbox from "react-18-image-lightbox"

export default function ActivityBox({ activity }) {
  const [isOpen, setIsOpen] = React.useState(false)
  const [photoIndex, setPhotoIndex] = React.useState(0)

  const images = React.useMemo(() => {
    if (!activity?.images) return []
    return [...activity?.images?.map((x) => x?.url)]
  }, [activity])

  return (
    <>
      <Grid container wrap="nowrap" spacing={2}>
        <Grid item>
          <Avatar
            alt={activity?.createdBy}
            src={activity?.agent?.avatar?.url}
          />
        </Grid>
        <Grid justifyContent="left" item xs zeroMinWidth>
          <Stack direction={"row"} justifyContent="space-between" spacing={5}>
            <h4 style={{ margin: 0, textAlign: "left" }}>
              {activity?.createdBy}
            </h4>

            <p style={{ textAlign: "left", color: "gray" }}>
              Posted {moment(activity?.createdAt).format("MM/DD/YY @ h:mm A")}
            </p>
          </Stack>
          {activity?.type === "sentTo" ? (
            <SentToDealers str={activity?.comment} />
          ) : activity?.type === "Unsent" ? (
            <UnSentToDealers str={activity?.comment} />
          ) : activity?.type === "Reset_Lead" ? (
            <RsentToDealers str={activity?.comment} />
          ) : activity?.type === "Counter" ? (
            <CounterSent str={activity?.comment} />
          ) : activity?.type === "Appointment" ? (
            <AppointmentCreated str={activity?.comment} />
          ) : activity?.type === "Reschedule" ? (
            <AppointmentCreated str={activity?.comment} />
          ) : activity?.type === "Cancel" ? (
            <AppointmentCreated str={activity?.comment} />
          ) : activity?.type === "Pre-Price" ? (
            <PreNegSent str={activity?.comment} />
          ) : activity?.type === "Accepted Counter" ? (
            <AcceptCounter str={activity?.comment} />
          ) : activity?.type === "Deal" ? (
            <DealLine str={activity?.comment} />
          ) : activity?.type === "Follow-Up" ? (
            <FollowUpLine str={activity?.comment} />
          ) : activity?.type === "Bids Sent" ? (
            <BidsSentLine str={activity?.comment} />
          ) : activity?.type === "Lost" ? (
            <LeadLostLine str={activity?.comment} />
          ) : activity?.type === "Generate Offer" ? (
            <GenerateOfferLine str={activity?.comment} />
          ) : activity?.type === "Generate API Offer" ? (
            <GenerateOfferLineAPI str={activity?.comment} />
          ) : (
            <p style={{ textAlign: "left" }}>{activity?.comment}</p>
          )}
          {activity?.images && (
            <Stack
              direction={"row"}
              spacing={2}
              flexWrap="wrap"
              alignItems="center"
            >
              {activity?.images?.map((image, i) => (
                <div
                  key={image?.name + i}
                  style={{
                    position: "relative",
                    width: "100px",
                    height: "100px",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    setPhotoIndex(i)
                    setIsOpen(true)
                  }}
                >
                  <img
                    src={image?.url}
                    alt=""
                    style={{
                      width: "100%",
                      height: "100px",
                      objectFit: "cover",
                    }}
                  />
                </div>
              ))}
            </Stack>
          )}
        </Grid>
      </Grid>
      {isOpen && (
        <Lightbox
          mainSrc={images[photoIndex]}
          nextSrc={images[(photoIndex + 1) % images?.length]}
          prevSrc={images[(photoIndex + images?.length - 1) % images?.length]}
          onCloseRequest={() => {
            setIsOpen(false)
            setPhotoIndex(0)
          }}
          onMovePrevRequest={() =>
            setPhotoIndex(
              (prev) => (prev + images?.length - 1) % images?.length
            )
          }
          onMoveNextRequest={() =>
            setPhotoIndex((prev) => (prev + 1) % images?.length)
          }
          style={{ zIndex: 99999999 }}
        />
      )}
    </>
  )
}
