import {
  Box,
  Button,
  Dialog,
  DialogTitle,
  Divider,
  FormControlLabel,
  FormGroup,
  Grid,
  MenuItem,
  Stack,
  Switch,
  TextField,
} from "@mui/material"
import axios from "axios"
import React from "react"
import { URL } from "../../config/apis"
import useData from "../../context/Data"
import SelectMenu from "../createPage/SelectMenu"
import PricesInput from "../createPage/PricesInput"
import { isAllowed } from "../../helppers/checkRoles"
import useAuth from "../../context/Auth"

export default function EditDealershipModal({ data, getData, open, setOpen }) {
  const { user } = useAuth()
  const { markets, loading, setLoading, setShow, setErr, teams } = useData()

  const [dealership, setDealership] = React.useState({
    market: data?.market?._id,
    address: data?.address,
    dname: data?.dname,
    city: data?.city,
    zip: data?.zip || null,
    team: data?.team?._id,
    fee: data?.fee,
    isActive: data?.isActive,
    askFor: data?.askFor,
    email: data?.email,
  })

  const handleClose = (e, reason) => {
    if (reason !== "backdropClick") {
      setOpen(false)
    }
  }

  const handleChangeSelect = (e, name) => {
    setDealership((prev) => ({ ...prev, [name]: e }))
  }
  const onValueChange = (e, name) => {
    setDealership((prev) => ({ ...prev, [name]: e }))
  }

  const handleBackdropClick = (event) => {
    event.stopPropagation()
    return false
  }

  const updateDealership = async (e) => {
    e.preventDefault()

    try {
      const res = await axios.put(
        `${URL}/dealerships/profile/${data?._id}`,
        dealership,
        {
          headers: {
            "Content-Type": "application/json",
          },
          withCredentials: true,
        }
      )

      if (res.data.ok) {
        await getData()
        setShow(true)
        setOpen(false)
      } else {
        setErr("Something went wrong!")
      }
      setLoading(false)
      setTimeout(() => {
        setShow(false)
        setErr(null)
      }, 2000)
    } catch (error) {
      setLoading(false)
      setErr(error.response?.data?.message || error.message)
      setTimeout(() => {
        setErr(null)
      }, 3000)
    }
  }

  const disableEnter = (e) => {
    var key = e.charCode || e.keyCode || 0
    if (key == 13) {
      e.preventDefault()
    }
  }

  return (
    <Dialog
      onClose={handleClose}
      open={open}
      onBackdropClick={handleBackdropClick}
      disableEscapeKeyDown
    >
      <DialogTitle>Update Dealership</DialogTitle>
      <Box
        component="form"
        sx={{
          "& > :not(style)": { width: "100%" },
          display: "flex",
          flexDirection: "column",
          gap: ".5rem",
        }}
        noValidate
        autoComplete="off"
        onSubmit={updateDealership}
        onKeyPress={disableEnter}
      >
        <Grid container spacing={3} columns={4} sx={{ px: 4, py: 2 }}>
          <Grid item sm={4}>
            <TextField
              size="small"
              id="outlined-basic"
              label="Dealership Name"
              variant="outlined"
              fullWidth
              value={dealership?.dname}
              onChange={(e) => onValueChange(e.target.value, "dname")}
            />
          </Grid>

          <Grid item sm={4}>
            <TextField
              size="small"
              id="outlined-basic"
              label="Dealership Email"
              variant="outlined"
              fullWidth
              required={false}
              value={dealership?.email}
              onChange={(e) => onValueChange(e.target.value, "email")}
            />
          </Grid>
          <Grid item sm={4}>
            <TextField
              size="small"
              id="outlined-basic"
              label="Dealership Address"
              variant="outlined"
              fullWidth
              value={dealership?.address}
              onChange={(e) => onValueChange(e.target.value, "address")}
            />
          </Grid>
          <Grid item sm={2}>
            <TextField
              size="small"
              id="outlined-basic"
              label="City"
              variant="outlined"
              fullWidth
              value={dealership?.city}
              onChange={(e) => onValueChange(e.target.value, "city")}
            />
          </Grid>
          <Grid item sm={2}>
            <TextField
              size="small"
              id="outlined-basic"
              label="ZIP"
              variant="outlined"
              fullWidth
              value={dealership?.zip}
              onChange={(e) => onValueChange(e.target.value, "zip")}
            />
          </Grid>
          <Grid item sm={4}>
            <TextField
              size="small"
              id="outlined-basic"
              label="Ask For"
              variant="outlined"
              fullWidth
              value={dealership?.askFor}
              onChange={(e) => onValueChange(e.target.value, "askFor")}
            />
          </Grid>

          <Grid item sm={2}>
            <SelectMenu
              sx={{ minWidth: 150 }}
              label="Market"
              options={markets}
              value={dealership?.market}
              handleChange={handleChangeSelect}
              name={"market"}
            >
              {markets?.map((o, i) => (
                <MenuItem key={o?._id + i} value={o?._id}>
                  {o?.name}
                </MenuItem>
              ))}
            </SelectMenu>
          </Grid>
          {isAllowed(user?.role, ["super-admin"]) && (
            <Grid item sm={2}>
              <SelectMenu
                sx={{ minWidth: 250 }}
                label="Team"
                options={teams}
                value={dealership?.team}
                handleChange={handleChangeSelect}
                name={"team"}
              >
                {teams &&
                  teams?.map((o, i) => (
                    <MenuItem key={o._id + i} value={o._id}>
                      {o.name}
                    </MenuItem>
                  ))}
              </SelectMenu>
            </Grid>
          )}
          {isAllowed(user?.role, ["super-admin"]) && (
            <Grid item sm={2}>
              <PricesInput
                lable="Fee"
                value={dealership?.fee}
                onValueChange={onValueChange}
                name="fee"
              />
            </Grid>
          )}
          {isAllowed(user?.role, ["super-admin"]) && (
            <Grid item sm={2}>
              <FormGroup>
                <FormControlLabel
                  control={
                    <Switch
                      checked={dealership?.isActive}
                      onChange={(e) =>
                        onValueChange(e.target.checked, "isActive")
                      }
                    />
                  }
                  label="Is Active?"
                />
              </FormGroup>
            </Grid>
          )}
        </Grid>
        {
          <>
            <Divider />
            <Stack
              direction={"row"}
              justifyContent="end"
              alignItems={"center"}
              spacing={2}
              px={2}
              pb={2}
            >
              <Button
                type="button"
                onClick={handleClose}
                variant="contained"
                color="error"
                sx={{ bgcolor: "#CCC" }}
                disabled={loading}
              >
                Close
              </Button>
              <Button
                disabled={loading}
                type="submit"
                variant="contained"
                color="success"
              >
                Update
              </Button>
            </Stack>
          </>
        }
      </Box>
    </Dialog>
  )
}
