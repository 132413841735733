import {
  Button,
  Container,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material"
import React from "react"
import { CSVLink } from "react-csv"
import useData from "../../../context/Data"

const headers = [
  { label: "Dealership", key: "dname" },
  { label: "Leads Sent", key: "sentCount" },
  { label: "Interested", key: "interstedCount" },
  { label: "Bids", key: "counteredCount" },
  { label: "Accepted", key: "acceptedCount" },
  { label: "Appointments", key: "appointmentsCount" },
  { label: "Closed Deals", key: "dealsCount" },
  { label: "Declined", key: "declinedCount" },
]

export default function DataResults({
  results,
  rangeData,
  start,
  end,
  type,
  selected,
}) {
  const { dealerships } = useData()

  const dealer = React.useMemo(() => {
    return dealerships?.find((d) => d?._id === selected)
  }, [results, dealerships])

  const csvReport = {
    data: results?.map((r) => ({
      ...r,
      dname: type === "Dealerships" ? dealer?.dname : r?.dname,
    })),
    headers: headers,
    filename: `${selected}-Report-${rangeData?.start || start}-to-${
      rangeData?.end || end
    }-${new Date().toLocaleDateString()}.csv`,
    target: "_blank",
  }

  // const count = React.useMemo(() => {
  //   if (!results || results?.length === 0)
  //     return {
  //       count: 0,
  //       start: rangeData?.start || start,
  //       end: rangeData?.end || end,
  //       type: type,
  //     }

  //   return {
  //     count: results?.map((r) => r.total)?.reduce((a, b) => a + b, 0),
  //     start: rangeData?.start || start,
  //     end: rangeData?.end || end,
  //     type: type,
  //   }
  // }, [results])

  return (
    <Container maxWidth="100%">
      <Stack mb={2} direction={"row"} spacing={3} alignItems="center">
        <Typography>
          {type === "Dealerships" ? <b>{dealer?.dname}</b> : type} Report from{" "}
          <b>{rangeData?.start || start}</b> to <b>{rangeData?.end || end}</b>
        </Typography>

        <Button variant="contained" color="success">
          <CSVLink
            {...csvReport}
            target="_blank"
            style={{ textDecoration: "none", color: "inherit" }}
          >
            Export to CSV
          </CSVLink>
        </Button>
      </Stack>
      <TableContainer sx={{}} component={Paper}>
        <Table sx={{}} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Dealership</TableCell>
              <TableCell align="center">Leads Sent</TableCell>
              <TableCell align="center">Interested</TableCell>
              <TableCell align="center">Bids</TableCell>
              <TableCell align="center">Accepted</TableCell>
              <TableCell align="center">Appointments</TableCell>
              <TableCell align="center">Closed Deals</TableCell>
              <TableCell align="center">Declined</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {results &&
              results?.map((a, i) => (
                <TableRow
                  key={a?._id + i}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell component="th" scope="row">
                    {type === "Dealerships" ? dealer?.dname : a?.dname}
                  </TableCell>
                  <TableCell align="center">{a?.sentCount}</TableCell>
                  <TableCell align="center">{a?.interstedCount}</TableCell>
                  <TableCell align="center">{a?.counteredCount}</TableCell>
                  <TableCell align="center">{a?.acceptedCount}</TableCell>
                  <TableCell align="center">{a?.appointmentsCount}</TableCell>
                  <TableCell align="center">{a?.dealsCount}</TableCell>
                  <TableCell align="center">{a?.declinedCount}</TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Container>
  )
}
