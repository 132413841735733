import {
  Container,
  Divider,
  Pagination,
  Stack,
  Typography,
} from "@mui/material"
import axios from "axios"
import React, { useEffect, useMemo } from "react"
import { useState } from "react"
import { useParams } from "react-router-dom"
import UserDetails from "../components/agent/UserDetails"
import { URL } from "../config/apis"
import EditAgent from "../components/agent/EditAgent"
import useAuth from "../context/Auth"
import { isAllowed } from "../helppers/checkRoles"
import AgentLogs from "../components/agent/AgentLogs"
import DateRangeBox from "../components/dealership/DateRangeBox"
import moment from "moment"

const formatDate = (date) => {
  if (!date) return null
  const formated = moment(date)

  if (formated.isValid()) {
    return formated.format("MM/DD/YYYY")
  }
  return null
}

const pageSize = 40

export default function AgentPage() {
  const { user } = useAuth()
  const { id } = useParams()

  const [agent, setAgent] = useState(null)
  const [loading, setLoading] = useState(true)
  const [isLoading, setIsLoading] = useState(true)
  const [error, setError] = useState(null)
  const [err, setErr] = useState(null)
  const [open, setOpen] = useState(false)
  const [activity, setActivity] = useState([])
  const [range, setRange] = useState(null)
  const [count, setCount] = useState(0)
  const [page, setPage] = useState(1)
  const [dateRange, setDateRange] = React.useState([null, null])

  const [startDate, endDate] = dateRange

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  const totalPages = useMemo(() => {
    return Math.ceil(count / pageSize)
  }, [count, pageSize])

  const getAgent = async () => {
    try {
      const res = await axios.get(`${URL}/agents/user/${id}`, {
        headers: {
          "Content-Type": "application/json",
        },
        withCredentials: true,
      })
      setAgent(res.data)
      setLoading(false)
    } catch (error) {
      setLoading(false)
      setErr(error?.response?.data?.message || error.message)
    }
  }

  useEffect(() => {
    getAgent()
  }, [])

  const getAgentLogs = async () => {
    const start = formatDate(startDate)
    const end = formatDate(endDate)
    setIsLoading(true)
    setError(null)
    try {
      const res = await axios.get(
        `${URL}/agents/user/${id}/report?start=${start}&end=${end}&page=${page}`,
        {
          headers: {
            "Content-Type": "application/json",
          },
          withCredentials: true,
        }
      )
      setActivity(res.data?.activity)
      setRange(res.data?.range)
      setCount(res.data?.count)
      setIsLoading(false)
    } catch (error) {
      setIsLoading(false)
      setError(error?.response?.data?.message || error.message)
    }
  }

  useEffect(() => {
    if (startDate && !endDate) return
    if (!startDate && endDate) return
    if (agent) {
      getAgentLogs()
    }
  }, [agent, startDate, endDate, page])

  if (loading) {
    return <>Loading...</>
  }

  if (!loading && err) {
    return <>{err}</>
  }

  return (
    <>
      <Stack direction={"row"} spacing={5}>
        <Stack flex={9}>
          <UserDetails
            user={agent}
            setOpen={setOpen}
            open={open}
            setState={setAgent}
          />
          <br />
          {open &&
            isAllowed(user?.role, ["super-admin", "admin", "manager"]) &&
            user?._id !== agent?._id && (
              <EditAgent
                agent={agent}
                setOpen={setOpen}
                open={open}
                setState={setAgent}
              />
            )}
        </Stack>
        <Stack flex={5}>
          <Stack
            direction={"row"}
            alignItems={"center"}
            justifyContent={"space-between"}
          >
            <Typography variant="h6" fontWeight={"600"}>
              Recent Activity
            </Typography>
            {range && range?.start && range?.end && (
              <Typography variant="subtitle2">
                {range?.start}
                {" - "}
                {range?.end}
              </Typography>
            )}
            <DateRangeBox
              endDate={endDate}
              setDateRange={setDateRange}
              startDate={startDate}
              setPage={setPage}
            />
          </Stack>
          <Divider />
          <AgentLogs err={error} activity={activity} isLoading={isLoading} />
          {totalPages > 0 && (
            <Stack justifyContent={"center"} alignItems={"center"} py={1}>
              <Pagination
                page={page}
                onChange={handleChangePage}
                count={totalPages}
                //showFirstButton
                //showLastButton
              />
            </Stack>
          )}
        </Stack>
      </Stack>
    </>
  )
}
