import axios from "axios"
import { createContext, useContext, useEffect, useMemo, useState } from "react"
import FeedBackBox from "../components/global/FeedBackBox"
import { URL } from "../config/apis"

const defaultData = [
  "646bb75c8a96b766adf29a6d",
  "646bb83b8a96b766adf29b4d",
  "646cbc2ebb83ccc70dfecc65",
  "646bbbbb8a96b766adf2a12b",
  "646bbb478a96b766adf2a114",
  "636a77e66d6d28518f8daf36",
  "63b45972eed89dde688432b9",
  "6329fa19783d7e09911a2393",
]

const DataContext = createContext()

export const DataContextProvider = ({ children }) => {
  const [ep_markets, setEp_Markets] = useState([])
  const [markets, setMarkets] = useState([])
  const [dealerships, setDealerships] = useState([])
  const [orgs, setOrgs] = useState([])
  const [agents, setAgents] = useState([])
  const [teams, setTeams] = useState([])
  const [usTzs, setUsTzs] = useState([])
  const [loading, setLoading] = useState(false)
  const [show, setShow] = useState(false)
  const [err, setErr] = useState(null)

  const getCurrentUser = async () => {
    try {
      const [m, d, a, t, g] = await Promise.all([
        axios.get(`${URL}/markets`, {
          withCredentials: true,
        }),
        axios.get(`${URL}/dealerships/get`, {
          withCredentials: true,
        }),
        axios.get(`${URL}/agents`, {
          withCredentials: true,
        }),
        axios.get(`${URL}/constants/tz`, {
          withCredentials: true,
        }),
        axios.get(`${URL}/teams`, {
          withCredentials: true,
        }),
      ])
      setMarkets(m.data)
      setDealerships(d.data.dealerships)
      setOrgs(d.data.organisations)
      setAgents(a.data)
      setUsTzs(t.data)
      setTeams(g.data)
    } catch (error) {}
  }

  const getSettingData = async () => {
    try {
      const { data } = await axios.get(`${URL}/settings?key=EP_MARKETS`, {
        withCredentials: true,
      })
      if (data) {
        setEp_Markets(data)
      } else {
        setEp_Markets(defaultData)
      }
    } catch (error) {}
  }

  useEffect(() => {
    getCurrentUser()
    getSettingData()
  }, [])

  const memoedValue = useMemo(
    () => ({
      markets,
      setMarkets,
      dealerships,
      setDealerships,
      orgs,
      setOrgs,
      agents,
      setAgents,
      usTzs,
      setUsTzs,
      loading,
      setLoading,
      show,
      setShow,
      err,
      setErr,
      teams,
      setTeams,
      ep_markets,
      setEp_Markets,
    }),
    [markets, dealerships, orgs, agents, usTzs, loading, show, err, teams, ep_markets]
  )

  return (
    <DataContext.Provider value={memoedValue}>
      <>
        {children}
        <FeedBackBox show={show} loading={loading} err={err} />
      </>
    </DataContext.Provider>
  )
}

export default function useData() {
  return useContext(DataContext)
}
