import {
  Container,
  Divider,
  MenuItem,
  Paper,
  Stack,
  Typography,
} from "@mui/material"
import React from "react"
import moment from "moment"
import { formatPrice } from "../../../helppers/formatters"
import useData from "../../../context/Data"
import SelectMenu from "../../createPage/SelectMenu"

export default function OfferBox({
  theOffer,
  dealer,
  setDealer,
  lead,
  ...props
}) {
  const { dealerships } = useData()

  const available = React.useMemo(() => {
    return dealerships?.filter(
      (d) =>
        d?.organisation === "646bb68d8a96b766adf29a18" &&
        d?.market === lead?.market?._id &&
        d?.isActive
    )
  }, [dealerships])

  return (
    <Stack width={"100%"} spacing={1} {...props}>
      <Paper
        sx={{ py: 3, gap: 2, display: "flex", flexDirection: "column" }}
        component={Container}
        maxWidth="sm"
      >
        {!!theOffer?.finalOffer &&
        !isNaN(theOffer?.finalOffer) &&
        theOffer?.finalOffer > 0 ? (
          <Stack spacing={1} justifyContent={"center"} alignItems={"center"}>
            <Typography fontWeight={"600"} variant="h4">
              Generated Offer
            </Typography>
            <Typography color={"green"} fontWeight={"700"} variant="h2">
              {formatPrice(theOffer?.finalOffer)}
            </Typography>
            <Typography>
              Expires in{" "}
              {moment(theOffer?.offerValidUntilDate).format(
                "MM/DD/YYYY @ h:m A"
              )}{" "}
              ({theOffer?.offerValidUntilDays} Days)
            </Typography>
          </Stack>
        ) : (
          <Stack spacing={1} justifyContent={"center"} alignItems={"center"}>
            <Typography fontWeight={"600"} variant="h4">
              {theOffer?.finalOffer}
            </Typography>
            {!!theOffer?.offerMessage?.message && (
              <Typography color={"red"} variant="body1">
                {theOffer?.offerMessage?.message}
              </Typography>
            )}
          </Stack>
        )}
        <Divider />
        <Stack spacing={1} justifyContent={"center"} alignItems={"center"}>
          <Typography fontWeight={"700"} variant="h5">
            Suggested Store: {theOffer?.mappedStore}
          </Typography>
        </Stack>
        <Divider />
        <SelectMenu
          handleChange={(v) => setDealer(v)}
          label={"Dealership"}
          name={"dealership"}
          value={dealer}
          options={available}
          sx={{ minWidth: 150 }}
          maxHeight={300}
          disabled={isNaN(theOffer?.finalOffer) || theOffer?.finalOffer < 0}
        >
          {available &&
            available?.map((d, i) => (
              <MenuItem key={d?._id + i} value={d?._id}>
                {d?.dname}
              </MenuItem>
            ))}
        </SelectMenu>
      </Paper>
    </Stack>
  )
}
