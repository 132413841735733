import {
  Box,
  Button,
  Container,
  Grid,
  MenuItem,
  Paper,
  Stack,
  TextField,
  Typography,
} from "@mui/material"
import axios from "axios"
import React from "react"
import SelectMenu from "../components/createPage/SelectMenu"
import MarketCard from "../components/market/MarketCard"
import { URL } from "../config/apis"
import { usStatesList } from "../constants/constants"
import useData from "../context/Data"

const INIT_STATE = { name: "", state: null, team: null }

export default function MarketsPage() {
  const { markets, setMarkets, loading, setLoading, setShow, setErr, teams } =
    useData()
  const [open, setOpen] = React.useState(false)
  const [market, setMarket] = React.useState(INIT_STATE)

  const CreateNewMarket = async () => {
    if (market?.name?.trim() === "" || market?.state == null) return
    setLoading(true)
    try {
      const res = await axios.post(`${URL}/markets`, market, {
        headers: {
          "Content-Type": "application/json",
        },
        withCredentials: true,
      })
      setLoading(false)
      if (res.data.ok) {
        setMarkets((prev) => [res.data.market, ...prev])
        setShow(true)
        setOpen(false)
        setMarket(INIT_STATE)
        setTimeout(() => {
          setShow(false)
        }, 2000)
      } else {
        setErr(res.data.message)
        setTimeout(() => {
          setErr(null)
        }, 3000)
      }
    } catch (error) {
      setLoading(false)
      setErr(error.response?.data?.message || error?.message)
      setTimeout(() => {
        setErr(null)
      }, 3000)
    }
  }

  return (
    <>
      <Container maxWidth="100%">
        <Box>
          <Stack
            direction={"row"}
            spacing={5}
            alignItems="center"
            justifyContent={"space-between"}
          >
            <Typography variant="h6">Markets</Typography>
            <div
              style={{
                display: "flex",
                justifyContent: "end",
                gap: 5,
                alignItems: "center",
              }}
            >
              <Button
                onClick={() => setOpen((prev) => !prev)}
                variant="contained"
                color={open ? "error" : "primary"}
                disabled={loading}
              >
                {open ? "Close" : "New Market"}
              </Button>
            </div>
          </Stack>
          {open && (
            <Paper variant="outlined" sx={{ mt: 2 }}>
              <Stack
                px={3}
                py={2}
                direction={"row"}
                alignItems="center"
                spacing={2}
              >
                <TextField
                  size="small"
                  id="outlined-basic"
                  label="Market Name"
                  variant="outlined"
                  value={market?.name}
                  onChange={(e) =>
                    setMarket((prev) => ({ ...prev, name: e.target.value }))
                  }
                  fullWidth
                  sx={{ maxWidth: "350px" }}
                />
                <SelectMenu
                  sx={{ minWidth: 200 }}
                  label="State"
                  options={usStatesList}
                  value={market?.state}
                  handleChange={(e) =>
                    setMarket((prev) => ({ ...prev, state: e }))
                  }
                  name={"state"}
                >
                  {usStatesList &&
                    usStatesList?.map((o, i) => (
                      <MenuItem key={o.code + i} value={o.code}>
                        {o.name}
                      </MenuItem>
                    ))}
                </SelectMenu>
                <SelectMenu
                  sx={{ minWidth: 250 }}
                  label="Team"
                  options={teams}
                  value={market?.team}
                  handleChange={(e) =>
                    setMarket((prev) => ({ ...prev, team: e }))
                  }
                  name={"team"}
                >
                  {teams &&
                    teams?.map((o, i) => (
                      <MenuItem key={o._id + i} value={o._id}>
                        {o.name}
                      </MenuItem>
                    ))}
                </SelectMenu>
                <Button
                  onClick={CreateNewMarket}
                  variant="contained"
                  color={"success"}
                  disabled={
                    loading ||
                    market?.name?.trim() === "" ||
                    market?.state == null
                  }
                >
                  {loading ? "Creating..." : "Create"}
                </Button>
              </Stack>
            </Paper>
          )}

          <Grid py={3} container spacing={3}>
            {markets?.map((m, i) => (
              <Grid key={m?._id + i} xs={6} md={4} xl={3} item>
                <MarketCard market={m} />
              </Grid>
            ))}
          </Grid>
        </Box>
      </Container>
    </>
  )
}
