export const LEAD_INITIAL_STATE = {
  vin: "",
  mileage: null,
  zip: null,
  year: null,
  make: "",
  model: "",
  trim: "",
  transmission: {
    short: "",
    full: "",
  },
  engine: {
    short: "",
    full: "",
  },
  type: "",
  extColor: "",
  intColor: "",
  titleStatus: null,
  fbmpId: null,
  otherSource: null,
  accuTrade: "",
  msrp: null,
  listedPrice: null,
  mmr: null,
  cgimv: null,
  mds: null,
  mdom: null,
  kbbppv: null,
  sellerName: "",
  sellerPhone: "",
  city: "",
  fb_name: "",
  state: null,
  market: null,
  condition: {},
  isRetail: false,
  retailNote: "",
  f_image: null,
  images: [],
  carfax: [],
  isCleanCfx: false,
  isCleanAutocheck: true,
  carfax_data: {
    minor: false,
    moderate: false,
    severe: false,
    branded: false,
  },
}
