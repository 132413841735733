import { Container, Divider, Paper, Stack, Typography } from "@mui/material"
import React from "react"
import { Outlet } from "react-router-dom"
import AccountSideBar from "../components/profile/AccountSideBar"
import UserDetails from "../components/profile/UserDetails"

export default function ProfilePage() {
  return (
    <Container maxWidth="lg">
      <Stack direction={{ xs: "column", lg: "row" }} spacing={2}>
        <UserDetails />
        <Paper component={Stack} flex={{ xs: 1, lg: 5 }} width='100%'>
          {/* nav */}
          <AccountSideBar />
          <Outlet />
        </Paper>
      </Stack>
    </Container>
  )
}
