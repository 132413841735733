import { Divider, Stack } from "@mui/material"
import React from "react"
import PhotoLibraryIcon from "@mui/icons-material/PhotoLibrary"

const styles = {
  container: {
    display: "flex",
    flexDirection: "column",
    gap: 1,
    width: "100%",
  },
  title: {
    display: "flex",
    alignItems: "center",
    gap: 5,
    width: "100%",
    cursor: "pointer",
  },
}

export default function TopTitleLeftSide({ handleClick, lead, setIsOpen }) {
  return (
    <div style={styles.container}>
      <div onClick={() => setIsOpen(true)} style={styles.title}>
        <h3>{lead?.title}</h3>
        <PhotoLibraryIcon />
      </div>
      <Stack
        direction="row"
        divider={<Divider orientation="vertical" flexItem />}
        spacing={1}
      >
        <span>{lead?.vin}</span>
        <span>{lead?.mileage?.toLocaleString()} miles</span>
        {lead?.zip && <span>ZIP: {lead?.zip}</span>}
        <span
          style={{ cursor: "pointer" }}
          onClick={handleClick("engine", lead?.engine?.full)}
        >
          {lead?.engine?.short}
        </span>
        <span
          style={{ cursor: "pointer" }}
          onClick={handleClick("transmission", lead?.transmission?.full)}
        >
          {lead?.transmission?.short}
        </span>
      </Stack>
    </div>
  )
}
