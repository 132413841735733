import moment from "moment"

export const formatPrice = (price, sign = false) => {
  if (typeof price === "undefined") return "N/A"
  if (isNaN(price)) return "N/A"
  if (price === 0 && sign) return "0"
  if (price === 0 && !sign) return "N/A"
  const formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
    minimumFractionDigits: 0,
    signDisplay: sign ? "always" : "auto",
  })
  return formatter.format(price)
}

export const formatDate = (date, format = "MM/DD/YYYY") => {
  return moment(date).format(format)
}

export const formatPhoneNumber = (phoneNumberString) => {
  if (!phoneNumberString) return "N/A"
  if (phoneNumberString?.length < 11) {
    phoneNumberString = "1" + phoneNumberString
  }
  var cleaned = ("" + phoneNumberString).replace(/\D/g, "")
  var match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/)
  if (match) {
    var intlCode = match[1] ? "+1 " : ""
    return [intlCode, "(", match[2], ") ", match[3], "-", match[4]].join("")
  }
  return null
}
