import React from "react"
import PaperWrrapper from "./PaperWrrapper"
import Grid from "@mui/material/Grid"
import { Chip, Stack } from "@mui/material"

export default function AutoCheckBox({ data }) {
  return (
    <PaperWrrapper title="AutoCheck">
      <Grid item>
        <Stack direction={"row"} gap={2} flexWrap="wrap">
          {typeof data?.hasAccidents !== "undefined" ? (
            <Chip
              label={!data?.hasAccidents ? "Clean" : "Not Clean"}
              color={!data?.hasAccidents ? "success" : "error"}
              sx={{ px: 0.5, py: 0.5 }}
            />
          ) : (
            <Chip
              label={`Accident Reported: ${data?.accidentCount} `}
              color={data?.accidentCount === 0 ? "success" : "error"}
              sx={{ px: 0.5, py: 0.5 }}
            />
          )}
          <Chip
            label={`Salvage Title Check: ${
              data?.salvage ? "Salvage" : "No Salvage"
            } `}
            color={data?.salvage ? "error" : "success"}
            sx={{ px: 0.5, py: 0.5 }}
          />
          {typeof data?.titleBrand !== "undefined" ? (
            <Chip
              label={`Title Brand Check: ${
                data?.titleBrand ? "Title Brand" : "No Title Brand"
              } `}
              color={data?.titleBrand ? "error" : "success"}
              sx={{ px: 0.5, py: 0.5 }}
            />
          ) : (
            <Chip
              label={`Title Brand Check: ${
                data?.titleBrands?.length > 0
                  ? data?.titleBrands?.join(", ")
                  : "No Title Brand"
              } `}
              color={data?.titleBrands?.length > 0 ? "error" : "success"}
              sx={{ px: 0.5, py: 0.5 }}
            />
          )}
          <Chip
            label={`Flood Damage Check: ${data?.floodDamage ? "Yes" : "No"} `}
            color={data?.floodDamage ? "error" : "success"}
            sx={{ px: 0.5, py: 0.5 }}
          />
          <Chip
            label={`Frame Damage Check: ${data?.frameDamage ? "Yes" : "No"} `}
            color={data?.frameDamage ? "error" : "success"}
            sx={{ px: 0.5, py: 0.5 }}
          />
          <Chip
            label={`Canadian Check: ${
              data?.previouslyCanadian ? "Yes" : "No"
            } `}
            color={data?.previouslyCanadian ? "error" : "success"}
            sx={{ px: 0.5, py: 0.5 }}
          />
          <Chip
            label={`Taxi Use: ${data?.taxiUse ? "Yes" : "No"} `}
            color={data?.taxiUse ? "error" : "success"}
            sx={{ px: 0.5, py: 0.5 }}
          />
          <Chip
            label={`Used As Rental Car: ${
              data?.usedAsRentalCar ? "Yes" : "No"
            } `}
            color={data?.usedAsRentalCar ? "error" : "success"}
            sx={{ px: 0.5, py: 0.5 }}
          />
          <Chip
            label={`Police Use: ${data?.policeUse ? "Yes" : "No"} `}
            color={data?.policeUse ? "error" : "success"}
            sx={{ px: 0.5, py: 0.5 }}
          />
          <Chip
            label={`Government Use: ${data?.governmentUse ? "Yes" : "No"} `}
            color={data?.governmentUse ? "error" : "success"}
            sx={{ px: 0.5, py: 0.5 }}
          />
          <Chip
            label={`Not Actual Mileage: ${
              data?.notActualMileage ? "Yes" : "No"
            } `}
            color={data?.notActualMileage ? "error" : "success"}
            sx={{ px: 0.5, py: 0.5 }}
          />
          <Chip
            label={`Last Recorded Odometer: ${
              (data?.miles || data?.lastOdometer)?.toLocaleString() || "N/A"
            } `}
            sx={{ px: 0.5, py: 0.5 }}
          />
          {/* <Chip
            label={`Last Title State: ${data?.lastTitleState || "N/A"} `}
            sx={{ px: 0.5, py: 0.5 }}
          /> */}
        </Stack>
      </Grid>
    </PaperWrrapper>
  )
}
