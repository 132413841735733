import { Avatar, Badge, IconButton, Tooltip } from "@mui/material"
import React from "react"
import useAuth from "../../context/Auth"
import AddCircleIcon from "@mui/icons-material/AddCircle"
import { uploadImageAvatar } from "../../helppers/images/profileImage"

export default function ProfileImage() {
  const { user, setUser } = useAuth()
  const [loading, setLoading] = React.useState(false)
  const imgRef = React.useRef()

  const handleImageUpload = async (file) => {
    if (!file) return
    setLoading(true)
    try {
      await uploadImageAvatar(file, user?.avatar, setUser)
    } catch (error) {
      console.log(error)
    } finally {
      setLoading(false)
      if (imgRef.current) {
        imgRef.current.value = ""
        imgRef.current.files = []
      }
    }
  }

  return (
    <div>
      <Badge
        overlap="circular"
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        badgeContent={
          <Tooltip
            title="Upload image formats .png/.jpg/.jpeg"
            placement="right"
          >
            <IconButton
              color="success"
              aria-label="upload picture"
              component="label"
              sx={{ bgcolor: "white", p: "2px" }}
            >
              <input
                hidden
                ref={imgRef}
                onChange={(e) => handleImageUpload(e.target.files[0])}
                accept="image/png, image/jpeg"
                type="file"
              />
              <AddCircleIcon />
            </IconButton>
          </Tooltip>
        }
      >
        <Avatar
          alt={user?.fname}
          src={user?.avatar?.url}
          sx={{ width: 100, height: 100, opacity: loading ? 0.3 : 1 }}
        />
      </Badge>
    </div>
  )
}
