import * as React from "react"
import { styled } from "@mui/material/styles"
import SpeedDial from "@mui/material/SpeedDial"
import SpeedDialAction from "@mui/material/SpeedDialAction"
import EditIcon from "@mui/icons-material/Edit"
import DoNotDisturbIcon from "@mui/icons-material/DoNotDisturb"
import { useNavigate } from "react-router-dom"
import { moveToLostSold, moveToTrash } from "../../helppers/leadActions"
import PopUpDialogDeal from "../modals/PopUpDialogDeal"
import FeedBackBox from "../global/FeedBackBox"
import PopUpDialogAppointment from "../modals/PopUpDialogAppointment"
import { isAllowed } from "../../helppers/checkRoles"
import useAuth from "../../context/Auth"
import RestartAltIcon from "@mui/icons-material/RestartAlt"
import ConfirmModal from "../modals/ConfirmModal"
import { URL } from "../../config/apis"
import axios from "axios"
import PopUpConfirm from "../global/PopUpConfirm"
import { Stack } from "@mui/material"
import SelectWithCheckBox from "../leadPage/inputs/SelectWithCheckBox"

const StyledSpeedDial = styled(SpeedDial)(({ theme }) => ({
  // position: "absolute",
  //   "&.MuiSpeedDial-directionUp, &.MuiSpeedDial-directionLeft": {
  //     bottom: theme.spacing(2),
  //     right: theme.spacing(2),
  //   },
  //   "&.MuiSpeedDial-directionDown, &.MuiSpeedDial-directionRight": {
  //     top: theme.spacing(2),
  //     left: theme.spacing(2),
  //   },
  " .css-118zhtq-MuiButtonBase-root-MuiFab-root-MuiSpeedDial-fab": {
    width: 40,
    height: 40,
  },
}))
const x = 1
export default function ActionsGroupBtn({ lead, setLead, setActivity }) {
  const { user } = useAuth()

  const navigate = useNavigate()
  const [isOpen, setIsOpen] = React.useState(false)
  const [open, setOpen] = React.useState(false)
  const [show, setShow] = React.useState(false)
  const [isShow, setIsShow] = React.useState(false)
  const [err, setErr] = React.useState(null)
  const [loading, setLoading] = React.useState(false)
  const [rOpen, setROpen] = React.useState(false)
  const [lOpen, setLOpen] = React.useState(false)
  const [reason, setReason] = React.useState("")

  const [selected, setSelected] = React.useState([])
  const [selectedDealers, setSelectedDealers] = React.useState([])

  const handleOpen = (event) => {
    setOpen((prev) => !prev)
  }

  const resendLead = async () => {
    try {
      if (!selected || selected?.length === 0) {
        return setErr("Please Select dealership to resend to")
      }
      setLoading(true)
      setErr(null)
      const res = await axios.patch(
        `${URL}/leads/reset-from-counters/${lead?._id}`,
        { selected },
        {
          headers: {
            "Content-Type": "application/json",
          },
          withCredentials: true,
        }
      )
      if (res.data.ok) {
        setLead((prev) => ({
          ...prev,
          countredBy: [],
          deniedBy: [],
          hiddenFrom: [],
          interested: [],
        }))
        setActivity((prev) => [res.data.activity, ...prev])
      }
      setShow(true)
      setLoading(false)
      setROpen(false)
      setTimeout(() => {
        setShow(false)
      }, 3000)
    } catch (error) {
      setLoading(false)
      setErr(error?.response?.data?.message || error.message)
      setTimeout(() => {
        setErr(null)
      }, 3000)
    } finally {
      setTimeout(() => {
        setErr(null)
        setShow(false)
      }, 3000)
    }
  }

  const handleLost = () => {
    moveToLostSold(
      lead?._id,
      setLead,
      setErr,
      setLoading,
      setShow,
      lead?.isLost,
      setActivity,
      reason,
      setReason,
      setLOpen
    )
  }

  return (
    <>
      <StyledSpeedDial
        ariaLabel="SpeedDial playground example"
        hidden={false}
        icon={<EditIcon sx={{ fontSize: "1rem" }} />}
        direction="left"
        open={open}
        onClick={handleOpen}
      >
        <SpeedDialAction
          onClick={() => navigate(`/leads/edit/${lead?._id}`)}
          icon={<EditIcon />}
          tooltipTitle="Edit"
        />
        {/* {isAllowed(user?.role, ["super-admin", "manager", "admin", "agent"]) &&
          !lead?.isLost &&
          !lead?.isTrash &&
          !lead?.isDeal && (
            <SpeedDialAction
              onClick={() => setIsOpen(true)}
              icon={<MonetizationOnIcon />}
              tooltipTitle="Deal"
            />
          )} */}
        {!lead?.isDeal && (
          <SpeedDialAction
            onClick={() => setLOpen(true)}
            icon={<DoNotDisturbIcon />}
            tooltipTitle={
              lead?.isLost ? "Recover from Lost/Sold" : "Lost / Sold"
            }
          />
        )}
        {/* {!lead?.isDeal && (
          <SpeedDialAction
            onClick={() =>
              moveToTrash(
                lead?._id,
                setLead,
                setErr,
                setLoading,
                setShow,
                lead?.isTrash,
                setActivity
              )
            }
            icon={<DeleteIcon />}
            tooltipTitle={lead?.isTrash ? "Recover from Trash" : "Trash"}
          />
        )} */}
        {/* {isAllowed(user?.role, ["super-admin", "manager", "admin", "agent"]) &&
          !lead?.isLost &&
          !lead?.isTrash &&
          !lead?.isDeal && (
            <SpeedDialAction
              onClick={() => setIsShow(true)}
              icon={<InsertInvitationIcon />}
              tooltipTitle="Appointment"
            />
          )} */}
        {isAllowed(user?.role, ["super-admin", "manager", "admin", "agent"]) &&
          !lead?.isLost &&
          !lead?.isTrash &&
          !lead?.isDeal && (
            <SpeedDialAction
              onClick={() => setROpen(true)}
              icon={<RestartAltIcon />}
              tooltipTitle="Resend"
            />
          )}
        {/* <SpeedDialAction
          icon={
            lead?.claimedBy === x ? <PlaylistRemoveIcon /> : <PostAddIcon />
          }
          tooltipTitle={
            lead?.claimedBy === x
              ? "Unclaim"
              : lead?.claimed
              ? "Claim"
              : "Claim"
          }
        /> */}
      </StyledSpeedDial>
      <PopUpDialogDeal
        open={isOpen}
        setActivity={setActivity}
        setLead={setLead}
        setOpen={setIsOpen}
        id={lead?._id}
        loading={loading}
        setErr={setErr}
        setShow={setShow}
        setLoading={setLoading}
        isAllowed={isAllowed(user?.role, [
          "super-admin",
          "manager",
          "admin",
          "agent",
        ])}
      />
      <PopUpDialogAppointment
        open={isShow}
        setActivity={setActivity}
        setLead={setLead}
        setOpen={setIsShow}
        id={lead?._id}
        loading={loading}
        setErr={setErr}
        setShow={setShow}
        setLoading={setLoading}
        lead={lead}
      />

      <ConfirmModal
        desc={
          "By clicking on confirm you will resend this lead to the dealer.\n This action also will remove any previous appointments created."
        }
        title={"Resend the lead"}
        onConfirm={resendLead}
        setOpen={setROpen}
        open={rOpen}
        loading={loading}
        noExit
        extra={
          <Stack py={2}>
            <SelectWithCheckBox
              selected={selected}
              setSelected={setSelected}
              selectedDealers={selectedDealers}
              setSelectedDealers={setSelectedDealers}
              list={lead?.sentTo}
            />
          </Stack>
        }
      />

      <FeedBackBox loading={loading} show={show} err={err} />
      <PopUpConfirm
        handleSubmit={handleLost}
        isLost={lead?.isLost}
        loading={loading}
        open={lOpen}
        reason={reason}
        setOpen={setLOpen}
        setReason={setReason}
        type={"lost"}
      />
    </>
  )
}
