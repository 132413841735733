import * as React from "react"
import ListItem from "@mui/material/ListItem"
import ListItemText from "@mui/material/ListItemText"
import ListItemIcon from "@mui/material/ListItemIcon"
import TodayIcon from "@mui/icons-material/Today"
import Typography from "@mui/material/Typography"
import momentTZ from "moment-timezone"
import axios from "axios"
import { ListItemButton, Tooltip } from "@mui/material"
import BorderColorIcon from "@mui/icons-material/BorderColor"
import UpdateAppointment from "../../modals/UpdateAppointment"
import { useState } from "react"
import EventBusyIcon from "@mui/icons-material/EventBusy"
import ConfirmModal from "../../modals/ConfirmModal"
import useData from "../../../context/Data"
import { URL } from "../../../config/apis"

const formatDate = (date, tz = "America/Los_Angeles") => {
  if (!date) return "N/A"
  return momentTZ.tz(date, tz).format("dddd MM/DD/YY @ h:mm A")
}

export default function AppointmentLine({
  app,
  setAppointments,
  setActivity,
  lead,
}) {
  const { loading, setLoading, setErr, setShow } = useData()
  const [open, setOpen] = useState(false)
  const [isOpen, setIsOpen] = useState(false)

  const cancelAppointment = async () => {
    setErr(null)
    setLoading(true)
    setShow(false)

    try {
      const res = await axios.put(
        `${URL}/calendar/appointment/${app?._id}/cancel`,
        {},
        {
          headers: {
            "Content-Type": "application/json",
          },
          withCredentials: true,
        }
      )
      if (res.data.ok) {
        setActivity((prev) => [res.data.activity, ...prev])
        setAppointments((prev) =>
          prev?.map((a) => {
            if (a?._id === app?._id) {
              return { ...a, status: "Canceled" }
            } else {
              return a
            }
          })
        )
      }
      setShow(true)
      setLoading(false)
      setIsOpen(false)
      setTimeout(() => {
        setShow(false)
      }, 3000)
    } catch (error) {
      setLoading(false)
      setErr(error?.response?.data?.message || error.message)
      setTimeout(() => {
        setErr(null)
      }, 3000)
    }
  }
  return (
    <>
      <ListItem alignItems="flex-start">
        <ListItemIcon sx={{ minWidth: "max-content", px: 1 }}>
          <TodayIcon
            color={
              app?.status === "Pending"
                ? "info"
                : app?.status === "Deal"
                ? "success"
                : app?.status === "Lost"
                ? "error"
                : "error"
            }
          />
        </ListItemIcon>
        <ListItemText
          primary={formatDate(app?.start, app?.tz)}
          secondary={
            <React.Fragment>
              <Typography
                sx={{ display: "inline" }}
                component="span"
                variant="body2"
                color="text.primary"
                fontWeight={500}
              >
                {app?.dealership?.dname} - {app?.status}
              </Typography>
            </React.Fragment>
          }
        />

        {app?.status !== "Deal" && (
          <ListItemButton
            onClick={() => setOpen(true)}
            sx={{ maxWidth: "max-content !important", px: 1 }}
          >
            <BorderColorIcon />
          </ListItemButton>
        )}
        {app?.status === "Pending" && (
          <Tooltip title="Cancel the Appointment" placement="top">
            <ListItemButton
              onClick={() => setIsOpen(true)}
              sx={{ maxWidth: "max-content !important", px: 1 }}
            >
              <EventBusyIcon color="error" />
            </ListItemButton>
          </Tooltip>
        )}
      </ListItem>
      {open && (
        <UpdateAppointment
          open={open}
          setOpen={setOpen}
          app={app}
          setActivity={setActivity}
          setAppointmnets={setAppointments}
          lead={lead}
        />
      )}
      {isOpen && (
        <ConfirmModal
          open={isOpen}
          setOpen={setIsOpen}
          desc="By clicking on confirm you will cancel this appointment and send sms notifications to Seller and Dealers"
          title={"Are you sure you want to cancel this appointment?"}
          onConfirm={cancelAppointment}
          loading={loading}
        />
      )}
    </>
  )
}
