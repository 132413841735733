import * as React from "react"
import Box from "@mui/material/Box"
import { NavLink } from "react-router-dom"
import AccountCircleIcon from "@mui/icons-material/AccountCircle"
import BusinessIcon from "@mui/icons-material/Business"
import LockIcon from "@mui/icons-material/Lock"

import Tabs from "@mui/material/Tabs"
import Tab from "@mui/material/Tab"

const nav = [
  {
    title: "Profile",
    to: "",
    icon: <AccountCircleIcon color="inherit" fontSize={"medium"} />,
    end: true,
  },
  {
    title: "Password",
    to: "password",
    icon: <LockIcon color="inherit" fontSize={"medium"} />,
  },
]

function LinkTab(props) {
  return (
    <Tab
      component={NavLink}
      style={({ isActive }) => ({
        color: isActive ? "#be0b30" : "inherit",
        backgroundColor: isActive ? "#f8f8f8" : "inherit",
        borderBottom: isActive ? "2px solid #be0b30" : "2px solid transparent",
        minHeight: "50px",
      })}
      {...props}
    />
  )
}

export default function AccountSideBar() {
  return (
    <Box sx={{ width: "100%" }}>
      <Tabs value={0} aria-label="Account nav" indicatorColor="">
        {nav.map((item, i) => (
          <LinkTab
            key={item.title + i}
            label={item.title}
            to={item.to}
            end={item.end}
            icon={item.icon}
            iconPosition="start"
          />
        ))}
      </Tabs>
    </Box>
  )
}
