import {
  Checkbox,
  FormControl,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
} from "@mui/material"
import React from "react"
import useData from "../../../context/Data"

const ITEM_HEIGHT = 48
const ITEM_PADDING_TOP = 8
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 8.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
}

export default function SelectWithCheckBox({
  selected,
  setSelected,
  selectedDealers,
  setSelectedDealers,
  list,
}) {
  const { dealerships } = useData()
  const handleChange = (event) => {
    const {
      target: { value },
    } = event
    setSelectedDealers(value)
    setSelected(value?.map((v) => v?._id))
  }

  const isSelected = (id) => {
    return selected?.includes(id)
  }

  const dealers = React.useMemo(() => {
    return dealerships
      ?.filter((d) => list?.includes(d?._id))
      ?.map((x) => ({ _id: x?._id, dname: x?.dname }))
  }, [list, dealerships])

  return (
    <div style={{ width: "100%" }}>
      <FormControl size="small" sx={{ width: "100%" }}>
        <InputLabel id="demo-multiple-checkbox-label">Dealers</InputLabel>
        <Select
          labelId="demo-multiple-checkbox-label"
          id="demo-multiple-checkbox"
          multiple
          value={selectedDealers}
          onChange={handleChange}
          input={<OutlinedInput sx={{ width: "100%" }} label="Dealers" />}
          renderValue={(selected) => selected?.map((i) => i?.dname)?.join(", ")}
          MenuProps={MenuProps}
        >
          {dealers?.map((name) => (
            <MenuItem key={name?._id} value={name}>
              <Checkbox checked={isSelected(name?._id)} />
              <ListItemText primary={name?.dname} />
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  )
}
