import {
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
} from "@mui/material"
import React from "react"

export default function PricesInput({
  lable,
  value,
  sign = "$",
  onValueChange,
  name,
  isCg = false,
  disabled = false,
  onClick
}) {
  return (
    <FormControl disabled={disabled} sx={{ width: "100%" }} size="small">
      <InputLabel htmlFor="outlined-adornment-amount">{lable}</InputLabel>
      <OutlinedInput
        id="outlined-adornment-amount"
        value={value == null ? null : value?.toLocaleString()}
        onChange={(e) =>
          onValueChange(
            parseFloat((e.target.value || "0").replace(/,/g, "")),
            name
          )
        }
        startAdornment={
          <InputAdornment position="start">{sign}</InputAdornment>
        }
        endAdornment={
          isCg && (
            <IconButton onClick={onClick}>
              <img src="/cg.png" alt="CG" height={20} width={"auto"} />
            </IconButton>
          )
        }
        label={lable}
      />
    </FormControl>
  )
}

export function PricesInputSimple({
  lable,
  value,
  sign = "$",
  onValueChange,
  disabled = false,
}) {
  return (
    <FormControl sx={{ width: "100%" }} size="small">
      <InputLabel htmlFor="outlined-adornment-amount">{lable}</InputLabel>
      <OutlinedInput
        id="outlined-adornment-amount"
        value={value == null ? null : value?.toLocaleString()}
        onChange={(e) =>
          onValueChange(parseFloat((e.target.value || "0").replace(/,/g, "")))
        }
        startAdornment={
          <InputAdornment position="start">{sign}</InputAdornment>
        }
        label={lable}
        disabled={disabled}
      />
    </FormControl>
  )
}
