import {
  Avatar,
  Button,
  List,
  ListItem,
  ListItemAvatar,
  ListItemButton,
  ListItemText,
} from "@mui/material"
import React from "react"
import axios from "axios"
import useData from "../../context/Data"
import { formatPrice } from "../../helppers/formatters"
import GavelIcon from "@mui/icons-material/Gavel"
import { URL } from "../../config/apis"
import FiberNewIcon from "@mui/icons-material/FiberNew"

const CounterOfferBox = ({
  setCounters,
  setLead,
  item,
  setActivity,
  disabled,
}) => {
  const { loading, setLoading, setShow, setErr } = useData()

  const onSubmit = async () => {
    setLoading(true)
    try {
      const res = await axios.put(
        `${URL}/leads/accept/${item?._id}`,
        {},
        {
          headers: {
            "Content-Type": "application/json",
          },
          withCredentials: true,
        }
      )
      if (res.data.ok) {
        setCounters([])
        setLead((prev) => ({ ...prev, acceptedBy: item?.delaership?._id }))
        setActivity((prev) => [res.data.activity, ...prev])
        setLoading(false)
        setShow(true)
        setTimeout(() => {
          setShow(false)
        }, 2000)
      }
    } catch (error) {
      setLoading(false)
      setErr(error.response?.data?.message || error.message)
      setTimeout(() => {
        setErr(null)
      }, 3000)
    }
  }
  const buttonSx = {
    bgcolor: "#1976d2",
  }

  return (
    <ListItem
      sx={{
        bgcolor: "inherit",
        width: "100%",
        opacity: loading ? 0.6 : 1,
      }}
      alignItems="flex-start"
    >
      <ListItemAvatar sx={{ p: 0 }}>
        <FiberNewIcon color="info" sx={{ fontSize: "2rem" }} />
      </ListItemAvatar>
      <ListItemText
        primary={`${
          item?.sentFrom === "EP - Offer Engine"
            ? "EP - Offer Engine"
            : item?.sentFrom === "API"
            ? item?.sentFrom
            : item?.sentFrom === "System"
            ? "API"
            : item?.sentBy?.fname
        } / ${item?.dealership?.dname}`}
        secondary={
          <React.Fragment>{`— Sent New Counter Offer  ${formatPrice(
            item?.amount
          )}`}</React.Fragment>
        }
      />
      <ListItemButton
        sx={{
          maxWidth: "max-content !important",
          display: "flex",
          justifyContent: "center",
          bgcolor: "#54c754",
          borderRadius: 1,
        }}
        onClick={onSubmit}
        disabled={loading || disabled}
      >
        <GavelIcon sx={{ color: "#1f1c1c" }} />
      </ListItemButton>
    </ListItem>
  )
}

export default function PendingCounterOffers({
  counters,
  setCounters,
  setLead,
  setActivity,
  disabled,
}) {
  return (
    <List sx={{ width: "100%", bgcolor: "background.paper" }}>
      {counters &&
        counters?.map((item, i) => (
          <CounterOfferBox
            setCounters={setCounters}
            setLead={setLead}
            key={item?._id + i}
            item={item}
            setActivity={setActivity}
            disabled={disabled}
          />
        ))}
    </List>
  )
}
