import * as React from "react"
import Box from "@mui/material/Box"
import Card from "@mui/material/Card"
import CardActions from "@mui/material/CardActions"
import CardContent from "@mui/material/CardContent"
import Button from "@mui/material/Button"
import Typography from "@mui/material/Typography"
import { Chip, Tooltip } from "@mui/material"
import { Link } from "react-router-dom"

const bull = (
  <Box
    component="span"
    sx={{ display: "inline-block", mx: "2px", transform: "scale(0.8)" }}
  >
    •
  </Box>
)

export default function DealershipCard({ dealership }) {
  return (
    <Box sx={{ minWidth: 275, opacity: dealership?.isActive ? 1 : 0.6 }}>
      <Card variant="outlined">
        <CardContent>
          <Tooltip title={dealership?.dname} placement="top">
            <Typography
              variant="h6"
              component="div"
              sx={{
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
              }}
            >
              {dealership?.dname}
            </Typography>
          </Tooltip>
          <Typography color="text.secondary">
            {dealership?.city} {bull} {dealership?.state}{" "}
            {!dealership?.isActive && (
              <>
                {bull} <Chip color="error" label="Disabled" size="small" />
              </>
            )}
          </Typography>
        </CardContent>
        <CardActions>
          <Button size="small">
            <Link
              to={`/dealerships/profile/${dealership?._id}`}
              style={{ textDecoration: "none", color: "inherit" }}
            >
              View Profile
            </Link>
          </Button>
        </CardActions>
      </Card>
    </Box>
  )
}
