import {
    Button,
  Container,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material"
import React from "react"
import { CSVLink } from "react-csv"

const headers = [
  { label: "Agent Name", key: "agent.fname" },
  { label: "Total", key: "total" },
]
const dheaders = [
  { label: "Dealership", key: "dname" },
  { label: "Total", key: "total" },
]
const mheaders = [
  { label: "Market", key: "market.name" },
  { label: "Total", key: "total" },
]

export default function DataResults({ results, rangeData, start, end, type }) {
  const csvReport = {
    data: results,
    headers:
      type === "Sent" || type === "Deals" || type === "bids"
        ? dheaders
        : type === "Created By Market"
        ? mheaders
        : headers,
    filename: `US-Leads-${type}-${
      type === "Sent"
        ? "To-Dealers"
        : type === "Created By Market"
        ? ""
        : type === "deals"
        ? "By-Dealers"
        : "By-Agents"
    }-${rangeData?.start || start}-to-${rangeData?.end || end}.csv`,
    target: "_blank",
  }

  const count = React.useMemo(() => {
    if (!results || results?.length === 0)
      return {
        count: 0,
        start: rangeData?.start || start,
        end: rangeData?.end || end,
        type: type,
      }

    return {
      count: results?.map((r) => r.total)?.reduce((a, b) => a + b, 0),
      start: rangeData?.start || start,
      end: rangeData?.end || end,
      type: type,
    }
  }, [results])

  return (
    <Container maxWidth="100%">
      <Stack mb={2} direction={"row"} spacing={3} alignItems="center">
        <Typography>
          {count?.type === "Deals"
            ? `Deals `
            : count?.type === "closed"
            ? `Closed Deals`
            : count?.type === "bids"
            ? `Dealers Bids`
            : `Leads ${count?.type} `}{" "}
          from <b>{count?.start}</b> to <b>{count?.end}</b> :{" "}
          <b>{count?.count}</b>
        </Typography>

        <Button variant="contained" color="success">
          <CSVLink
            {...csvReport}
            target="_blank"
            style={{ textDecoration: "none", color: "inherit" }}
          >
            Export to CSV
          </CSVLink>
        </Button>
      </Stack>
      <TableContainer
        sx={{ maxWidth: "max-content", minWidth: 400 }}
        component={Paper}
      >
        <Table
          sx={{ maxWidth: "max-content", minWidth: 400 }}
          aria-label="simple table"
        >
          <TableHead>
            <TableRow>
              <TableCell>
                {count?.type === "Sent" || count?.type === "Deals"
                  ? "Dealership"
                  : count?.type === "Created By Market"
                  ? "Market"
                  : "User"}
              </TableCell>
              <TableCell align="right">Total</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {results &&
              results?.map((a, i) => (
                <TableRow
                  key={a?._id + i}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell component="th" scope="row">
                    {a?.agent?.fname || a?.dname || a?.market?.name}
                  </TableCell>
                  <TableCell align="right">{a?.total}</TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Container>
  )
}
