import * as React from "react"
import List from "@mui/material/List"
import Divider from "@mui/material/Divider"
import CounterOffer from "./CounterOffer"
import DefaultView from "./DefaultView"
import AcceptedView from "./AcceptedView"

export default function DealerActivity({
  dActivity,
  setDealerActivity,
  setLead,
  setActivity,
}) {
  return (
    <List sx={{ width: "100%", bgcolor: "background.paper" }}>
      {dActivity &&
        dActivity?.map((item, i) => (
          <>
            {item?.type === "Counter" ? (
              <CounterOffer
                setDealerActivity={setDealerActivity}
                setActivity={setActivity}
                setLead={setLead}
                key={item?._id + i}
                item={item}
              />
            ) : item?.type === "Accept" || item?.type === "Accepted Counter" ? (
              <AcceptedView key={item?._id + i} item={item} />
            ) : (
              <DefaultView key={item?._id + i} item={item} />
            )}
            {dActivity?.length - 1 !== i && (
              <Divider key={item?._id} variant="inset" component="li" />
            )}
          </>
        ))}
      {/* <ListItem alignItems="flex-start">
        <ListItemAvatar>
          <Avatar sx={{ bgcolor: blue[500] }}>
            <ConnectingAirportsIcon />
          </Avatar>
        </ListItemAvatar>
        <ListItemText
          primary="Jennifer Scott / Dealership Name"
          secondary={
            <React.Fragment>
              {" — Sent a Counter offer of $14,000"}
            </React.Fragment>
          }
        />
      </ListItem>
      <Divider variant="inset" component="li" />
      <ListItem alignItems="flex-start">
        <ListItemAvatar>
          <Avatar sx={{ bgcolor: red[600] }}>
            <CancelIcon />
          </Avatar>
        </ListItemAvatar>
        <ListItemText
          primary="Sandra Adams / Dealership Name"
          secondary={
            <React.Fragment>{" — Not Intersted on this lead"}</React.Fragment>
          }
        /> 
      </ListItem>
        */}
    </List>
  )
}
