import * as React from "react"
import { styled } from "@mui/material/styles"
import Box from "@mui/material/Box"
import CssBaseline from "@mui/material/CssBaseline"

import SideBar from "../navs/SideBar"
import TopBar from "../navs/TopBar"

const drawerWidth = 240

const Main = styled("main", { shouldForwardProp: (prop) => prop !== "open" })(
  ({ theme, open }) => ({
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: `-${drawerWidth}px`,
    ...(open && {
      transition: theme.transitions.create("margin", {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: 0,
    }),
  })
)

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: "flex-end",
}))

const MainLayout = ({ children }) => {
  const [open, setOpen] = React.useState(
    JSON.parse(localStorage.getItem("isOpen"))
  )

  const handleDrawerOpen = () => {
    setOpen(true)
  }

  const handleDrawerClose = () => {
    setOpen(false)
  }
  React.useEffect(() => {
    localStorage.setItem("isOpen", JSON.stringify(open))
  }, [open])

  return (
    <Box sx={{ display: "flex", bgcolor:'#f8f8f8' }}>
      <CssBaseline />
      <TopBar handleDrawerOpen={handleDrawerOpen} open={open} />
      <SideBar
        drawerWidth={drawerWidth}
        handleDrawerClose={handleDrawerClose}
        open={open}
      />
      <Main open={open}>
        <DrawerHeader />
        {children}
      </Main>
    </Box>
  )
}
export default MainLayout
