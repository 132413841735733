import axios from "axios"
import { URL } from "../../config/apis"

export const saveAvatar = async (data) => {
  try {
    await axios.patch(`${URL}/agents/update/avatar`, data, {
      headers: {
        "Content-Type": "application/json",
      },
      withCredentials: true,
    })
  } catch (error) {
    console.log(error)
  }
}

export const saveDealerLogo = async (data, id) => {
  try {
    await axios.put(`${URL}/dealerships/profile/${id}/`, data, {
      headers: {
        "Content-Type": "application/json",
      },
      withCredentials: true,
    })
  } catch (error) {
    console.log(error)
  }
}
