import HomeIcon from "@mui/icons-material/Home"
import AddCircleIcon from "@mui/icons-material/AddCircle"
import AssessmentIcon from "@mui/icons-material/Assessment"
import FolderCopyIcon from "@mui/icons-material/FolderCopy"
import EmojiTransportationIcon from "@mui/icons-material/EmojiTransportation"
import PeopleAltIcon from "@mui/icons-material/PeopleAlt"
import LocationOnIcon from "@mui/icons-material/LocationOn"
import MoveToInboxIcon from "@mui/icons-material/MoveToInbox"
import ImportExportIcon from "@mui/icons-material/ImportExport"
import HighlightOffIcon from "@mui/icons-material/HighlightOff"
import DeleteIcon from "@mui/icons-material/Delete"
import RequestQuoteIcon from "@mui/icons-material/RequestQuote"
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth"
import DashboardIcon from "@mui/icons-material/Dashboard"
import { FaFileCsv } from "react-icons/fa"

export const navMenu = [
  {
    lable: "Dashboard",
    icon: <DashboardIcon />,
    href: "/",
  },
  {
    lable: "Create",
    icon: <AddCircleIcon />,
    href: "/new",
  },
  {
    lable: "Leads",
    icon: <FolderCopyIcon />,
    href: "/leads",
  },
  {
    lable: "Accepted",
    icon: <MoveToInboxIcon />,
    href: "/accepted",
  },
  {
    lable: "Counters",
    icon: <ImportExportIcon />,
    href: "/counters",
  },
  {
    lable: "Appointments",
    icon: <CalendarMonthIcon />,
    href: "/appointments",
  },
  {
    lable: "Done Deals",
    icon: <RequestQuoteIcon />,
    href: "/deals",
  },
  {
    lable: "Lost",
    icon: <HighlightOffIcon />,
    href: "/lost",
  },
  {
    lable: "Trash",
    icon: <DeleteIcon />,
    href: "/trash",
  },
  // {
  //   lable: "Dealerships",
  //   icon: <EmojiTransportationIcon />,
  //   href: "/dealerships",
  // },
  // {
  //   lable: "Markets",
  //   icon: <LocationOnIcon />,
  //   href: "/markets",
  // },
  // {
  //   lable: "Users",
  //   icon: <PeopleAltIcon />,
  //   href: "/users",
  // },
]

export const botMenu = [
  // {
  //   lable: "Accepted",
  //   icon: <MoveToInboxIcon />,
  //   href: "/accepted",
  // },
  // {
  //   lable: "Counters",
  //   icon: <ImportExportIcon />,
  //   href: "/counters",
  // },
  // {
  //   lable: "Appointments",
  //   icon: <CalendarMonthIcon />,
  //   href: "/appointments",
  // },
  // {
  //   lable: "Done Deals",
  //   icon: <RequestQuoteIcon />,
  //   href: "/deals",
  // },
  // {
  //   lable: "Lost",
  //   icon: <HighlightOffIcon />,
  //   href: "/lost",
  // },
  // {
  //   lable: "Trash",
  //   icon: <DeleteIcon />,
  //   href: "/trash",
  // },
  // {
  //   lable: "Reports",
  //   icon: <AssessmentIcon />,
  //   href: "/reports",
  // },
]

export const adminMenu = [
  {
    lable: "Users",
    icon: <PeopleAltIcon />,
    href: "/users",
    roles: ["super-admin", "admin"],
  },
  {
    lable: "Dealerships",
    icon: <EmojiTransportationIcon />,
    href: "/dealerships",
    roles: ["super-admin", "agent", "manager", "admin"],
  },
  {
    lable: "Markets",
    icon: <LocationOnIcon />,
    href: "/markets",
    roles: ["super-admin"],
  },
  {
    lable: "DB Reports",
    icon: <AssessmentIcon />,
    href: "/db-reports",
    roles: ["super-admin", "agent", "manager", "admin"],
  },
  {
    lable: "Dealer Reports",
    icon: <AssessmentIcon />,
    href: "/reports",
    roles: ["super-admin", "agent", "manager", "admin"],
  },
  // {
  //   lable: "Export",
  //   icon: <FaFileCsv size={22} />,
  //   href: "/export",
  //   roles: ["super-admin"],
  // },
]
