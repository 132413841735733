import { MenuItem, Paper, Stack } from "@mui/material"
import axios from "axios"
import React, { useEffect, useState } from "react"
import CalendarBox from "../components/calendar"
import Calendar from "../components/calendar/Calendar"
import { URL } from "../config/apis"
import SelectMenu from "../components/createPage/SelectMenu"

export default function CalendarPage() {
  const [appointments, setAppointments] = useState([])
  const [loading, setLoading] = useState(false)
  const [status, setStatus] = useState("All")
  const [start, setStart] = useState("")
  const [end, setEnd] = useState("")
  const [err, setErr] = useState(null)

  const getAppointments = async (startView, endView) => {
    if (!startView || !endView) return
    setStart(startView)
    setEnd(endView)
    setLoading(true)
    setErr(null)
    try {
      const res = await axios.get(
        `${URL}/calendar?start=${startView}&end=${endView}&status=${status}`,
        {
          headers: {
            "Content-Type": "application/json",
          },
          withCredentials: true,
        }
      )
      setAppointments(res.data)
    } catch (error) {
      setErr(error.response?.data?.message || error.message)
    }
    setLoading(false)
  }

  useEffect(() => {
    getAppointments(start, end)
  }, [status])

  // if (loading) {
  //   return <>Loading ...</>
  // }
  // if (err) {
  //   return <>{err}</>
  // }

  return (
    <div style={{ position: "relative" }}>
      {loading && (
        <Stack
          position={"absolute"}
          sx={{ inset: 0, bgcolor: "#47444457", zIndex: 10 }}
          justifyContent="center"
          alignItems={"center"}
        >
          <Paper
            component={Stack}
            elevation={5}
            sx={{ bgcolor: "#fff", borderRadius: 1, mb: 20 }}
            px={5}
            py={2}
          >
            Loading Calendar Events...
          </Paper>
        </Stack>
      )}
      <Stack direction={"row"} mb={1}>
        <SelectMenu
          handleChange={setStatus}
          label={"Status"}
          name={"status"}
          value={status}
          options={["All", "Pending", "Deal", "No Show", "Canceled", "Lost"]}
          sx={{ minWidth: 150 }}
        >
          {["All", "Pending", "Deal", "No Show", "Canceled", "Lost"]?.map(
            (item, i) => (
              <MenuItem key={item + i} value={item}>
                {item}
              </MenuItem>
            )
          )}
        </SelectMenu>
      </Stack>
      <Calendar appointments={appointments} getAppointments={getAppointments} />
    </div>
  )
}
