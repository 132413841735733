import * as React from "react"
import Button from "@mui/material/Button"
import Dialog from "@mui/material/Dialog"
import DialogActions from "@mui/material/DialogActions"
import DialogContent from "@mui/material/DialogContent"
import DialogContentText from "@mui/material/DialogContentText"
import DialogTitle from "@mui/material/DialogTitle"
import Slide from "@mui/material/Slide"

import { MenuItem } from "@mui/material"
import { lostReasons } from "../../constants/reasons"
import SelectMenu from "../createPage/SelectMenu"

const msgs = {
  lost: {
    title: "Mark this lead as LOST",
    desc: "Are you sure ? if you click on submit lead will be deleted from dealers portal",
  },
  trash: {
    title: "Mark this lead as TRASH",
    desc: "Are you sure ? if you click on submit lead will be deleted from dealers portal",
  },
  unaccept: {
    title: "Unaccept this lead",
    desc: "Are you sure? if you click submit, this lead will no longer be marked as accepted",
  },
  undeal: {
    title: "Reset this lead from Deals",
    desc: "Are you sure? if you click submit, this lead will no longer be marked as a deal",
  },
  noShow: {
    title: "Mark this lead as No Show",
    desc: "Are you sure? if you click submit, the appointment and lead will be marked as No Show",
  },
  "Offer Made": {
    title: "Offer Made on this lead",
    desc: "Are you sure? if you click submit, you confirm that the Offer Made to the seller",
  },
  "Ready Closer": {
    title: "Send this lead to Closers",
    desc: "Are you sure? If you submit, you confirm the lead is ready for the closers",
  },
}

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />
})

export default function PopUpConfirm({
  open,
  setOpen,
  type,
  loading,
  handleSubmit,
  isLost,
  reason,
  setReason,
}) {
  if (!type) return

  const handleClose = (e, reason) => {
    if (reason !== "backdropClick") {
      setOpen(false)
      setReason("")
    }
  }

  const handleBackdropClick = (event) => {
    event.stopPropagation()
    return false
  }

  return (
    <Dialog
      open={open}
      TransitionComponent={Transition}
      keepMounted
      onClose={handleClose}
      aria-describedby="alert-dialog-slide-description"
      onBackdropClick={handleBackdropClick}
      disableEscapeKeyDown
    >
      <DialogTitle>
        {isLost ? "Recover this Lead" : msgs[type]?.title}
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-slide-description">
          {isLost
            ? "When you recover this lead it will be visible on the dealer portal again"
            : msgs[type]?.desc}
        </DialogContentText>
        <br />
        {!isLost ? (
          <SelectMenu
            sx={{ minWidth: 150 }}
            label="Lost Reasons"
            options={lostReasons}
            value={reason}
            handleChange={(value) => setReason(value)}
            name={"reason"}
          >
            {lostReasons?.map((o, i) => (
              <MenuItem key={o?.title + i} value={o?.value}>
                {o?.title}
              </MenuItem>
            ))}
          </SelectMenu>
        ) : null}
      </DialogContent>
      <DialogActions>
        <Button color="error" disabled={loading} onClick={handleClose}>
          Cancel
        </Button>
        <Button
          variant="contained"
          color={isLost ? "success" : "error"}
          disabled={loading || (isLost === false && reason === "")}
          onClick={handleSubmit}
        >
          {isLost ? "Recover" : "Submit"}
        </Button>
      </DialogActions>
    </Dialog>
  )
}
