export const handleDrag = (e) => {
  e.preventDefault()
  e.stopPropagation()
}

export const handleDragIn = (e, setDragCounter, setDrag) => {
  e.preventDefault()
  e.stopPropagation()
  setDragCounter((prev) => prev + 1)
  setDrag(true)
}

export const handleDragOut = (e, setDragCounter, setDrag) => {
  e.preventDefault()
  e.stopPropagation()
  setDragCounter((prev) => {
    if (prev - 1 === 0) {
      setDrag(false)
    }
    return prev - 1
  })
}

export function openSmallPage(url) {
  window.open(
    url,
    "targetWindow",
    `toolbar=no,
      location=no,
      status=no,
      menubar=no,
      scrollbars=yes,
      resizable=yes,
      width=1200,
      height=800
      `
  )
}

export const getCgUrl = (vin, mileage, zip) => {
  return `https://www.cargurus.com/Cars/instantMarketValueFromVIN.action?startUrl=%2F&++++++++carDescription.vin%0D%0A=${vin}&++++++++carDescription.postalCode%0D%0A=${zip}&++++++++carDescription.mileage%0D%0A=${mileage}`
}
