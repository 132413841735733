import * as React from "react"
import Button from "@mui/material/Button"
import Dialog from "@mui/material/Dialog"
import DialogActions from "@mui/material/DialogActions"
import DialogContent from "@mui/material/DialogContent"
import DialogContentText from "@mui/material/DialogContentText"
import DialogTitle from "@mui/material/DialogTitle"

export default function ConfirmModal({
  open,
  setOpen,
  title,
  desc,
  onConfirm,
  loading,
  noExit = false,
  extra = null,
}) {
  const handleClose = (e, reason) => {
    if (!noExit) {
      setOpen(false)
      return
    }
    if (reason !== "backdropClick") {
      setOpen(false)
    }
  }

  const handleBackdropClick = (event) => {
    event.stopPropagation()
    return false
  }

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      onBackdropClick={handleBackdropClick}
      disableEscapeKeyDown={noExit}
    >
      <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {desc}
        </DialogContentText>
        {extra}
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          disabled={loading}
          onClick={handleClose}
          autoFocus
          color="error"
        >
          No, Close
        </Button>
        <Button onClick={onConfirm} disabled={loading}>
          Yes, Confirm
        </Button>
      </DialogActions>
    </Dialog>
  )
}
