import * as React from "react"
import Button from "@mui/material/Button"
import TextField from "@mui/material/TextField"
import { Alert, Divider, Grid, Paper, Stack } from "@mui/material"
import { URL } from "../../config/apis"
import axios from "axios"
import Switch from "@mui/material/Switch"
import FormGroup from "@mui/material/FormGroup"
import FormControlLabel from "@mui/material/FormControlLabel"
import useData from "../../context/Data"
import OutlinedInput from "@mui/material/OutlinedInput"
import InputLabel from "@mui/material/InputLabel"
import MenuItem from "@mui/material/MenuItem"
import FormControl from "@mui/material/FormControl"
import ListItemText from "@mui/material/ListItemText"
import Select from "@mui/material/Select"
import Checkbox from "@mui/material/Checkbox"
import { isAllowed } from "../../helppers/checkRoles"
import useAuth from "../../context/Auth"
import SelectMenu from "../createPage/SelectMenu"

const ITEM_HEIGHT = 48
const ITEM_PADDING_TOP = 8
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
}

export default function EditAgent({ agent: _agent, setState, open, setOpen }) {
  const { user } = useAuth()
  const { setAgents, teams } = useData()
  const [agent, setAgent] = React.useState({
    f_name: _agent?.f_name,
    l_name: _agent?.l_name,
    email: _agent?.email,
    team: _agent?.team,
    slackId: _agent?.slackId,
    role: _agent?.role,
    twilioPhone: _agent?.twilioPhone,
  })
  const [loading, setLoading] = React.useState(false)
  const [err, setErr] = React.useState(null)
  const [success, setSuccess] = React.useState(null)

  const saveProfile = async () => {
    if (!isAllowed(user?.role, ["super-admin", "admin", "manager"])) return
    if (user?._id === _agent?._id) return
    if (!agent?.f_name || !agent?.l_name) {
      setErr("Name is required")
      return setTimeout(() => {
        setErr(null)
      }, 3000)
    }
    if (!agent?.email) {
      setErr("Please Add email")
      return setTimeout(() => {
        setErr(null)
      }, 3000)
    }

    setErr(null)
    setLoading(true)
    setSuccess(null)
    try {
      const res = await axios.put(`${URL}/agents/edit/${_agent?._id}`, agent, {
        headers: {
          "Content-Type": "application/json",
        },
        withCredentials: true,
      })
      if (res.data.ok) {
        setState((prev) => ({ ...prev, ...res.data.user }))
      }
      setSuccess("Agent Updated")
      setLoading(false)
      setOpen(false)
      setTimeout(() => {
        setSuccess(null)
      }, 3000)
    } catch (error) {
      setLoading(false)
      setErr(error?.response?.data?.message || error.message)
      // setTimeout(() => {
      //   setErr(null)
      // }, 3000)
    }
  }

  const handleSelectChange = (event) => {
    const {
      target: { value },
    } = event
    setAgent((prev) => ({
      ...prev,
      role: typeof value === "string" ? value.split(",") : value,
    }))
  }

  const handleChange = (value, name) => {
    setAgent((prev) => ({ ...prev, [name]: value }))
  }

  return (
    <Paper>
      <Stack px={5} pt={2}>
        {loading && <Alert severity="info">Updating agent ...</Alert>}
        {err && <Alert severity="error">{err}</Alert>}
        {success && <Alert severity="success">{success}</Alert>}
      </Stack>

      <Grid container sx={{ py: 3, px: 5 }} spacing={2} columns={2}>
        <Grid item sm={1}>
          <TextField
            size="small"
            id="outlined-basic"
            label="First Name"
            variant="outlined"
            fullWidth
            value={agent?.f_name}
            onChange={(e) => handleChange(e.target.value, "f_name")}
          />
        </Grid>
        <Grid item sm={1}>
          <TextField
            size="small"
            id="outlined-basic"
            label="Last Name"
            variant="outlined"
            fullWidth
            value={agent?.l_name}
            onChange={(e) => handleChange(e.target.value, "l_name")}
          />
        </Grid>
        <Grid item sm={2}>
          <TextField
            size="small"
            id="outlined-basic"
            label="Email"
            variant="outlined"
            fullWidth
            value={agent?.email}
            onChange={(e) => handleChange(e.target.value, "email")}
          />
        </Grid>
        {isAllowed(user?.role, ["super-admin", "admin"]) && (
          <Grid item sm={1}>
            <SelectMenu
              handleChange={(v) => handleChange(v, "team")}
              label={"Team"}
              name={"team"}
              value={agent.team}
              options={teams}
              sx={{ minWidth: 150 }}
            >
              {teams &&
                teams?.map((team) => (
                  <MenuItem key={team?._id} value={team?._id}>
                    {team?.name}
                  </MenuItem>
                ))}
            </SelectMenu>
          </Grid>
        )}
        <Grid item sm={1}>
          <TextField
            size="small"
            id="outlined-basic"
            label="Slack Id"
            variant="outlined"
            fullWidth
            value={agent?.slackId}
            onChange={(e) => handleChange(e.target.value, "slackId")}
          />
        </Grid>
        <Grid item sm={1}>
          <TextField
            size="small"
            id="outlined-basic"
            label="Twilio Phone"
            variant="outlined"
            fullWidth
            value={agent?.twilioPhone}
            onChange={(e) => handleChange(e.target.value, "twilioPhone")}
          />
        </Grid>
        {isAllowed(user?.role, ["super-admin"]) && (
          <Grid item sm={2}>
            <FormControl size="small" sx={{ minWidth: 250, width: "100%" }}>
              <InputLabel id="demo-multiple-checkbox-label">Roles</InputLabel>
              <Select
                labelId="demo-multiple-checkbox-label"
                id="demo-multiple-checkbox"
                multiple
                value={agent?.role}
                onChange={handleSelectChange}
                input={<OutlinedInput label="Roles" />}
                renderValue={(selected) => selected.join(", ")}
                MenuProps={MenuProps}
              >
                {[
                  "user",
                  "agent",
                  "manager",
                  "closer",
                  "admin",
                  "super-admin",
                ].map((name) => (
                  <MenuItem key={name} value={name}>
                    <Checkbox checked={agent?.role?.indexOf(name) > -1} />
                    <ListItemText primary={name} />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
        )}
      </Grid>
      <Divider flexItem />
      <Stack
        direction={"row"}
        justifyContent="center"
        flexWrap="wrap"
        gap={2}
        sx={{ width: "100%", py: 2, px: 5 }}
      >
        <Button
          onClick={() => setOpen((prev) => !prev)}
          color={open ? "error" : "primary"}
          variant="contained"
        >
          {open ? "Close" : "Edit"}
        </Button>
        <Button
          onClick={saveProfile}
          color={"success"}
          variant="contained"
          disabled={loading}
        >
          {loading ? "Saving..." : "Save"}
        </Button>
      </Stack>
    </Paper>
  )
}
