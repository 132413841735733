import { Avatar, ListItem, ListItemAvatar, ListItemText } from "@mui/material"
import React from "react"
import GavelIcon from "@mui/icons-material/Gavel"
import { useMemo } from "react"
import { formatPrice } from "../../../helppers/formatters"
import moment from "moment"

export default function CounterOffer({ item }) {
  const buttonSx = {
    bgcolor: "#1976d2",
  }

  const action = useMemo(() => {
    return JSON.parse(item?.action)
  }, [item?.action])

  return (
    <ListItem
      sx={{
        bgcolor: "inherit",
        width: "100%",
      }}
      alignItems="flex-start"
    >
      <ListItemAvatar>
        <Avatar sx={{ ...buttonSx }}>
          <GavelIcon />
        </Avatar>
      </ListItemAvatar>
      <ListItemText
        primary={`${item?.createdBy} / ${item?.delaership?.dname}`}
        secondary={
          <React.Fragment>{`Sent New Bid  ${formatPrice(
            action?.amount
          )} at ${moment(item?.createdAt).format("MM/DD hA")}`}</React.Fragment>
        }
      />
    </ListItem>
  )
}
