import { Chip, Stack } from "@mui/material"
import React, { useMemo } from "react"
import useData from "../../context/Data"
import CancelIcon from "@mui/icons-material/Cancel"

const DealetChip = ({ id, unSendToDealers, disabled }) => {
  const { dealerships } = useData()
  const dealer = useMemo(() => {
    return dealerships?.find((d) => d?._id === id)
  }, [])
  return (
    <Chip
      size="small"
      color="primary"
      sx={{ p: 0.5 }}
      label={dealer?.dname}
      deleteIcon={<CancelIcon />}
      onDelete={() => unSendToDealers(id)}
      disabled={disabled}
    />
  )
}

export default function SentToBox({ list, unSendToDealers, disabled }) {
  return (
    <Stack direction={"row"} flexWrap="wrap" alignItems="center" gap={2}>
      {list?.map((item) => (
        <DealetChip
          id={item}
          disabled={disabled}
          key={item}
          unSendToDealers={unSendToDealers}
        />
      ))}
    </Stack>
  )
}
