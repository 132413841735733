import { Avatar, ListItem, ListItemAvatar, ListItemText } from "@mui/material"
import { green, grey } from "@mui/material/colors"
import React, { useMemo } from "react"
import CheckCircleIcon from "@mui/icons-material/CheckCircle"
import { formatPrice } from "../../../helppers/formatters"
import moment from "moment"

export default function AcceptedView({ item }) {
  const buttonSx = {
    bgcolor: green[500],
  }
  const action = useMemo(() => {
    return JSON.parse(item?.action)
  }, [item?.action])
  return (
    <ListItem key={item?._id} alignItems="flex-start">
      <ListItemAvatar>
        <Avatar sx={{ ...buttonSx }}>
          <CheckCircleIcon />
        </Avatar>
      </ListItemAvatar>
      <ListItemText
        primary={`${item?.createdBy} / ${item?.delaership?.dname}`}
        secondary={
          <React.Fragment>{`${action?.txt} - ${formatPrice(
            action?.amount
          )} at ${moment(item?.createdAt).format("MM/DD hA")}`}</React.Fragment>
        }
      />
    </ListItem>
  )
}
