import * as React from "react"
import Box from "@mui/material/Box"
import Card from "@mui/material/Card"
import CardActions from "@mui/material/CardActions"
import CardContent from "@mui/material/CardContent"
import Button from "@mui/material/Button"
import Typography from "@mui/material/Typography"
import { Tooltip } from "@mui/material"
import { Link } from "react-router-dom"
import useData from "../../context/Data"
import SimpleModal from "../modals/SimpleModal"
import EditMarket from "./EditMarket"

const bull = (
  <Box
    component="span"
    sx={{ display: "inline-block", mx: "2px", transform: "scale(0.8)" }}
  >
    •
  </Box>
)

export default function MarketCard({ market }) {
  const { teams } = useData()
  const [open, setOpen] = React.useState(false)

  const team = React.useMemo(() => {
    return teams?.find((t) => t?._id === market?.team)
  }, [])
  return (
    <>
      <Box sx={{ minWidth: 275 }}>
        <Card variant="outlined" sx={{position: 'relative'}}>
        <Button size="small" sx={{position: 'absolute', bottom:15 , right: 10}} variant="contained" onClick={() => setOpen(true)}>Edit</Button>
          <CardContent>
            <Tooltip title={market?.name} placement="top">
              <Typography
                variant="h6"
                component="div"
                sx={{
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  whiteSpace: "nowrap",
                }}
              >
                {market?.name}
              </Typography>
            </Tooltip>
            <Typography color="text.secondary">
              {market?.state} {bull} {team && `${team?.name} Team`}{" "}
            </Typography>
          </CardContent>
        </Card>
      </Box>
      <SimpleModal
        open={open}
        setOpen={setOpen}
        isBack={true}
        setTitle={() => {}}
      >
        <EditMarket market={market} setOpen={setOpen} />
      </SimpleModal>
    </>
  )
}
