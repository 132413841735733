import { Box, Button, Divider, Slider, Stack, Typography } from "@mui/material"
import React, { useCallback, useEffect, useMemo, useState } from "react"
import axios from "axios"
import MultiSelectInput from "../leadPage/inputs/MultiSelectInput"
import { URL } from "../../config/apis"

export default function AdvencedFilters({
  years,
  setYears,
  makes,
  setMakes,
  models,
  setModels,
  getLeads,
  setOpen,
}) {
  const [_makes, set_Makes] = useState([])
  const [_models, set_Models] = useState([])

  const getMakes = useCallback(async () => {
    try {
      const { data } = await axios.get(
        `${URL}/grouped-filters/makes?start=${years[0]}&end=${years[1]}`,
        {
          headers: {
            "Content-Type": "application/json",
          },
          withCredentials: true,
        }
      )
      set_Makes(data)
      //   setMakes([])
      set_Models([])
      setModels([])
    } catch (error) {
      console.log(error)
    }
  }, [years])

  const getModels = async () => {
    try {
      const { data } = await axios.post(
        `${URL}/grouped-filters/models`,
        {
          years,
          makes,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
          withCredentials: true,
        }
      )
      set_Models(data)
      setModels([])
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    getMakes()
  }, [])

  useMemo(() => {
    if (makes?.length > 0) {
      getModels()
    } else {
      set_Models([])
      setModels([])
    }
  }, [makes])

  return (
    <Stack minWidth={500} spacing={2}>
      <Typography variant="h6">Advanced Filters</Typography>
      <Divider />
      <Stack spacing={2}>
        <Box sx={{ width: "100%", px: 2 }}>
          <Typography>Years</Typography>
          <Slider
            getAriaLabel={() => "Years range"}
            value={years}
            onChange={(e, value) => setYears(value)}
            valueLabelDisplay="auto"
            //    getAriaValueText={valuetext}
            min={2000}
            max={2024}
            onBlur={getMakes}
          />
        </Box>
        <MultiSelectInput
          selected={makes}
          setSelected={setMakes}
          label={"Makes"}
          options={_makes}
        />
        <MultiSelectInput
          selected={models}
          setSelected={setModels}
          label={"Models"}
          options={_models}
        />
      </Stack>
      <Divider />
      <Stack
        direction={"row"}
        spacing={3}
        alignItems={"center"}
        justifyContent={"end"}
      >
        <Button onClick={() => setOpen(false)} variant="text" color="error">
          Close
        </Button>
        <Button onClick={getLeads} variant="contained">
          Filter
        </Button>
      </Stack>
    </Stack>
  )
}
