import * as React from "react"
import OutlinedInput from "@mui/material/OutlinedInput"
import InputLabel from "@mui/material/InputLabel"
import MenuItem from "@mui/material/MenuItem"
import FormControl from "@mui/material/FormControl"
import ListItemText from "@mui/material/ListItemText"
import Select from "@mui/material/Select"
import Checkbox from "@mui/material/Checkbox"
import useData from "../../../context/Data"
import { Divider, ListSubheader } from "@mui/material"

const ITEM_HEIGHT = 48
const ITEM_PADDING_TOP = 8
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 8.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
}

export default function SelectWithCheckMark({
  selected,
  setSelected,
  selectedDealers,
  setSelectedDealers,
  list,
  lead,
}) {
  const { dealerships } = useData()
  const handleChange = (event) => {
    const {
      target: { value },
    } = event
    setSelectedDealers(value)
    setSelected(value?.map((v) => v?._id))
  }

  const isSelected = (id) => {
    // const d = selectedDealers?.find((i) => i?._id === id)
    // if (!d) return false
    return selected?.includes(id)
  }

  const matched = React.useMemo(() => {
    return lead?.match?.filter((d) => !list?.includes(d?._id))
  }, [lead?.match, selected, list])

  const otherDealers = React.useMemo(() => {
    return dealerships?.filter(
      (d) =>
        !list?.includes(d?._id) &&
        d?.isActive &&
        d?.market === lead?.market?._id &&
        !lead?.match?.map((dl) => dl?._id)?.includes(d?._id)
    )
  }, [lead?.match, selected, list, lead?.market?._id])

  return (
    <div style={{ width: "100%" }}>
      <FormControl size="small" sx={{ width: "100%" }}>
        <InputLabel id="demo-multiple-checkbox-label">Dealers</InputLabel>
        <Select
          labelId="demo-multiple-checkbox-label"
          id="demo-multiple-checkbox"
          multiple
          value={selectedDealers}
          onChange={handleChange}
          input={<OutlinedInput sx={{ width: "100%" }} label="Dealers" />}
          renderValue={(selected) => selected?.map((i) => i?.dname)?.join(", ")}
          MenuProps={MenuProps}
        >
          <ListSubheader>Matched Dealers</ListSubheader>
          {matched?.length > 0 ? (
            matched?.map((name) => (
              <MenuItem key={name?._id} value={name}>
                <Checkbox checked={isSelected(name?._id)} />
                <ListItemText primary={name?.dname} />
              </MenuItem>
            ))
          ) : (
            <em style={{ fontSize: 14, padding: "0 20px" }}>Empty</em>
          )}
          <div style={{ width: 10, height: 10 }} />
          <Divider />
          <ListSubheader>Others</ListSubheader>
          {
            // dealerships &&
            //   dealerships
            //     ?.filter(
            //       (d) =>
            //         !list?.includes(d?._id) &&
            //         d?.isActive &&
            //         d?.market === lead?.market?._id &&
            //         !lead?.match?.map((dl) => dl?._id)?.includes(d?._id)
            //     )
            otherDealers?.length > 0 ? (
              otherDealers?.map((name) => (
                <MenuItem key={name?._id} value={name}>
                  <Checkbox checked={isSelected(name?._id)} />
                  <ListItemText primary={name?.dname} />
                </MenuItem>
              ))
            ) : (
              <em style={{ fontSize: 14, padding: "0 20px" }}>Empty</em>
            )
          }
        </Select>
      </FormControl>
    </div>
  )
}
