export const lostReasons = [
  { title: "Undisclosed Damaged", value: "Undisclosed Damaged" },
  { title: "Sold to 3rd Party", value: "Sold to 3rd Party" },
  { title: "Ghosted", value: "Ghosted" },
  { title: "Market Not Serviced", value: "Market Not Serviced" },
  { title: "Decided to Keep Vehicle", value: "Decided to Keep Vehicle" },
  { title: "Title Issues", value: "Title Issues" },
  { title: "Dealer/Wholesaler", value: "Dealer/Wholesaler" },
  { title: "Dealer Low Balled (No Reason)", value: "Dealer Low Balled" },
  {
    title: "Lost to Another Dealer",
    value: "Lost to Another Dealer",
  },
  { title: "Lease (Dealer Can't Buy)", value: "Lease" },
]
