import * as React from "react"
import Table from "@mui/material/Table"
import TableBody from "@mui/material/TableBody"
import TableCell from "@mui/material/TableCell"
import TableContainer from "@mui/material/TableContainer"
import TableHead from "@mui/material/TableHead"
import TableRow from "@mui/material/TableRow"
import Paper from "@mui/material/Paper"
import { formatDate, formatPrice } from "../../helppers/formatters"
import { Link } from "react-router-dom"

function Row({ lead }) {
  return (
    <React.Fragment>
      <TableRow sx={{ "& > *": { borderBottom: "unset" } }}>
        <TableCell component="th" scope="row">
          <Link
            style={{
              color: "inherit",
              fontWeight: 600,
              textDecoration: "none",
            }}
            to={`/leads/item/${lead?._id}`}
          >
            {lead?.title}
          </Link>
        </TableCell>
        <TableCell align="left">{lead.vin}</TableCell>
        <TableCell align="left">{lead.market?.name}</TableCell>
        <TableCell align="center">{formatDate(lead.updatedAt)}</TableCell>
      </TableRow>
    </React.Fragment>
  )
}

export default function LostTable({ leads }) {
  return (
    <TableContainer component={Paper}>
      <Table aria-label="collapsible table">
        <TableHead>
          <TableRow sx={{ fontWeight: 500 }}>
            <TableCell>Title</TableCell>
            <TableCell align="left">VIN</TableCell>
            <TableCell align="left">Market</TableCell>
            <TableCell align="center">Last Update</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {leads && leads?.map((lead) => <Row key={lead?._id} lead={lead} />)}
        </TableBody>
      </Table>
    </TableContainer>
  )
}
