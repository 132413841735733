import { Divider, Stack } from "@mui/material"
import React from "react"
import ActivityBox from "./ActivityBox"
import ActivityInputBox from "./ActivityInputBox"

export default function LeadActivity({ activity, setActivity }) {
  return (
    <div style={{ padding: 14 }}>
      <h3>Activity</h3>
      <Stack style={{ padding: "40px 20px" }}>
        <ActivityInputBox setActivity={setActivity} />
        <Divider variant="fullWidth" style={{ margin: "30px 0" }} />
        {activity &&
          activity?.map((d, i) => (
            <>
              <ActivityBox key={d?._id + i} activity={d} />
              {i !== activity?.length - 1 && (
                <Divider
                  key={d._id}
                  variant="fullWidth"
                  style={{ margin: "30px 0" }}
                />
              )}
            </>
          ))}
      </Stack>
    </div>
  )
}
