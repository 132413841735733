import { Stack } from "@mui/material"
import Container from "@mui/material/Container"
import axios from "axios"
import { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import FeedBackBox from "../components/global/FeedBackBox"
import { URL } from "../config/apis"
import Pagination from "@mui/material/Pagination"
import { useMemo } from "react"
import SkeletonCard from "../components/global/SkeletonCard"
import DealsTable from "../components/tables/DealsTable"
import LostTable from "../components/tables/LostTable"

const pageSize = 20

export default function TrashedPage() {
  const cash = JSON.parse(sessionStorage.getItem("trash-Page"))

  const [leads, setLeads] = useState([])
  const [err, setErr] = useState(null)
  const [count, setCount] = useState(0)
  const [loading, setLoading] = useState(true)
  const [fetching, setFetching] = useState(false)

  const [page, setPage] = useState(cash?.page || 1)

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  const getLeads = async () => {
    setFetching(true)
    try {
      const res = await axios.get(`${URL}/leads/trash?page=${page}`, {
        headers: {
          "Content-Type": "application/json",
        },
        withCredentials: true,
      })
      setLeads(res.data?.leads)
      setCount(res.data?.count)
      setLoading(false)
      setFetching(false)
    } catch (error) {
      setLoading(false)
      setFetching(false)
      setErr(error?.response?.data?.message || error.message)
    }
  }

  useEffect(() => {
    getLeads()

    sessionStorage.setItem("trash-Page", JSON.stringify({ page }))
  }, [page])

  const totalPages = useMemo(() => {
    return Math.ceil(count / pageSize)
  }, [count, pageSize])

  if (loading) {
    return <SkeletonCard />
  }
  if (err) {
    return <>{err}</>
  }

  return (
    <>
      <Container maxWidth="100%">
        <LostTable leads={leads} />
        <br />
        {totalPages > 0 && (
          <Stack
            direction={"row"}
            justifyContent="center"
            sx={{ mt: 2 }}
            spacing={2}
          >
            <Pagination
              page={page}
              onChange={handleChangePage}
              count={totalPages}
              showFirstButton
              showLastButton
            />
          </Stack>
        )}
      </Container>
    </>
  )
}
