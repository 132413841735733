import React from "react"
import Stack from "@mui/material/Stack"
import TextField from "@mui/material/TextField"
import {
  FormControl,
  InputAdornment,
  InputLabel,
  OutlinedInput,
} from "@mui/material"

export default function NumberRange({
  title = "Price",
  setCreiteria,
  selected,
  sign = false,
  xkey,
}) {
  const handleSelect = (e, theKey, nKey) => {
    setCreiteria((prev) => {
      const t = prev[xkey]
      if (!t) {
        return { ...prev, [xkey]: { [theKey]: e, [nKey]: selected[nKey] } }
      }
      return { ...prev, [xkey]: { [theKey]: e, [nKey]: t[nKey] } }
    })
  }
  return (
    <Stack direction={"row"} spacing={1}>
      <FormControl sx={{ width: "100%" }} size="small">
        <InputLabel htmlFor={"ninVal-" + xkey}>{`Min ${title}`}</InputLabel>
        <OutlinedInput
          id={"ninVal-" + xkey}
          value={selected == null ? null : selected?.minVal?.toLocaleString()}
          onChange={(e) =>
            handleSelect(
              parseFloat((e.target.value || "0").replace(/,/g, "")),
              "ninVal",
              "maxVal"
            )
          }
          startAdornment={
            sign ? <InputAdornment position="start">$</InputAdornment> : null
          }
          label={`Min ${title}`}
        />
      </FormControl>
      <FormControl sx={{ width: "100%" }} size="small">
        <InputLabel htmlFor={"maxVal-" + xkey}>{`Max ${title}`}</InputLabel>
        <OutlinedInput
          id={"maxVal-" + xkey}
          value={selected == null ? null : selected?.maxVal?.toLocaleString()}
          onChange={(e) =>
            handleSelect(
              parseFloat((e.target.value || "0").replace(/,/g, "")),
              "maxVal",
              "ninVal"
            )
          }
          startAdornment={
            sign ? <InputAdornment position="start">$</InputAdornment> : null
          }
          label={`Max ${title}`}
        />
      </FormControl>
    </Stack>
  )
}
