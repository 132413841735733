import { Alert, Button, Stack, Typography } from "@mui/material"
import Box from "@mui/material/Box"
import Container from "@mui/material/Container"
import Grid from "@mui/material/Grid"
import axios from "axios"
import { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import { LeadsBox } from "../components/boxes"
import FeedBackBox from "../components/global/FeedBackBox"
import { URL } from "../config/apis"
import Pagination from "@mui/material/Pagination"
import { useMemo } from "react"
import SleletonTable from "../components/global/SleletonTable"
import LeadsFilters from "../components/filters/LeadsFilters"
import AcceptedTable from "../components/tables/AcceptedTable"

const pageSize = 20

export default function AcceptedPage() {
  const cash = JSON.parse(sessionStorage.getItem("acceptedPage"))

  const [leads, setLeads] = useState([])
  const [err, setErr] = useState(null)
  const [error, setError] = useState(null)
  const [count, setCount] = useState(0)
  const [loading, setLoading] = useState(true)
  const [isLoading, setIsLoading] = useState(false)
  const [fetching, setFetching] = useState(false)
  const [show, setShow] = useState(false)

  const [page, setPage] = useState(cash?.page || 1)
  const [market, setMarket] = useState(cash?.market || "All")
  const [claimer, setClaimer] = useState(cash?.claimer || "All")
  const [dealership, setDealership] = useState(cash?.dealership || "All")

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  const getLeads = async () => {
    setFetching(true)
    try {
      const res = await axios.get(
        `${URL}/leads/accepted?page=${page}&market=${market}&claimer=${claimer}&dealership=${dealership}`,
        {
          headers: {
            "Content-Type": "application/json",
          },
          withCredentials: true,
        }
      )
      setLeads(res.data?.leads)
      setCount(res.data?.count)
      setLoading(false)
      setFetching(false)
    } catch (error) {
      setLoading(false)
      setFetching(false)
      setErr(error?.response?.data?.message)
    }
  }

  useEffect(() => {
    getLeads()

    sessionStorage.setItem(
      "acceptedPage",
      JSON.stringify({ page, market, claimer, dealership })
    )
  }, [page, market, claimer, dealership])

  const totalPages = useMemo(() => {
    return Math.ceil(count / pageSize)
  }, [count, pageSize])

  if (loading) {
    return <SleletonTable />
  }

  return (
    <>
      {fetching && (
        <div className="alert">
          <Alert icon={<></>} severity="info">
            Loading...
          </Alert>
        </div>
      )}
      <Container maxWidth="100%">
        <LeadsFilters
          market={market}
          setMarket={setMarket}
          //  agent={agent}
          //  setAgent={setAgent}
          setPage={setPage}
          count={count}
          claimer={claimer}
          setClaimer={setClaimer}
          dealership={dealership}
          setDealership={setDealership}
          isDealer={true}
          isAgent={false}
        />
        <Box>
          {leads?.length === 0 ? (
            <Stack
              sx={{ py: 3, px: 5, minHeight: "500px" }}
              justifyContent={"center"}
              alignItems="center"
              spacing={2}
            >
              <Typography color={"gray"} variant="h5">
                No Accepted Deals Found
              </Typography>
            </Stack>
          ) : (
            <AcceptedTable data={leads} />
          )}
        </Box>
        <br />
        {totalPages > 1 && (
          <Stack
            direction={"row"}
            justifyContent="center"
            sx={{ mt: 2 }}
            spacing={2}
          >
            <Pagination
              page={page}
              onChange={handleChangePage}
              count={totalPages}
              showFirstButton
              showLastButton
            />
          </Stack>
        )}
      </Container>
      <FeedBackBox loading={isLoading} show={show} err={error} />
    </>
  )
}
