import * as React from "react"
import { Theme, useTheme } from "@mui/material/styles"
import Box from "@mui/material/Box"
import OutlinedInput from "@mui/material/OutlinedInput"
import InputLabel from "@mui/material/InputLabel"
import MenuItem from "@mui/material/MenuItem"
import FormControl from "@mui/material/FormControl"
import Select, { SelectChangeEvent } from "@mui/material/Select"
import Chip from "@mui/material/Chip"
import axios from "axios"
import useData from "../../context/Data"
import { Alert, Button } from "@mui/material"
import { URL } from "../../config/apis"

const ITEM_HEIGHT = 48
const ITEM_PADDING_TOP = 8
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
}

function getStyles(name, personName, theme) {
  return {
    fontWeight:
      personName.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  }
}

export default function SelectMarkets() {
  const theme = useTheme()
  const { markets, ep_markets, setEp_Markets } = useData()
  const [selectedMarkets, setSelectedMarkets] = React.useState(ep_markets)
  const [loading, setLoading] = React.useState(false)
  const [success, setSucess] = React.useState(null)
  const [error, setError] = React.useState(null)

  const handleChange = (event) => {
    const {
      target: { value },
    } = event
    setSelectedMarkets(typeof value === "string" ? value.split(",") : value)
  }
  const saveSettings = async () => {
    setError(null)
    setSucess(null)
    setLoading(true)
    try {
      const res = await axios.patch(
        `${URL}/settings?key=EP_MARKETS`,
        selectedMarkets,
        {
          headers: {
            "Content-Type": "application/json",
          },
          withCredentials: true,
        }
      )
      if (res.data.ok) {
        setEp_Markets(selectedMarkets)
        setLoading(false)
        setSucess("Settings Updated succesfully")
      }
    } catch (error) {
      setError(error.response?.data?.message || error.message)
      setLoading(false)
      console.log(error)
    } finally {
      setTimeout(() => {
        setSucess(null)
      }, 2000)
    }
  }

  React.useLayoutEffect(() => {
    setSelectedMarkets(ep_markets)
  }, [ep_markets])

  return (
    <div>
      {loading && (
        <Alert sx={{ mb: 2 }} severity="info">
          Please wait ...
        </Alert>
      )}
      {success && (
        <Alert sx={{ mb: 2 }} severity="success">
          {success}
        </Alert>
      )}
      {error && (
        <Alert sx={{ mb: 2 }} severity="error">
          {error}
        </Alert>
      )}

      <FormControl disabled={loading} fullWidth sx={{ m: 1 }}>
        <InputLabel id="Echopart-Markets">Echopart Markets</InputLabel>
        <Select
          labelId="Echopart-Markets"
          id="demo-Markets"
          multiple
          value={selectedMarkets}
          onChange={handleChange}
          input={<OutlinedInput id="select-Markets" label="Echopart Markets" />}
          renderValue={(selected) => (
            <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
              {selected.map((value) => (
                <Chip
                  key={value}
                  label={markets?.find((m) => m?._id === value)?.name}
                />
              ))}
            </Box>
          )}
          MenuProps={MenuProps}
        >
          {markets &&
            markets.map((market) => (
              <MenuItem
                key={market?._id}
                value={market?._id}
                style={getStyles(market?._id, selectedMarkets, theme)}
              >
                {market?.name}
              </MenuItem>
            ))}
        </Select>
      </FormControl>
      <br />
      <Button
        disabled={loading}
        onClick={saveSettings}
        variant="contained"
        color="success"
      >
        Save
      </Button>
    </div>
  )
}
