import { URL } from "../../config/apis"
import axios from "axios"

export const getStateTz = async (state, setState) => {
  if (!state) {
    return
  }
  setState(null)
  try {
    const res = await axios.get(`${URL}/constants/tz/${state}`, {
      withCredentials: true,
    })
    setState(res.data)
  } catch (error) {
    console.log(error)
    return
  }
}
