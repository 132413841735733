import React from "react"
import {
  Box,
  Button,
  Divider,
  FormControlLabel,
  FormGroup,
  Grid,
  MenuItem,
  Stack,
  Switch,
  TextField,
} from "@mui/material"
import SelectMenu from "../createPage/SelectMenu"
import PricesInput from "../createPage/PricesInput"
import { isAllowed } from "../../helppers/checkRoles"
import useData from "../../context/Data"
import useAuth from "../../context/Auth"
import ImageUploader from "./ImageUploader"

export default function ProfileInfo({
  dealership,
  setDealership,
  saveInfo,
  loading,
}) {
  const { user } = useAuth()
  const { markets } = useData()

  const handleChangeSelect = (e, name) => {
    setDealership((prev) => ({ ...prev, [name]: e }))
  }
  const onValueChange = (e, name) => {
    setDealership((prev) => ({ ...prev, [name]: e }))
  }

  const disableEnter = (e) => {
    var key = e.charCode || e.keyCode || 0
    if (key == 13) {
      e.preventDefault()
    }
  }

  const saveData = async (e) => {
    e.preventDefault()

    const data = {
      market: dealership?.market,
      address: dealership?.address,
      dname: dealership?.dname,
      city: dealership?.city,
      zip: dealership?.zip,
      fee: dealership?.fee,
      isActive: dealership?.isActive,
      askFor: dealership?.askFor,
      email: dealership?.email,
    }
    await saveInfo(data)
  }

  return (
    <Box
      component="form"
      sx={{
        "& > :not(style)": { width: "100%" },
        display: "flex",
        flexDirection: "column",
        gap: ".5rem",
      }}
      noValidate
      autoComplete="off"
      onSubmit={saveData}
      onKeyPress={disableEnter}
    >
      <Grid container spacing={3} columns={4} sx={{ px: 4, py: 2 }}>
        <Grid item sm={4}>
          <ImageUploader
            dealership={dealership}
            setDealership={setDealership}
            isLoading={loading}
          />
        </Grid>
        <Grid item sm={4}>
          <TextField
            size="small"
            id="outlined-basic"
            label="Dealership Name"
            variant="outlined"
            fullWidth
            value={dealership?.dname}
            onChange={(e) => onValueChange(e.target.value, "dname")}
            disabled={loading}
          />
        </Grid>

        <Grid item sm={4}>
          <TextField
            size="small"
            id="outlined-basic"
            label="Dealership Email"
            variant="outlined"
            fullWidth
            required={false}
            value={dealership?.email}
            onChange={(e) => onValueChange(e.target.value, "email")}
            disabled={loading}
          />
        </Grid>
        <Grid item sm={4}>
          <TextField
            size="small"
            id="outlined-basic"
            label="Dealership Address"
            variant="outlined"
            fullWidth
            value={dealership?.address}
            onChange={(e) => onValueChange(e.target.value, "address")}
            disabled={loading}
          />
        </Grid>
        <Grid item sm={2}>
          <TextField
            size="small"
            id="outlined-basic"
            label="City"
            variant="outlined"
            fullWidth
            value={dealership?.city}
            onChange={(e) => onValueChange(e.target.value, "city")}
            disabled={loading}
          />
        </Grid>
        <Grid item sm={2}>
          <TextField
            size="small"
            id="outlined-basic"
            label="ZIP"
            variant="outlined"
            fullWidth
            value={dealership?.zip}
            onChange={(e) => onValueChange(e.target.value, "zip")}
            disabled={loading}
          />
        </Grid>
        <Grid item sm={4}>
          <TextField
            size="small"
            id="outlined-basic"
            label="Ask For"
            variant="outlined"
            fullWidth
            value={dealership?.askFor}
            onChange={(e) => onValueChange(e.target.value, "askFor")}
            disabled={loading}
          />
        </Grid>

        <Grid item sm={2}>
          <SelectMenu
            sx={{ minWidth: 150 }}
            label="Market"
            options={markets}
            value={dealership?.market}
            handleChange={handleChangeSelect}
            name={"market"}
            maxHeight={350}
            disabled={loading}
          >
            {markets?.map((o, i) => (
              <MenuItem key={o?._id + i} value={o?._id}>
                {o?.name}
              </MenuItem>
            ))}
          </SelectMenu>
        </Grid>
        {/* {isAllowed(user?.role, ["super-admin"]) && (
          <Grid item sm={2}>
            <SelectMenu
              sx={{ minWidth: 250 }}
              label="Team"
              options={teams}
              value={dealership?.team}
              handleChange={handleChangeSelect}
              name={"team"}
            >
              {teams &&
                teams?.map((o, i) => (
                  <MenuItem key={o._id + i} value={o._id}>
                    {o.name}
                  </MenuItem>
                ))}
            </SelectMenu>
          </Grid>
        )} */}
        {isAllowed(user?.role, ["super-admin"]) && (
          <Grid item sm={2}>
            <PricesInput
              lable="Fee"
              value={dealership?.fee}
              onValueChange={onValueChange}
              name="fee"
              disabled={loading}
            />
          </Grid>
        )}
        {isAllowed(user?.role, ["super-admin"]) && (
          <Grid item sm={2}>
            <FormGroup>
              <FormControlLabel
                control={
                  <Switch
                    checked={dealership?.isActive}
                    disabled={loading}
                    onChange={(e) =>
                      onValueChange(e.target.checked, "isActive")
                    }
                  />
                }
                label="Is Active?"
              />
            </FormGroup>
          </Grid>
        )}
      </Grid>
      {
        <>
          <Divider />
          <Stack
            direction={"row"}
            justifyContent="end"
            alignItems={"center"}
            spacing={2}
            px={2}
            pb={2}
          >
            <Button
              disabled={loading}
              type="submit"
              variant="contained"
              color="success"
            >
              Update
            </Button>
          </Stack>
        </>
      }
    </Box>
  )
}
