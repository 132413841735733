import {
  Alert,
  Button,
  Container,
  CssBaseline,
  Paper,
  Stack,
  TextField,
  Typography,
} from "@mui/material"
import axios from "axios"
import React from "react"
import { useState } from "react"
import { URL } from "../config/apis"
import useAuth from "../context/Auth"

export default function LoginPage() {
  const { setUser, setLoading } = useAuth()

  const [err, setErr] = useState(null)
  const [isLoding, setIsLoading] = useState(false)

  const login = async (e) => {
    e.preventDefault()
    const form = new FormData(e.target)
    const formData = Object.fromEntries(form.entries())
    setIsLoading(true)
    try {
      const res = await axios.post(`${URL}/auth/login`, formData, {
        headers: {
          "Content-Type": "application/json",
        },
        withCredentials: true,
      })
      setIsLoading(false)
      setLoading(true)
      setTimeout(() => {
        // setUser(res.data.user)
        // setLoading(false)
        window.location.reload()
      }, 500)
    } catch (error) {
      setErr(error.response?.data?.message || error.message)
      setIsLoading(false)
    }
  }

  return (
    <React.Fragment>
      <div style={{ backgroundColor: "#0059ff" }}>
        <CssBaseline />
        <Container
          maxWidth="xs"
          sx={{
            width: "100%",
            //  flexDirection: "column",
            display: "flex",
            minHeight: "100vh",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Paper sx={{ width: "100%" }}>
            <Stack
              component="form"
              sx={{
                width: "100%",
                height: "100%",
                bgcolor: "white",
                py: 10,
                px: 5,
              }}
              alignItems="center"
              justifyContent={"center"}
              spacing={2}
              noValidate
              autoComplete="off"
              onSubmit={login}
            >
              {err && (
                <Alert severity="error">
                  <div dangerouslySetInnerHTML={{ __html: err }} />
                </Alert>
              )}

              <Stack alignItems={"center"}>
                <img src="/dark-logo.png" height={80} />
                <Typography color={"#0059ff"} fontWeight={700} variant="h6">
                  Welcome Back
                </Typography>
                {/* <Typography pt={2} fontWeight={700} variant="h6">
                Login
              </Typography> */}
              </Stack>
              <TextField
                size="small"
                id="email"
                label="Email"
                variant="outlined"
                fullWidth
                name="email"
                //type="email"
              />
              <TextField
                size="small"
                id="password"
                label="Password"
                variant="outlined"
                fullWidth
                name="password"
                type="password"
              />
              <Button
                fullWidth
                variant="contained"
                type="submit"
                disabled={isLoding}
              >
                {isLoding ? "submitting" : "login"}
              </Button>
            </Stack>
          </Paper>
        </Container>
      </div>
    </React.Fragment>
  )
}
