import * as React from "react"
import Accordion from "@mui/material/Accordion"
import AccordionSummary from "@mui/material/AccordionSummary"
import AccordionDetails from "@mui/material/AccordionDetails"
import Typography from "@mui/material/Typography"
import ExpandMoreIcon from "@mui/icons-material/ExpandMore"
import { Stack } from "@mui/material"
import SingleResponseByButtons from "./questions/SingleResponseByButtons"

export default function AccordinationData({ condition, setCondition }) {
  const handleChange = (value, cat) => {
    setCondition((prev) => {
      const newMap = new Map(prev)
      let q = newMap.get(cat)
      q.responseOptions = q?.responseOptions?.map((r) => {
        if (r.id === value) {
          return { ...r, isSelected: !r?.isSelected }
        } else {
          return { ...r, isSelected: false }
        }
      })
      newMap.set(cat, q)
      return new Map(newMap)
    })
  }
  // const handleChange = (value, cat, q) => {
  //   value = JSON.parse(value)
  //   setCondition((prev) => {
  //     let categories = prev?.categories || []
  //     categories = categories.map((c) => {
  //       if (c.category.id === cat) {
  //         const questions = c.questions.map((qt) => {
  //           if (qt.id === q) {
  //             let responseOptions = qt.responseOptions || []

  //             responseOptions = responseOptions.map((r) => {
  //               if (r.id === value?.id) {
  //                 return { ...value, isSelected: true }
  //               } else if (r?.needSubResponsesIfSelected) {
  //                 let o = r.responseClarification?.responseOptions
  //                 if (o) {
  //                   o = o?.map((op) => {
  //                     return { ...op, isSelected: false }
  //                   })
  //                 }
  //                 return {
  //                   ...r,
  //                   responseClarification: {
  //                     ...r?.responseClarification,
  //                     responseOptions: o,
  //                     isAnswered: false,
  //                   },
  //                   isSelected: false,
  //                 }
  //               } else {
  //                 return { ...r, isSelected: false }
  //               }
  //             })
  //             return { ...qt, responseOptions, isAnswered: true }
  //           } else {
  //             return qt
  //           }
  //         })
  //         return { ...c, questions }
  //       } else {
  //         return c
  //       }
  //     })
  //     return { ...prev, categories }
  //   })
  // }

  const handleChangeResp = (value, cat, checked, repId) => {
    setCondition((prev) => {
      const newMap = new Map(prev)
      let q = newMap.get(cat)
      q.responseOptions = q?.responseOptions?.map((r) => {
        if (r.id === repId && !!r.responseClarification && r.isSelected) {
          let rc = r.responseClarification
          rc.responseOptions = rc.responseOptions?.map((o) => {
            if (o.id === value) {
              return { ...o, isSelected: checked }
              // } else if (value === "OTHER" && checked) {
              //   return { ...o, isSelected: false }
              // } else if (o.id === "OTHER" && checked) {
              //   return { ...o, isSelected: false }
            } else {
              return o
            }
          })
          return { ...r, responseClarification: rc }
        } else {
          return { ...r }
        }
      })
      newMap.set(cat, q)
      return new Map(newMap)
    })
  }

  // const handleChangeResp = (value, cat, q, checked, repId) => {
  //   setCondition((prev) => {
  //     let categories = prev?.categories || []
  //     categories = categories.map((c) => {
  //       if (c.category.id === cat) {
  //         const questions = c.questions.map((qt) => {
  //           if (qt.id === q) {
  //             let responseOptions = qt.responseOptions || []
  //             responseOptions = responseOptions.map((r) => {
  //               if (r.id === repId) {
  //                 let a = r.responseClarification?.responseOptions || []
  //                 a = a?.map((x) => {
  //                   if (x.id === value.id) {
  //                     return { ...value, isSelected: checked }
  //                   } else {
  //                     return x
  //                   }
  //                 })
  //                 return {
  //                   ...r,
  //                   isSelected: true,
  //                   responseClarification: {
  //                     ...r.responseClarification,
  //                     responseOptions: a,
  //                     isAnswered: true,
  //                   },
  //                 }
  //               } else {
  //                 return {
  //                   ...r,
  //                   isSelected: false,
  //                   responseClarification: r.needSubResponsesIfSelected && {
  //                     ...r.responseClarification,
  //                     isAnswered: false,
  //                   },
  //                 }
  //               }
  //             })
  //             return { ...qt, responseOptions, isAnswered: true }
  //           } else {
  //             return qt
  //           }
  //         })
  //         return { ...c, questions }
  //       } else {
  //         return c
  //       }
  //     })
  //     return { ...prev, categories }
  //   })
  // }
  const handleChangeText = (text, value, cat, q, repId) => {}
  // const handleChangeText = (text, value, cat, q, repId) => {
  //   setCondition((prev) => {
  //     let categories = prev?.categories || []
  //     categories = categories.map((c) => {
  //       if (c.category.id === cat) {
  //         const questions = c.questions.map((qt) => {
  //           if (qt.id === q) {
  //             let responseOptions = qt.responseOptions || []
  //             responseOptions = responseOptions.map((r) => {
  //               if (r.id === repId) {
  //                 let a = r.responseClarification?.responseOptions || []
  //                 a = a?.map((x) => {
  //                   if (x.id === value.id) {
  //                     return { ...value, isSelected: true, freeTextType: text }
  //                   } else {
  //                     return x
  //                   }
  //                 })
  //                 return {
  //                   ...r,
  //                   isSelected: true,
  //                   responseClarification: {
  //                     ...r.responseClarification,
  //                     responseOptions: a,
  //                     isAnswered: true,
  //                   },
  //                 }
  //               } else {
  //                 return { ...r, isSelected: false }
  //               }
  //             })
  //             return { ...qt, responseOptions, isAnswered: true }
  //           } else {
  //             return qt
  //           }
  //         })
  //         return { ...c, questions }
  //       } else {
  //         return c
  //       }
  //     })
  //     return { ...prev, categories }
  //   })
  // }

  return (
    <div>
      {condition &&
        Array.from(condition.values())?.map((c, i) => (
          <Accordion defaultExpanded key={c?.id + i}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
              sx={{ bgcolor: "#fbfbfb" }}
            >
              <Typography>{c?.text}</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Stack pb={2} spacing={1} px={2}>
                <SingleResponseByButtons
                  responseOptions={c.responseOptions}
                  handleChange={handleChange}
                  category={c?.id}
                  handleChangeResp={handleChangeResp}
                  handleChangeText={handleChangeText}
                />
              </Stack>
            </AccordionDetails>
          </Accordion>
        ))}
    </div>
  )
}
