import { Box, Button, Divider, Stack } from "@mui/material"
import axios from "axios"
import React from "react"
import { URL } from "../../config/apis"
import useData from "../../context/Data"
import DealershipCriteria from "../dealership/DealershipCriteria"

export default function CriteriaFrom({ setDealership, dealership }) {
  const { loading, setLoading, setShow, setErr } = useData()

  const [criteria, setCriteria] = React.useState({
    ...dealership?.criteria,
  })


  const updateDealershipCriteria = async () => {
    try {
      const res = await axios.put(
        `${URL}/dealerships/criteria/${dealership?._id}`,
        criteria,
        {
          headers: {
            "Content-Type": "application/json",
          },
          withCredentials: true,
        }
      )

      if (res.data.ok) {
        setDealership((prev) => ({ ...prev, criteria }))
        setShow(true)
      } else {
        setErr("Something went wrong!")
      }
      setLoading(false)
      setTimeout(() => {
        setShow(false)
        setErr(null)
      }, 2000)
    } catch (error) {
      setLoading(false)
      setErr(error.response?.data?.message || error.message)
      setTimeout(() => {
        setErr(null)
      }, 3000)
    }
  }
  return (
    <Box
      sx={{
        "& > :not(style)": { width: "100%" },
        display: "flex",
        flexDirection: "column",
        gap: ".5rem",
      }}
      noValidate
      autoComplete="off"
    >
      <DealershipCriteria isModal={false} criteria={criteria} setCreiteria={setCriteria} />
      {
        <>
          <Divider />
          <Stack
            direction={"row"}
            justifyContent="end"
            alignItems={"center"}
            spacing={2}
            px={2}
            pb={2}
          >
            <Button
              disabled={loading}
              variant="contained"
              color="success"
              onClick={updateDealershipCriteria}
            >
              Update
            </Button>
          </Stack>
        </>
      }
    </Box>
  )
}
