import { createContext, useContext, useEffect, useMemo, useState } from "react"
import { io } from "socket.io-client"

const socket = io("https://api.dealerbids.io", {
  withCredentials: true,
  autoConnect: false,
})
const SocketContext = createContext()

export const SocketContextProvider = ({ children }) => {
  const [online, setOnline] = useState([])

  useEffect(() => {
    socket.connect()
    socket.on("connect_error", () => {
      socket.connect()
    })

    return () => {
      socket.off("connect_error")
    }
  }, [])

  const memoedValue = useMemo(() => ({ online, socket }), [online, socket])

  return (
    <SocketContext.Provider value={memoedValue}>
      {children}
    </SocketContext.Provider>
  )
}

export default function useSocket() {
  return useContext(SocketContext)
}
