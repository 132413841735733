import React, { useEffect, useState } from "react"
import { Button, Container, Paper, Stack } from "@mui/material"
import NavTabs, { CustomTabPanel } from "../components/dealership/NavTabs"
import axios from "axios"
import { URL } from "../config/apis"
import { Link, useParams } from "react-router-dom"
import ProfileInfo from "../components/dealership/ProfileInfo"
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos"
import CriteriaFrom from "../components/dealership/CriteriaFrom"
import AdditionalInfoForm from "../components/dealership/AdditionalInfoForm"
import FeedBackBox from "../components/global/FeedBackBox"

export default function EditDealershipPage() {
  const { id } = useParams()

  const [activeTab, setActiveTab] = React.useState(0)

  const [dealership, setDealership] = useState(null)
  const [loading, setLoading] = useState(true)
  const [err, setErr] = useState(null)

  const [isLoading, setIsLoading] = React.useState(false)
  const [error, setError] = React.useState(null)
  const [show, setShow] = React.useState(false)

  const getDealership = async () => {
    setErr(null)
    try {
      const { data } = await axios.get(
        `${URL}/dealerships/profile/${id}/info`,
        {
          headers: {
            "Content-Type": "application/json",
          },
          withCredentials: true,
        }
      )

      setDealership(data)
      setLoading(false)
    } catch (error) {
      setLoading(false)
      setErr(error.response?.data?.message || error.message)
    }
  }

  useEffect(() => {
    getDealership()
  }, [])

  const updateDealership = async (data) => {
    setErr(null)
    setIsLoading(true)
    try {
      const res = await axios.put(`${URL}/dealerships/profile/${id}`, data, {
        headers: {
          "Content-Type": "application/json",
        },
        withCredentials: true,
      })

      if (res.data.ok) {
        setShow(true)
      } else {
        throw new Error("Something went wrong!")
      }
    } catch (error) {
      setErr(error.response?.data?.message || error.message)
    } finally {
      setIsLoading(false)
      setTimeout(() => {
        setShow(false)
        setErr(null)
      }, 2000)
    }
  }

  if (loading) {
    return <>loading...</>
  }

  return (
    <>
      <Container maxWidth="lg">
        <Stack spacing={2}>
          <div>
            <Button
              startIcon={<ArrowBackIosIcon />}
              variant="text"
              LinkComponent={Link}
              to={`/dealerships/profile/${id}`}
            >
              {dealership?.dname}
            </Button>
          </div>
          <Paper component={Stack} flex={{ xs: 1, lg: 5 }} width="100%">
            <NavTabs
              value={activeTab}
              setValue={setActiveTab}
              loading={isLoading}
            >
              <CustomTabPanel value={activeTab} index={0}>
                <ProfileInfo
                  dealership={dealership}
                  setDealership={setDealership}
                  saveInfo={updateDealership}
                  loading={isLoading}
                />
              </CustomTabPanel>
              <CustomTabPanel value={activeTab} index={1}>
                <CriteriaFrom
                  dealership={dealership}
                  setDealership={setDealership}
                />
              </CustomTabPanel>
              <CustomTabPanel value={activeTab} index={2}>
                <AdditionalInfoForm
                  dealership={dealership}
                  setDealership={setDealership}
                  saveInfo={updateDealership}
                  loading={isLoading}
                />
              </CustomTabPanel>
            </NavTabs>
          </Paper>
        </Stack>
      </Container>
      <FeedBackBox err={err} loading={isLoading} show={show} />
    </>
  )
}
