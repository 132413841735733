import Box from "@mui/material/Box"
import Container from "@mui/material/Container"
import TextField from "@mui/material/TextField"
import MenuItem from "@mui/material/MenuItem"
import Divider from "@mui/material/Divider"
import Button from "@mui/material/Button"
import ButtonGroup from "@mui/material/ButtonGroup"
import Switch from "@mui/material/Switch"
import FormControlLabel from "@mui/material/FormControlLabel"
import React from "react"
import PricesInput from "../components/createPage/PricesInput"
import SelectMenu from "../components/createPage/SelectMenu"
import CarFaxBox from "../components/global/CarFaxBox"
import AutoCheckBox from "../components/global/AutoCheckBox"
import PopUpDialog from "../components/modals/PopUpDialog"
import { LEAD_INITIAL_STATE } from "../constants/initialStates"
import GirdBoxWrraper from "../components/createPage/GirdBoxWrraper"
import { Chip, Grid, Typography } from "@mui/material"
import { cgUrl, imgUrl, usStatesList } from "../constants/constants"
import IconButton from "@mui/material/IconButton"
import PhotoCamera from "@mui/icons-material/PhotoCamera"
import Stack from "@mui/material/Stack"
import PopDownUpDialog from "../components/modals/PopDownUpDialog"
import {
  bbVinDecoder,
  cargurusDecoder,
  getAutoCheck,
  getCarfaxData,
  vinDecoder,
  vinWvDecoder,
  wvVinDecoder,
} from "../helppers/apiCalls/decoders"
import LoadingBackdrop from "../components/global/LoadingBackdrop"
import CfxSkeleton from "../components/global/CfxSkeleton"
import useData from "../context/Data"
import CheckCircleIcon from "@mui/icons-material/CheckCircle"
import CancelIcon from "@mui/icons-material/Cancel"
import axios from "axios"
import { URL } from "../config/apis"
import { useNavigate, useSearchParams } from "react-router-dom"
import FeedBackBox from "../components/global/FeedBackBox"
import TextEditor from "../components/text-editor/TextEditor"
import CarFaxEditBox from "../components/createPage/CarFaxEditBox"
import { getCgUrl, openSmallPage } from "../helppers/functions"
import { importListingData } from "../helppers/apiCalls/importListingData"
import OptInStatusModal from "../components/modals/OptInStatusModal"

export default function CreateNewListing() {
  const { markets } = useData()
  const navigate = useNavigate()
  const [searchParams, setSearchParams] = useSearchParams()

  const type = searchParams.get("type")

  const [optin, setOptin] = React.useState(false)
  const [open, setOpen] = React.useState(false)
  const [isOpen, setIsOpen] = React.useState(false)
  const [show, setShow] = React.useState(false)
  const [vinLoading, setVinLoading] = React.useState(false)
  const [isLoading, setIsLoading] = React.useState(false)
  const [acLoading, setAcLoading] = React.useState(false)
  const [lead, setLead] = React.useState(LEAD_INITIAL_STATE)
  const [data, setData] = React.useState(null)
  const [acheck, setAcheck] = React.useState(null)
  const [vinErr, setVinErr] = React.useState(null)
  const [acErr, setAcErr] = React.useState(null)
  const [Err, setErr] = React.useState(null)
  const [wvData, setWvData] = React.useState(null)

  const handleChange = (e) => {
    setLead((prev) => ({ ...prev, retailNote: e }))
  }
  const handleChangeSelect = (e, name) => {
    setLead((prev) => ({ ...prev, [name]: e }))
  }
  const onValueChange = (e, name) => {
    if (["transmission", "engine"].includes(name)) {
      setLead((prev) => ({
        ...prev,
        [name]: {
          short: e,
          full: e,
        },
      }))
    } else {
      setLead((prev) => ({ ...prev, [name]: e }))
    }
  }

  const onVinChange = async (e) => {
    const vin = e.target.value
    setLead((prev) => ({ ...prev, vin }))
    if (vin.length === 17) {
      if (type === "new") {
        // await bbVinDecoder(
        //   vin,
        //   lead?.mileage,
        //   lead?.zip,
        //   setLead,
        //   setVinLoading,
        //   setData,
        //   setVinErr
        // )
        // await vinDecoder(
        //   vin,
        //   lead?.mileage,
        //   lead?.zip,
        //   setLead,
        //   setVinLoading,
        //   setData,
        //   setVinErr
        // )
        await wvVinDecoder(vin, setLead, setVinLoading, setData, setVinErr)
      } else if (type === "import") {
        await importListingData(vin, setLead, setVinLoading, setData, setVinErr)
      }
      // await vinWvDecoder(vin, setWvData)
      // await vinDecoder(
      //   vin,
      //   lead?.mileage,
      //   lead?.zip,
      //   setLead,
      //   setVinLoading,
      //   setData,
      //   setVinErr
      // )
      // await getCarfaxData(vin, setLead, setCfxLoading, setCfxErr, setCfx)
      // await getAutoCheck(vin, setLead, setAcLoading, setAcErr, setAcheck)
    }
  }

  // const handleSubmit = (e) => {
  //   e.preventDefault()
  //   setOptin(true)
  // }

  const createNewLead = async (e) => {
    e.preventDefault()
    // if (
    //   !optin ||
    //   typeof optin === "undefined" ||
    //   !["YES", "NO"].includes(optin)
    // ) {
    //   setErr("Please select a valid Opt-In Status ('YES' or 'NO')")
    //   return
    // }
    if (lead?.vin?.length !== 17) {
      setErr("VIN must be 17 characters long")
      return
    }

    setErr(null)
    setShow(false)
    setIsLoading(true)
    try {
      const res = await axios.post(
        `${URL}/leads?type=${type}`,
        { ...lead, optin },
        {
          headers: {
            "Content-Type": "application/json",
          },
          withCredentials: true,
        }
      )
      if (res.data.ok) {
        setShow(true)
        setIsLoading(false)
        setTimeout(() => {
          setShow(false)
          setLead(LEAD_INITIAL_STATE)
          setData(null)
          // setCfx(null)
          setAcheck(null)
          navigate(`/leads/item/${res.data?.id}`)
        }, 2000)
      }
    } catch (error) {
      setIsLoading(false)
      if (error.response) {
        setErr(error.response?.data?.message)
      } else {
        setErr(error.message)
      }
    }
  }

  const disableEnter = (e) => {
    var key = e.charCode || e.keyCode || 0
    if (key == 13) {
      e.preventDefault()
    }
  }

  if (!type) {
    return (
      <Stack
        direction={"row"}
        spacing={2}
        alignItems={"center"}
        justifyContent={"center"}
        minHeight={300}
      >
        <Button
          variant="contained"
          color="success"
          onClick={() => setSearchParams({ type: "new" })}
        >
          New
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={() => setSearchParams({ type: "import" })}
        >
          Import
        </Button>
      </Stack>
    )
  }

  return (
    <>
      <Container maxWidth="100%" sx={{ bgcolor: "white", py: 3 }}>
        <Box
          component="form"
          sx={{
            "& > :not(style)": { mb: 1, width: "100%" },
            display: "flex",
            flexDirection: "column",
            gap: ".5rem",
          }}
          noValidate
          autoComplete="off"
          onSubmit={createNewLead}
          onKeyPress={disableEnter}
        >
          <GirdBoxWrraper>
            <Grid item xs={3}>
              <TextField
                size="small"
                id="outlined-basic"
                label="VIN"
                variant="outlined"
                fullWidth
                value={lead?.vin}
                onChange={onVinChange}
                inputProps={{ maxLength: 17 }}
              />
            </Grid>
            {type === "import" && !data ? (
              <></>
            ) : (
              <>
                <Grid item xs={2}>
                  <TextField
                    size="small"
                    id="outlined-basic"
                    label="Mileage"
                    variant="outlined"
                    fullWidth
                    value={lead?.mileage}
                    onChange={(e) =>
                      onValueChange(parseInt(e.target.value || "0"), "mileage")
                    }
                  />
                </Grid>
                <Grid item xs={1}>
                  <TextField
                    size="small"
                    id="outlined-basic"
                    label="ZIP"
                    variant="outlined"
                    fullWidth
                    inputProps={{ inputMode: "numeric", pattern: "[0-9]*" }}
                    value={lead?.zip}
                    onChange={(e) => onValueChange(e.target.value, "zip")}
                  />
                </Grid>
              </>
            )}
            {/* <Grid item xs={1}>
              <FormControlLabel
                control={
                  <Switch onChange={handleSwitchKbb} checked={lead?.isKbb} />
                }
                label="KBB"
              />
            </Grid> */}
            <Grid item xs={6}>
              {data && !data?.totalLoss && lead?.vin?.length === 17 && (
                <div style={{ display: "flex", justifyContent: "end" }}>
                  <ButtonGroup
                    variant="contained"
                    aria-label="outlined button group"
                  >
                    <Button disabled={isLoading} type="submit" color="success">
                      {isLoading ? "Saving..." : "Save"}
                    </Button>
                  </ButtonGroup>
                </div>
              )}
            </Grid>
          </GirdBoxWrraper>
          {data && (
            <>
              <GirdBoxWrraper>
                <Grid item xs={1}>
                  <TextField
                    size="small"
                    id="outlined-basic"
                    label="Year"
                    variant="outlined"
                    value={lead?.year}
                    inputProps={{ inputMode: "numeric", pattern: "[0-9]*" }}
                    fullWidth
                    onChange={(e) => onValueChange(e.target.value, "year")}
                  />
                </Grid>
                <Grid item xs={2}>
                  <TextField
                    size="small"
                    id="outlined-basic"
                    label="Make"
                    variant="outlined"
                    value={lead?.make}
                    fullWidth
                    onChange={(e) => onValueChange(e.target.value, "make")}
                  />
                </Grid>
                <Grid item xs={2}>
                  <TextField
                    size="small"
                    id="outlined-basic"
                    label="Model"
                    variant="outlined"
                    value={lead?.model}
                    fullWidth
                    onChange={(e) => onValueChange(e.target.value, "model")}
                  />
                </Grid>
                <Grid item xs={2}>
                  <TextField
                    size="small"
                    id="outlined-basic"
                    label="Trim"
                    variant="outlined"
                    value={lead?.trim}
                    fullWidth
                    onChange={(e) => onValueChange(e.target.value, "trim")}
                  />
                </Grid>
                <Grid item xs={2}>
                  <TextField
                    size="small"
                    id="outlined-basic"
                    label="Transmission"
                    variant="outlined"
                    sx={{ flexGrow: 1 }}
                    value={lead?.transmission?.full}
                    onChange={(e) =>
                      onValueChange(e.target.value, "transmission")
                    }
                    fullWidth
                  />
                </Grid>
                <Grid item xs={3}>
                  <TextField
                    size="small"
                    id="outlined-basic"
                    label="Engine"
                    variant="outlined"
                    sx={{ flexGrow: 1 }}
                    value={lead?.engine?.full}
                    fullWidth
                    onChange={(e) => onValueChange(e.target.value, "engine")}
                  />
                </Grid>
              </GirdBoxWrraper>
              <Box
                component="div"
                sx={{ display: "flex", gap: "1rem", width: "100%" }}
              >
                <TextField
                  size="small"
                  id="outlined-basic"
                  label="Vehicle Type"
                  variant="outlined"
                  value={lead?.type}
                  onChange={(e) => onValueChange(e.target.value, "type")}
                />
                <TextField
                  size="small"
                  id="outlined-basic"
                  label="Exterior Color"
                  variant="outlined"
                  value={lead?.extColor}
                  onChange={(e) => onValueChange(e.target.value, "extColor")}
                />
                <TextField
                  size="small"
                  id="outlined-basic"
                  label="Interior Color"
                  variant="outlined"
                  value={lead?.intColor}
                  onChange={(e) => onValueChange(e.target.value, "intColor")}
                />

                {/* <SelectMenu
                  sx={{ minWidth: 150 }}
                  label="Exterior Color"
                  options={[]}
                  value={lead?.extColor}
                  handleChange={handleChangeSelect}
                  name={"extColor"}
                >
                  {[
                    "Title In Hand",
                    "Loan on Vehicle",
                    "Lease on Vehicle",
                  ]?.map((o, i) => (
                    <MenuItem key={o + i} value={o}>
                      {o}
                    </MenuItem>
                  ))}
                </SelectMenu> */}
                {/* <SelectMenu
                  sx={{ minWidth: 150 }}
                  label="Interior Color"
                  options={[]}
                  value={lead?.intColor}
                  handleChange={handleChangeSelect}
                  name={"intColor"}
                >
                  {[
                    "Title In Hand",
                    "Loan on Vehicle",
                    "Lease on Vehicle",
                  ]?.map((o, i) => (
                    <MenuItem key={o + i} value={o}>
                      {o}
                    </MenuItem>
                  ))}
                </SelectMenu> */}
                <SelectMenu
                  sx={{ minWidth: 150 }}
                  label="Title Status"
                  options={[
                    "Title In Hand",
                    "Loan on Vehicle",
                    "Lease on Vehicle",
                  ]}
                  value={lead?.titleStatus}
                  handleChange={handleChangeSelect}
                  name={"titleStatus"}
                >
                  {[
                    "Title In Hand",
                    "Loan on Vehicle",
                    "Lease on Vehicle",
                  ]?.map((o, i) => (
                    <MenuItem key={o + i} value={o}>
                      {o}
                    </MenuItem>
                  ))}
                </SelectMenu>

                <TextField
                  size="small"
                  id="outlined-basic"
                  label="FBMP Id"
                  variant="outlined"
                  sx={{ flexGrow: 1 }}
                  value={lead?.fbmpId}
                  onChange={(e) => onValueChange(e.target.value, "fbmpId")}
                />
                <TextField
                  size="small"
                  id="outlined-basic"
                  label="Other Source"
                  variant="outlined"
                  sx={{ flexGrow: 1 }}
                  value={lead?.otherSource}
                  onChange={(e) => onValueChange(e.target.value, "otherSource")}
                />
                {/* <TextField
                  size="small"
                  id="outlined-basic"
                  label="Accu-Trade Link"
                  variant="outlined"
                  sx={{ flexGrow: 1 }}
                  value={lead?.accuTrade}
                  onChange={(e) => onValueChange(e.target.value, "accuTrade")}
                /> */}
              </Box>
              <Box
                component="div"
                sx={{ display: "flex", gap: "1rem", width: "100%" }}
              >
                <PricesInput
                  lable="MSRP"
                  value={lead?.msrp}
                  onValueChange={onValueChange}
                  name="msrp"
                />
                <PricesInput
                  lable="Listed Price"
                  value={lead?.listedPrice}
                  onValueChange={onValueChange}
                  name="listedPrice"
                />
                <PricesInput
                  lable="MMR"
                  value={lead?.mmr}
                  onValueChange={onValueChange}
                  name="mmr"
                />
                <PricesInput
                  lable="KBB PP"
                  value={lead?.kbbppv}
                  onValueChange={onValueChange}
                  name="kbbppv"
                />
                <PricesInput
                  lable="CG IMV"
                  value={lead?.cgimv}
                  onValueChange={onValueChange}
                  name="cgimv"
                  isCg
                  onClick={() =>
                    openSmallPage(
                      `${getCgUrl(lead?.vin, lead?.mileage, lead?.zip)}`
                    )
                  }
                />
                <PricesInput
                  lable="CG Private"
                  value={lead?.cgPrivate}
                  onValueChange={onValueChange}
                  name="cgPrivate"
                  // isCg
                  // onClick={() =>
                  //   openSmallPage(
                  //     `${getCgUrl(lead?.vin, lead?.mileage, lead?.zip)}`
                  //   )
                  // }
                />
                <TextField
                  size="small"
                  id="outlined-basic"
                  label="MDS"
                  variant="outlined"
                  fullWidth
                  value={lead?.mds}
                  onChange={(e) => onValueChange(e.target.value, "mds")}
                />
                {/* <TextField
                  size="small"
                  id="outlined-basic"
                  label="MDOM"
                  variant="outlined"
                  fullWidth
                  value={lead?.mdom}
                  onChange={(e) => onValueChange(e.target.value, "mdom")}
                /> */}
              </Box>
              <Box
                component="div"
                sx={{ display: "flex", gap: "1rem", width: "100%" }}
              >
                <TextField
                  size="small"
                  id="outlined-basic"
                  label="Seller Name"
                  variant="outlined"
                  value={lead?.sellerName}
                  onChange={(e) => onValueChange(e.target.value, "sellerName")}
                />
                <TextField
                  size="small"
                  id="outlined-basic"
                  label="Cell Phone"
                  variant="outlined"
                  value={lead?.sellerPhone}
                  onChange={(e) => onValueChange(e.target.value, "sellerPhone")}
                />
                <TextField
                  size="small"
                  id="outlined-basic"
                  label="City"
                  variant="outlined"
                  value={lead?.city}
                  onChange={(e) => onValueChange(e.target.value, "city")}
                />
                <SelectMenu
                  sx={{ minWidth: 150 }}
                  label="State"
                  options={usStatesList}
                  value={lead?.state}
                  handleChange={handleChangeSelect}
                  name={"state"}
                >
                  {usStatesList &&
                    usStatesList?.map((o, i) => (
                      <MenuItem key={o.code + i} value={o.code}>
                        {o.name}
                      </MenuItem>
                    ))}
                </SelectMenu>

                <SelectMenu
                  sx={{ minWidth: 150 }}
                  label="Market"
                  options={markets}
                  value={lead?.market}
                  handleChange={handleChangeSelect}
                  name={"market"}
                >
                  {markets?.map((o, i) => (
                    <MenuItem key={o?._id + i} value={o?._id}>
                      {o?.name}
                    </MenuItem>
                  ))}
                </SelectMenu>
                <TextField
                  size="small"
                  id="outlined-fb_name"
                  label="FB Name"
                  variant="outlined"
                  value={lead?.fb_name}
                  onChange={(e) => onValueChange(e.target.value, "fb_name")}
                />
                {/* <FormControlLabel
                  control={
                    <Switch
                      onChange={handleSwitchChange}
                      checked={lead?.isRetail}
                    />
                  }
                  label="Retail Customer"
                /> */}
              </Box>
              <Divider />
              {/* start images */}
              <Stack direction="row" alignItems="center" spacing={2}>
                {/* <IconButton color="primary" onClick={() => setIsOpen(true)}>
                  <PhotoCamera />
                </IconButton> */}
                <Button
                  onClick={() => setIsOpen(true)}
                  variant="contained"
                  startIcon={<PhotoCamera />}
                >
                  Manage Images
                </Button>
                <Divider orientation="vertical" flexItem />
                <Stack direction="row" alignItems="center" spacing={2}>
                  <Typography>Featured Image:</Typography>
                  {lead?.f_image ? (
                    <CheckCircleIcon color="success" />
                  ) : (
                    <CancelIcon color="error" />
                  )}
                </Stack>
                <Divider orientation="vertical" flexItem />
                <Stack direction="row" alignItems="center" spacing={2}>
                  <Typography>Images:</Typography>
                  <Typography>
                    {lead?.images?.length > 0
                      ? lead?.images?.length
                      : "No Images"}{" "}
                    Uploaded
                  </Typography>
                </Stack>
              </Stack>
              {/* end images */}
              <Divider />
              {/* text Editor */}
              <TextEditor value={lead?.condition} setLead={setLead} />
              <Divider />
              <Box
                component="div"
                sx={{
                  display: "flex",
                  gap: "1rem",
                  width: "100%",
                  flexDirection: "column",
                }}
              >
                {/* {cfxLoading ? (
                  <CfxSkeleton />
                ) : cfx ? (
                  <CarFaxBox data={cfx} isClean={lead?.isCleanCfx} />
                ) : cfxErr ? (
                  <div>{cfxErr}</div>
                ) : null} */}
                <CarFaxEditBox setLead={setLead} lead={lead} />
                {!!data?.autoCheck && <AutoCheckBox data={data?.autoCheck} />}

                {/* {acLoading ? (
                  <CfxSkeleton arr={[...Array(6)]} />
                ) : acheck ? (
                  <AutoCheckBox data={acheck} />
                ) : acErr ? (
                  <div>{acErr}</div>
                ) : null} */}
              </Box>
              {!!wvData && (
                <>
                  <Divider />
                  <Stack>
                    <span style={{ fontWeight: "bold" }}>
                      TEST VIN DECODER:
                    </span>
                    {!!wvData?.error ? (
                      <>ERROR: {wvData?.error}</>
                    ) : (
                      !!wvData?.make && (
                        <Stack gap={2}>
                          <Stack direction={"row"} gap={2} flexWrap="wrap">
                            <Chip
                              label={`${wvData?.year} ${wvData?.make} ${wvData?.model} ${wvData?.trim}`}
                              sx={{ px: 0.5, py: 0.5 }}
                            />
                            <Chip
                              label={`Drivetrain: ${wvData?.drivetrain}`}
                              sx={{ px: 0.5, py: 0.5 }}
                            />
                            <Chip
                              label={`Engine: ${wvData?.engine}`}
                              sx={{ px: 0.5, py: 0.5 }}
                            />
                            <Chip
                              label={`Transmission: ${wvData?.transmission}`}
                              sx={{ px: 0.5, py: 0.5 }}
                            />
                            <Chip
                              label={`Exterior Color: ${wvData?.ext_color}`}
                              sx={{ px: 0.5, py: 0.5 }}
                            />
                            <Chip
                              label={`Interior Color: ${wvData?.int_color}`}
                              sx={{ px: 0.5, py: 0.5 }}
                            />
                            <Chip
                              label={`Body: ${wvData?.body}`}
                              sx={{ px: 0.5, py: 0.5 }}
                            />
                          </Stack>
                          {!!wvData?.options &&
                            Array.isArray(wvData?.options) && (
                              <Stack>
                                <span style={{ fontWeight: "600" }}>
                                  Options
                                </span>
                                <Stack
                                  direction={"row"}
                                  gap={1}
                                  flexWrap="wrap"
                                >
                                  {wvData?.options?.map((o) => (
                                    <span
                                      style={{
                                        fontSize: 13,
                                        fontWeight: "500",
                                      }}
                                      key={o?.name}
                                    >
                                      {o?.name},
                                    </span>
                                  ))}
                                </Stack>
                              </Stack>
                            )}
                        </Stack>
                      )
                    )}
                  </Stack>
                </>
              )}
            </>
          )}
        </Box>
      </Container>
      <PopUpDialog
        open={open}
        setOpen={setOpen}
        value={lead?.retailNote}
        onChange={handleChange}
      />
      <PopDownUpDialog
        open={isOpen}
        setOpen={setIsOpen}
        setLead={setLead}
        lead={lead}
      />
      {/*
      <OptInStatusModal
        loading={isLoading}
        onConfirm={createNewLead}
        open={optin}
        setOpen={setOptin}
      />
       */}
      <LoadingBackdrop open={vinLoading || show} />
      <FeedBackBox loading={isLoading} show={show} err={Err || vinErr} />
    </>
  )
}
