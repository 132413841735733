import axios from "axios"
import React from "react"
import { URL } from "../../config/apis"
import useData from "../../context/Data"
import { Button, MenuItem, Stack, TextField, Typography } from "@mui/material"
import SelectMenu from "../createPage/SelectMenu"
import { usStatesList } from "../../constants/constants"

export default function EditMarket({ market: _market, setOpen }) {
  const { setMarkets, loading, setLoading, setShow, setErr, teams } = useData()
  const [market, setMarket] = React.useState(_market)

  const updateMarket = async () => {
    if (market?.name?.trim() === "" || market?.state == null) return
    setLoading(true)
    try {
      const res = await axios.put(
        `${URL}/markets/update/${_market?._id}`,
        market,
        {
          headers: {
            "Content-Type": "application/json",
          },
          withCredentials: true,
        }
      )
      setLoading(false)
      if (res.data.ok) {
        setMarkets((prev) => {
          return prev.map((m) => {
            if (m?._id === res.data.market?._id) {
              return res.data.market
            } else {
              return m
            }
          })
        })
        setShow(true)
        setOpen(false)
        setTimeout(() => {
          setShow(false)
        }, 2000)
      } else {
        setErr(res.data.message)
        setTimeout(() => {
          setErr(null)
        }, 3000)
      }
    } catch (error) {
      setLoading(false)
      setErr(error.response?.data?.message || error?.message)
      setTimeout(() => {
        setErr(null)
      }, 3000)
    }
  }
  return (
    <Stack px={3} py={2} spacing={3}>
      <Typography variant="h6" pb={1}>
        Update Market
      </Typography>
      <TextField
        size="small"
        id="outlined-basic"
        label="Market Name"
        variant="outlined"
        value={market?.name}
        onChange={(e) =>
          setMarket((prev) => ({ ...prev, name: e.target.value }))
        }
        fullWidth
        //  sx={{ maxWidth: "350px" }}
      />
      <Stack direction={"row"} alignItems={"center"} spacing={2}>
        <SelectMenu
          sx={{ minWidth: 200 }}
          label="State"
          options={usStatesList}
          value={market?.state}
          handleChange={(e) => setMarket((prev) => ({ ...prev, state: e }))}
          name={"state"}
        >
          {usStatesList &&
            usStatesList?.map((o, i) => (
              <MenuItem key={o.code + i} value={o.code}>
                {o.name}
              </MenuItem>
            ))}
        </SelectMenu>
        <SelectMenu
          sx={{ minWidth: 250 }}
          label="Team"
          options={teams}
          value={market?.team}
          handleChange={(e) => setMarket((prev) => ({ ...prev, team: e }))}
          name={"team"}
        >
          {teams &&
            teams?.map((o, i) => (
              <MenuItem key={o._id + i} value={o._id}>
                {o.name}
              </MenuItem>
            ))}
        </SelectMenu>
      </Stack>
      <Stack
        direction={"row"}
        justifyContent={"end"}
        alignItems={"center"}
        spacing={2}
      >
        <Button
          onClick={() => setOpen(false)}
          variant="contained"
          disabled={loading}
          color="inherit"
        >
          Close
        </Button>
        <Button
          onClick={updateMarket}
          variant="contained"
          color={"success"}
          disabled={
            loading || market?.name?.trim() === "" || market?.state == null
          }
        >
          {loading ? "Updating..." : "Update"}
        </Button>
      </Stack>
    </Stack>
  )
}
