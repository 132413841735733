import * as React from "react"
import Divider from "@mui/material/Divider"
import Paper from "@mui/material/Paper"
import Grid from "@mui/material/Grid"
import Typography from "@mui/material/Typography"

export default function PaperWrrapper({ children, title = "CarFax" }) {
  return (
    <Paper
      sx={{
        flexGrow: 1,
        padding: "1rem",
        flexDirection: "column",
        display: "flex",
        gap: 1,
      }}
    >
      <Typography variant="h6">{title}</Typography>
      <Divider />
      <Grid container sx={{ paddingY: "8px" }} spacing={2}>
        {children}
      </Grid>
    </Paper>
  )
}
