import * as React from "react"
import { styled, useTheme } from "@mui/material/styles"
import Drawer from "@mui/material/Drawer"
import Typography from "@mui/material/Typography"
import { Link, NavLink } from "react-router-dom"
import List from "@mui/material/List"
import Divider from "@mui/material/Divider"
import IconButton from "@mui/material/IconButton"
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft"
import ChevronRightIcon from "@mui/icons-material/ChevronRight"
import ListItem from "@mui/material/ListItem"
import ListItemButton from "@mui/material/ListItemButton"
import ListItemIcon from "@mui/material/ListItemIcon"
import ListItemText from "@mui/material/ListItemText"
import InboxIcon from "@mui/icons-material/MoveToInbox"
import MailIcon from "@mui/icons-material/Mail"
import { adminMenu, botMenu, navMenu } from "../../helppers/constants"
import { appName } from "../../constants/constants"
import { Chip, Stack } from "@mui/material"
import { isAllowed } from "../../helppers/checkRoles"
import useAuth from "../../context/Auth"

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: "flex-end",
}))

function SideBar({ drawerWidth, open, handleDrawerClose }) {
  const theme = useTheme()
  const { user } = useAuth()

  return (
    <Drawer
      sx={{
        width: drawerWidth,
        flexShrink: 0,
        "& .MuiDrawer-paper": {
          width: drawerWidth,
          boxSizing: "border-box",
        },
      }}
      variant="persistent"
      anchor="left"
      open={open}
    >
      <DrawerHeader>
        <Stack position={"relative"} flex={1}>
          <img
            src="/dark-logo.png"
            alt="DealerBids"
            style={{ height: 60, width: "auto", objectFit: "contain" }}
          />
          {/* <img src="/usa.svg" width={30} /> */}
          {/* <Chip
            label="Source"
            size="small"
            color="secondary"
            sx={{
              borderRadius: 1,
              px: 0,
              fontWeight: "900",
              position: "absolute",
              top: 25,
              left: 10,
            }}
          /> */}
        </Stack>

        <IconButton onClick={handleDrawerClose}>
          {theme.direction === "ltr" ? (
            <ChevronLeftIcon />
          ) : (
            <ChevronRightIcon />
          )}
        </IconButton>
      </DrawerHeader>
      <Divider />
      <List>
        {navMenu.map((item, index) => (
          <ListItem key={item.href + index} disablePadding>
            <NavLink
              to={item.href}
              style={({ isActive }) => ({
                color: isActive ? "black" : "inherit",
                textDecoration: "none",
                width: "100%",
              })}
            >
              {({ isActive }) => (
                <ListItemButton
                  sx={{ backgroundColor: isActive ? "#00000026" : "inherit" }}
                >
                  <ListItemIcon sx={{ color: isActive ? "black" : "inherit" }}>
                    {item.icon}
                  </ListItemIcon>
                  <ListItemText primary={item.lable} />
                </ListItemButton>
              )}
            </NavLink>
          </ListItem>
        ))}
      </List>
      <Divider />
      <List>
        {botMenu.map((item, index) => (
          <ListItem key={item.href + index} disablePadding>
            <NavLink
              to={item.href}
              style={({ isActive }) => ({
                color: isActive ? "black" : "inherit",
                textDecoration: "none",
                width: "100%",
              })}
            >
              {({ isActive }) => (
                <ListItemButton
                  sx={{ backgroundColor: isActive ? "#00000026" : "inherit" }}
                >
                  <ListItemIcon sx={{ color: isActive ? "black" : "inherit" }}>
                    {item.icon}
                  </ListItemIcon>
                  <ListItemText primary={item.lable} />
                </ListItemButton>
              )}
            </NavLink>
          </ListItem>
        ))}
      </List>

      {/* <Divider /> */}
      <List>
        {adminMenu.map((item, index) => {
          if (!isAllowed(user?.role, item?.roles || [])) {
            return null
          }
          return (
            <ListItem key={item.href + index} disablePadding>
              <NavLink
                to={item.href}
                style={({ isActive }) => ({
                  color: isActive ? "black" : "inherit",
                  textDecoration: "none",
                  width: "100%",
                })}
              >
                {({ isActive }) => (
                  <ListItemButton
                    sx={{
                      backgroundColor: isActive ? "#00000026" : "inherit",
                    }}
                  >
                    <ListItemIcon
                      sx={{ color: isActive ? "black" : "inherit" }}
                    >
                      {item.icon}
                    </ListItemIcon>
                    <ListItemText primary={item.lable} />
                  </ListItemButton>
                )}
              </NavLink>
            </ListItem>
          )
        })}
      </List>
    </Drawer>
  )
}

export default SideBar
