import Box from "@mui/material/Box"
import Container from "@mui/material/Container"
import TextField from "@mui/material/TextField"
import MenuItem from "@mui/material/MenuItem"
import Divider from "@mui/material/Divider"
import Button from "@mui/material/Button"
import ButtonGroup from "@mui/material/ButtonGroup"
import Switch from "@mui/material/Switch"
import FormControlLabel from "@mui/material/FormControlLabel"
import React, { useEffect } from "react"
import PricesInput from "../components/createPage/PricesInput"
import SelectMenu from "../components/createPage/SelectMenu"
import CarFaxBox from "../components/global/CarFaxBox"
import AutoCheckBox from "../components/global/AutoCheckBox"
import PopUpDialog from "../components/modals/PopUpDialog"
import { LEAD_INITIAL_STATE } from "../constants/initialStates"
import GirdBoxWrraper from "../components/createPage/GirdBoxWrraper"
import { Alert, Grid, Snackbar, Typography } from "@mui/material"
import { cgUrl, imgUrl, usStatesList } from "../constants/constants"
import IconButton from "@mui/material/IconButton"
import PhotoCamera from "@mui/icons-material/PhotoCamera"
import Stack from "@mui/material/Stack"
import PopDownUpDialog from "../components/modals/PopDownUpDialog"
import {
  cargurusDecoder,
  getAutoCheck,
  getAutoCheckSimple,
  getCarfaxData,
  getCarfaxDatasimple,
  SimpleVinDecoder,
  vinDecoder,
} from "../helppers/apiCalls/decoders"
import LoadingBackdrop from "../components/global/LoadingBackdrop"
import CfxSkeleton from "../components/global/CfxSkeleton"
import useData from "../context/Data"
import CheckCircleIcon from "@mui/icons-material/CheckCircle"
import CancelIcon from "@mui/icons-material/Cancel"
import axios from "axios"
import { URL } from "../config/apis"
import { useNavigate, useParams } from "react-router-dom"
import TextEditor from "../components/text-editor/TextEditor"
import CarFaxEditBox from "../components/createPage/CarFaxEditBox"
import { getCgUrl, openSmallPage } from "../helppers/functions"

export default function EditLeadPage() {
  const { id } = useParams()
  const navigate = useNavigate()

  const { markets } = useData()
  const [open, setOpen] = React.useState(false)
  const [isOpen, setIsOpen] = React.useState(false)
  const [show, setShow] = React.useState(false)
  const [loading, setLoading] = React.useState(true)
  const [isLoading, setIsLoading] = React.useState(false)
  const [lead, setLead] = React.useState(LEAD_INITIAL_STATE)
  const [data, setData] = React.useState(null)
  const [cfx, setCfx] = React.useState(null)
  const [acheck, setAcheck] = React.useState(null)
  const [cfxErr, setCfxErr] = React.useState(null)
  const [acErr, setAcErr] = React.useState(null)
  const [Err, setErr] = React.useState(null)

  const handleSwitchChange = (e, checked) => {
    setLead((prev) => ({ ...prev, isRetail: checked }))
    if (checked) {
      setOpen(true)
    }
  }

  const handleSwitchKbb = (e, checked) => {
    setLead((prev) => ({ ...prev, isKbb: checked }))
  }

  const handleChange = (e) => {
    setLead((prev) => ({ ...prev, retailNote: e }))
  }
  const handleChangeSelect = (e, name) => {
    setLead((prev) => ({ ...prev, [name]: e }))
  }
  const onValueChange = (e, name) => {
    if (["transmission", "engine"].includes(name)) {
      setLead((prev) => ({
        ...prev,
        [name]: {
          short: e,
          full: e,
        },
      }))
    } else {
      setLead((prev) => ({ ...prev, [name]: e }))
    }
  }

  const getLeadById = async () => {
    setLoading(true)
    try {
      const res = await axios.get(`${URL}/leads/item/${id}?mode=edit`, {
        headers: {
          "Content-Type": "application/json",
        },
        withCredentials: true,
      })
      if (res.data) {
        // await SimpleVinDecoder(res.data?.vin, setData)
        setLead((prev) => ({ ...prev, ...res.data }))
        // await getCarfaxDatasimple(res.data?.vin, setCfx)
        // await getAutoCheckSimple(res.data?.vin, setAcheck)
      } else {
        setErr("Lead not found")
      }
      setLoading(false)
    } catch (error) {
      setLoading(false)
      setErr(error?.response?.data?.message)
    }
  }

  useEffect(() => {
    getLeadById()
  }, [])

  const EditLead = async (e) => {
    e.preventDefault()
    setErr(null)
    setShow(false)
    setIsLoading(true)
    try {
      const res = await axios.put(`${URL}/leads/item/${id}`, lead, {
        headers: {
          "Content-Type": "application/json",
        },
        withCredentials: true,
      })
      if (res.data.ok) {
        setShow(true)
        setIsLoading(false)
        setTimeout(() => {
          setShow(false)
          navigate(`/leads/item/${id}`)
        }, 1000)
      }
      setIsLoading(false)
    } catch (error) {
      setIsLoading(false)
      if (error.response) {
        setErr(error.response?.data?.message)
      } else {
        setErr(error.message)
      }
      setTimeout(() => {
        setErr(null)
      }, 3000)
    }
  }

  if (loading) {
    return <div>Loading ...</div>
  }

  return (
    <>
      <Container maxWidth="100%">
        <Box
          component="form"
          sx={{
            "& > :not(style)": { my: 1, width: "100%" },
            display: "flex",
            flexDirection: "column",
            gap: ".5rem",
          }}
          noValidate
          autoComplete="off"
          onSubmit={EditLead}
        >
          <GirdBoxWrraper>
            <Grid item xs={3}>
              <TextField
                size="small"
                id="outlined-basic"
                label="VIN"
                variant="outlined"
                fullWidth
                value={lead?.vin}
                //onChange={onVinChange}
                disabled={true}
              />
            </Grid>
            <Grid item xs={2}>
              <TextField
                size="small"
                id="outlined-basic"
                label="Mileage"
                variant="outlined"
                fullWidth
                value={lead?.mileage}
                onChange={(e) =>
                  onValueChange(parseInt(e.target.value || "0"), "mileage")
                }
              />
            </Grid>
            <Grid item xs={1}>
              <TextField
                size="small"
                id="outlined-basic"
                label="ZIP"
                variant="outlined"
                fullWidth
                inputProps={{ inputMode: "numeric", pattern: "[0-9]*" }}
                value={lead?.zip}
                onChange={(e) => onValueChange(e.target.value, "zip")}
              />
            </Grid>

            <Grid item xs={5}>
              <div style={{ display: "flex", justifyContent: "end" }}>
                <ButtonGroup
                  variant="contained"
                  aria-label="outlined button group"
                >
                  <Button disabled={isLoading} type="submit" color="success">
                    {isLoading ? "Saving..." : "Save"}
                  </Button>
                </ButtonGroup>
              </div>
            </Grid>
          </GirdBoxWrraper>
          <>
            <GirdBoxWrraper>
              <Grid item xs={1}>
                <TextField
                  size="small"
                  id="outlined-basic"
                  label="Year"
                  variant="outlined"
                  value={lead?.year}
                  inputProps={{ inputMode: "numeric", pattern: "[0-9]*" }}
                  fullWidth
                  onChange={(e) => onValueChange(e.target.value, "year")}
                />
              </Grid>
              <Grid item xs={2}>
                <TextField
                  size="small"
                  id="outlined-basic"
                  label="Make"
                  variant="outlined"
                  value={lead?.make}
                  fullWidth
                  onChange={(e) => onValueChange(e.target.value, "make")}
                />
              </Grid>
              <Grid item xs={1}>
                <TextField
                  size="small"
                  id="outlined-basic"
                  label="Model"
                  variant="outlined"
                  value={lead?.model}
                  fullWidth
                  onChange={(e) => onValueChange(e.target.value, "model")}
                />
              </Grid>
              <Grid item xs={2}>
                <TextField
                  size="small"
                  id="outlined-basic"
                  label="Trim"
                  variant="outlined"
                  value={lead?.trim}
                  fullWidth
                  onChange={(e) => onValueChange(e.target.value, "trim")}
                />
              </Grid>
              <Grid item xs={3}>
                <TextField
                  size="small"
                  id="outlined-basic"
                  label="Transmission"
                  variant="outlined"
                  sx={{ flexGrow: 1 }}
                  value={lead?.transmission?.full}
                  onChange={(e) =>
                    onValueChange(e.target.value, "transmission")
                  }
                  fullWidth
                />
              </Grid>
              <Grid item xs={3}>
                <TextField
                  size="small"
                  id="outlined-basic"
                  label="Engine"
                  variant="outlined"
                  sx={{ flexGrow: 1 }}
                  value={lead?.engine?.full}
                  fullWidth
                  onChange={(e) => onValueChange(e.target.value, "engine")}
                />
              </Grid>
            </GirdBoxWrraper>
            <Box
              component="div"
              sx={{ display: "flex", gap: "1rem", width: "100%" }}
            >
              <TextField
                size="small"
                id="outlined-basic"
                label="Vehicle Type"
                variant="outlined"
                value={lead?.type}
                onChange={(e) => onValueChange(e.target.value, "type")}
              />
              <TextField
                size="small"
                id="outlined-basic"
                label="Exterior Color"
                variant="outlined"
                value={lead?.extColor}
                onChange={(e) => onValueChange(e.target.value, "extColor")}
              />
              <TextField
                size="small"
                id="outlined-basic"
                label="Interior Color"
                variant="outlined"
                value={lead?.intColor}
                onChange={(e) => onValueChange(e.target.value, "intColor")}
              />

              <SelectMenu
                sx={{ minWidth: 150 }}
                label="Title Status"
                options={[
                  "Title In Hand",
                  "Loan on Vehicle",
                  "Lease on Vehicle",
                ]}
                value={lead?.titleStatus}
                handleChange={handleChangeSelect}
                name={"titleStatus"}
              >
                {["Title In Hand", "Loan on Vehicle", "Lease on Vehicle"]?.map(
                  (o, i) => (
                    <MenuItem key={o + i} value={o}>
                      {o}
                    </MenuItem>
                  )
                )}
              </SelectMenu>

              <TextField
                size="small"
                id="outlined-basic"
                label="FBMP Id"
                variant="outlined"
                sx={{ flexGrow: 1 }}
                value={lead?.fbmpId}
                onChange={(e) => onValueChange(e.target.value, "fbmpId")}
              />
              <TextField
                size="small"
                id="outlined-basic"
                label="Other Source"
                variant="outlined"
                sx={{ flexGrow: 1 }}
                value={lead?.otherSource}
                onChange={(e) => onValueChange(e.target.value, "otherSource")}
              />
              {/* <TextField
                size="small"
                id="outlined-basic"
                label="Accu-Trade Link"
                variant="outlined"
                sx={{ flexGrow: 1 }}
                value={lead?.accuTrade}
                onChange={(e) => onValueChange(e.target.value, "accuTrade")}
              /> */}
            </Box>
            <Box
              component="div"
              sx={{ display: "flex", gap: "1rem", width: "100%" }}
            >
              <PricesInput
                lable="MSRP"
                value={lead?.msrp}
                onValueChange={onValueChange}
                name="msrp"
              />
              <PricesInput
                lable="Listed Price"
                value={lead?.listedPrice}
                onValueChange={onValueChange}
                name="listedPrice"
              />
              <PricesInput
                lable="MMR"
                value={lead?.mmr}
                onValueChange={onValueChange}
                name="mmr"
              />
              <PricesInput
                lable="KBB PP"
                value={lead?.kbbppv}
                onValueChange={onValueChange}
                name="kbbppv"
              />
              <PricesInput
                lable="CG IMV"
                value={lead?.cgimv}
                onValueChange={onValueChange}
                name="cgimv"
                isCg
                onClick={() =>
                  openSmallPage(
                    `${getCgUrl(lead?.vin, lead?.mileage, lead?.zip)}`
                  )
                }
              />
              <PricesInput
                lable="CG Private"
                value={lead?.cgPrivate}
                onValueChange={onValueChange}
                name="cgPrivate"
                // isCg
                // onClick={() =>
                //   openSmallPage(
                //     `${getCgUrl(lead?.vin, lead?.mileage, lead?.zip)}`
                //   )
                // }
              />
              <TextField
                size="small"
                id="outlined-basic"
                label="MDS"
                variant="outlined"
                fullWidth
                value={lead?.mds}
                onChange={(e) => onValueChange(e.target.value, "mds")}
              />
              {/* <TextField
                size="small"
                id="outlined-basic"
                label="MDOM"
                variant="outlined"
                fullWidth
                value={lead?.mdom}
                onChange={(e) => onValueChange(e.target.value, "mdom")}
              /> */}
            </Box>
            <Box
              component="div"
              sx={{ display: "flex", gap: "1rem", width: "100%" }}
            >
              <TextField
                size="small"
                id="outlined-basic"
                label="Seller Name"
                variant="outlined"
                value={lead?.sellerName}
                onChange={(e) => onValueChange(e.target.value, "sellerName")}
              />
              <TextField
                size="small"
                id="outlined-basic"
                label="Cell Phone"
                variant="outlined"
                value={lead?.sellerPhone}
                onChange={(e) => onValueChange(e.target.value, "sellerPhone")}
              />
              <TextField
                size="small"
                id="outlined-basic"
                label="City"
                variant="outlined"
                value={lead?.city}
                onChange={(e) => onValueChange(e.target.value, "city")}
              />
              <SelectMenu
                sx={{ minWidth: 150 }}
                label="State"
                options={usStatesList}
                value={lead?.state}
                handleChange={handleChangeSelect}
                name={"state"}
              >
                {usStatesList &&
                  usStatesList?.map((o, i) => (
                    <MenuItem key={o.code + i} value={o.code}>
                      {o.name}
                    </MenuItem>
                  ))}
              </SelectMenu>

              <SelectMenu
                sx={{ minWidth: 150 }}
                label="Market"
                options={markets}
                value={lead?.market}
                handleChange={handleChangeSelect}
                name={"market"}
              >
                {markets?.map((o, i) => (
                  <MenuItem key={o?._id + i} value={o?._id}>
                    {o?.name}
                  </MenuItem>
                ))}
              </SelectMenu>
              <TextField
                size="small"
                id="outlined-fb_name"
                label="FB Name"
                variant="outlined"
                value={lead?.fb_name}
                onChange={(e) => onValueChange(e.target.value, "fb_name")}
              />
              {/* <FormControlLabel
                control={
                  <Switch
                    onChange={handleSwitchChange}
                    checked={lead?.isRetail}
                  />
                }
                label="Retail Customer"
              /> */}
            </Box>
            <Divider />
            {/* start images */}
            <Stack direction="row" alignItems="center" spacing={2}>
              {/* <IconButton color="primary" onClick={() => setIsOpen(true)}>
                  <PhotoCamera />
                </IconButton> */}
              <Button
                onClick={() => setIsOpen(true)}
                variant="contained"
                startIcon={<PhotoCamera />}
              >
                Manage Images
              </Button>
              <Divider orientation="vertical" flexItem />
              <Stack direction="row" alignItems="center" spacing={2}>
                <Typography>Featured Image:</Typography>
                {lead?.f_image ? (
                  <CheckCircleIcon color="success" />
                ) : (
                  <CancelIcon color="error" />
                )}
              </Stack>
              <Divider orientation="vertical" flexItem />
              <Stack direction="row" alignItems="center" spacing={2}>
                <Typography>Images:</Typography>
                <Typography>
                  {lead?.images?.length > 0
                    ? lead?.images?.length
                    : "No Images"}{" "}
                  Uploaded
                </Typography>
              </Stack>
            </Stack>
            {/* end images */}
            <Divider />
            {/* text Editor */}
            <TextEditor value={lead?.condition} setLead={setLead} />
            <Divider />
            <Box
              component="div"
              sx={{
                display: "flex",
                gap: "1rem",
                width: "100%",
                flexDirection: "column",
              }}
            >
              {/* {cfx ? (
                <CarFaxBox data={cfx} isClean={lead?.isCleanCfx} />
              ) : cfxErr ? (
                <div>{cfxErr}</div>
              ) : null} */}

              <CarFaxEditBox setLead={setLead} lead={lead} />

              {/* {acheck ? (
                <AutoCheckBox data={acheck} />
              ) : acErr ? (
                <div>{acErr}</div>
              ) : null} */}
            </Box>
          </>
        </Box>
      </Container>
      <PopUpDialog
        open={open}
        setOpen={setOpen}
        value={lead?.retailNote}
        onChange={handleChange}
      />
      <PopDownUpDialog
        open={isOpen}
        setOpen={setIsOpen}
        setLead={setLead}
        lead={lead}
      />
      <LoadingBackdrop open={loading || show} />
      <>
        <Snackbar
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
          open={isLoading}
          message="Saving ..."
        />
        <Snackbar
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          autoHideDuration={3000}
          open={show}
          sx={{ minWidth: "200px" }}
        >
          <Alert
            color="success"
            severity="success"
            variant="filled"
            sx={{ width: "100%" }}
          >
            Updated Successfully
          </Alert>
        </Snackbar>
        <Snackbar
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          autoHideDuration={3000}
          open={Err !== null}
          sx={{ minWidth: "200px", maxWidth: "400px" }}
          severity="error"
        >
          <Alert
            color="error"
            severity="error"
            variant="filled"
            sx={{ width: "100%" }}
          >
            {Err}
          </Alert>
        </Snackbar>
      </>
    </>
  )
}
