import { FormControlLabel, Grid, Switch } from "@mui/material"
import React from "react"
import PaperWrrapper from "../global/PaperWrrapper"

export default function CarFaxEditBox({ setLead, lead }) {
  const onValueChange = (name, value) => {
    setLead((prev) => ({
      ...prev,
      carfax_data: { ...prev?.carfax_data, [name]: value },
    }))
  }

  return (
    <PaperWrrapper title="CarFax">
      <Grid item sm={2}>
        <FormControlLabel
          control={
            <Switch
              name="isCleanCfx"
              onChange={(e, checked) =>
                setLead((prev) => ({ ...prev, isCleanCfx: checked }))
              }
              checked={lead?.isCleanCfx}
            />
          }
          label="Clean CarFax"
        />
      </Grid>

      <Grid item sm={2}>
        <FormControlLabel
          control={
            <Switch
              name="accedent"
              onChange={(e, checked) => onValueChange("accedent", checked)}
              checked={lead?.carfax_data?.accedent}
            />
          }
          label="Accident Reported"
        />
      </Grid>
      <Grid item sm={2}>
        <FormControlLabel
          control={
            <Switch
              name="minor"
              onChange={(e, checked) => onValueChange("minor", checked)}
              checked={lead?.carfax_data?.minor}
            />
          }
          label="Minor"
        />
      </Grid>
      <Grid item sm={2}>
        <FormControlLabel
          control={
            <Switch
              name="moderate"
              onChange={(e, checked) => onValueChange("moderate", checked)}
              checked={lead?.carfax_data?.moderate}
            />
          }
          label="Moderate"
        />
      </Grid>
      <Grid item sm={2}>
        <FormControlLabel
          control={
            <Switch
              name="severe"
              onChange={(e, checked) => onValueChange("severe", checked)}
              checked={lead?.carfax_data?.severe}
            />
          }
          label="Severe"
        />
      </Grid>
      <Grid item sm={2}>
        <FormControlLabel
          control={
            <Switch
              name="branded"
              onChange={(e, checked) => onValueChange("branded", checked)}
              checked={lead?.carfax_data?.branded}
            />
          }
          label="Branded Title"
        />
      </Grid>
    </PaperWrrapper>
  )
}
