import React from "react"
import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"

const DateRangeBox = ({ setDateRange, startDate, endDate, setPage }) => {
  return (
    <DatePicker
      selectsRange={true}
      startDate={startDate}
      endDate={endDate}
      onChange={(update) => {
        setDateRange(update)
        setPage(1)
      }}
      isClearable={true}
    />
  )
}
export default DateRangeBox
