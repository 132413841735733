import MainLayout from "./components/layouts/MainLayout"
import { Routes, Route, Navigate, useLocation } from "react-router-dom"
import { CreateNewListing, DealershipsPage, Home, MarketsPage } from "./pages"
import SingleLeadpage from "./pages/SingleLeadpage"
import LoginPage from "./pages/LoginPage"
import useAuth from "./context/Auth"
import { DataContextProvider } from "./context/Data"
import EditLeadPage from "./pages/EditLeadPage"
import CreateNewDealershipPage from "./pages/CreateNewDealershipPage"
import DealershipPage from "./pages/DealershipPage"
import AgentsPage from "./pages/AgentsPage"
import AgentPage from "./pages/AgentPage"
import AcceptedPage from "./pages/AcceptedPage"
import CountersPage from "./pages/CountersPage"
import DoneDealsPage from "./pages/DoneDealsPage"
import CalendarPage from "./pages/CalendarPage"
import ReportsPage from "./pages/ReportsPage"
import Dashboard from "./pages/Dashboard"
import { SocketContextProvider } from "./context/Socket"
import ProfilePage from "./pages/ProfilePage"
import ProfileInfoPage from "./pages/profile/ProfileInfoPage"
import PasswordEdit from "./pages/profile/PasswordEdit"
import LostPage from "./pages/LostPage"
import TrashedPage from "./pages/TrashedPage"
import { isAllowed } from "./helppers/checkRoles"
import ExportLeads from "./pages/ExportLeads"

import "./App.css"
import SettingPage from "./pages/SettingPage"
import DealersReportsPage from "./pages/DealersReportsPage"
import EditDealershipPage from "./pages/EditDealershipPage"

function App() {
  const { user } = useAuth()

  if (!user) {
    return (
      <Routes>
        <Route
          path="/*"
          element={!user ? <LoginPage /> : <Navigate to="/" replace={true} />}
        />
        <Route
          path="/login"
          element={!user ? <LoginPage /> : <Navigate to="/" replace={true} />}
        />
      </Routes>
    )
  }

  return (
    <MainLayout>
      <DataContextProvider>
        <SocketContextProvider>
          <Routes>
            <Route path="/">
              <Route index element={<Dashboard />} />
              <Route path="/new" element={<CreateNewListing />} />
              <Route
                path="/settings"
                element={
                  isAllowed(user?.role, ["super-admin"]) ? (
                    <SettingPage />
                  ) : (
                    <Navigate to="/" replace={true} />
                  )
                }
              />

              <Route path="/leads" element={<Home />} />
              <Route path="/accepted" element={<AcceptedPage />} />
              <Route path="/counters" element={<CountersPage />} />
              <Route path="/deals" element={<DoneDealsPage />} />
              <Route path="/lost" element={<LostPage />} />
              <Route path="/trash" element={<TrashedPage />} />

              <Route path="/leads/item/:id" element={<SingleLeadpage />} />
              <Route path="/leads/edit/:id" element={<EditLeadPage />} />

              <Route path="/users" element={<AgentsPage />} />
              <Route path="/users/profile/:id" element={<AgentPage />} />
              <Route path="/profile" element={<ProfilePage />}>
                <Route index element={<ProfileInfoPage />} />
                <Route path="password" element={<PasswordEdit />} />
              </Route>

              <Route path="/dealerships" element={<DealershipsPage />} />
              {/* <Route
                path="/export"
                element={
                  isAllowed(user?.role, ["super-admin"]) ? (
                    <ExportLeads />
                  ) : (
                    <Navigate to="/" replace={true} />
                  )
                }
              /> */}
              <Route
                path="/dealerships/profile/:id"
                element={<DealershipPage />}
              />
              <Route
                path="/dealerships/profile/:id/edit"
                element={<EditDealershipPage />}
              />
              <Route
                path="/dealerships/new"
                element={
                  isAllowed(user?.role, ["super-admin"]) ? (
                    <CreateNewDealershipPage />
                  ) : (
                    <Navigate to="/dealerships" replace={true} />
                  )
                }
              />

              <Route
                path="/markets"
                element={
                  isAllowed(user?.role, ["super-admin"]) ? (
                    <MarketsPage />
                  ) : (
                    <Navigate to="/" replace={true} />
                  )
                }
              />

              <Route path="/appointments" element={<CalendarPage />} />
              <Route path="/db-reports" element={<ReportsPage />} />
              <Route path="/reports" element={<DealersReportsPage />} />
            </Route>
            <Route
              path="/login"
              element={
                user ? <Navigate to="/" replace={true} /> : <LoginPage />
              }
            />
          </Routes>
        </SocketContextProvider>
      </DataContextProvider>
    </MainLayout>
  )
}

export default App
