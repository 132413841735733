import { Paper } from "@mui/material"

export default function PaperContainer({ children, minHeight = "500px" }) {
  return (
    <Paper
      variant="elevation"
      elevation={1}
      sx={{ paddingY: "10px", minHeight }}
    >
      {children}
    </Paper>
  )
}
