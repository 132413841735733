import { Stack, Tooltip, Typography } from "@mui/material"
import React from "react"
import FacebookIcon from "@mui/icons-material/Facebook"
import { formatPrice } from "../../helppers/formatters"
import IosShareIcon from "@mui/icons-material/IosShare"
// import { AiFillCheckSquare, AiFillCloseSquare } from "react-icons/ai"
// import OptInStatusModal from "../modals/OptInStatusModal"
// import { updateLead } from "../../helppers/apiCalls/update-lead"
// import useData from "../../context/Data"

export default function TopTileRightSide({ lead, setLead, setActivity }) {
  // const { loading, setLoading, setShow, setErr } = useData()
  // const [open, setOpen] = useState(false)

  // const updateOptin = async (optin) => {
  //   if (
  //     !optin ||
  //     typeof optin === "undefined" ||
  //     !["YES", "NO"].includes(optin)
  //   ) {
  //     setErr("Please select a valid Opt-In Status ('YES' or 'NO')")
  //     return setTimeout(() => {
  //       setErr(null)
  //     }, 2000)
  //   }
  //   setErr(null)
  //   setShow(false)
  //   setLoading(true)
  //   const payload = { optin }
  //   const { ok, error, activity } = await updateLead(payload, lead?._id)
  //   if (ok) {
  //     setShow(true)
  //     setLoading(false)
  //     setLead((prev) => ({ ...prev, optin }))
  //     if (activity) {
  //       setActivity((prev) => [activity, ...prev])
  //     }
  //     setOpen(false)
  //     setTimeout(() => {
  //       setShow(false)
  //     }, 2000)
  //   }
  //   if (error) {
  //     setErr(error)
  //     setLoading(false)
  //     setTimeout(() => {
  //       setErr(null)
  //     }, 2000)
  //   }
  // }

  return (
    <>
      <Stack
        flexShrink={0}
        justifyContent={"end"}
        direction="row"
        alignItems={"center"}
        spacing={2}
      >
        <Stack direction="row" alignItems={"center"} spacing={1}>
          {/* <Tooltip placement="top" title="Opt-In Status">
            <IconButton
              onClick={() => setOpen(true)}
              size="small"
              aria-label="Opt-In-Status"
            >
              {lead?.optin === "YES" ? (
                <AiFillCheckSquare style={{ fontSize: 32, color: "#43a047" }} />
              ) : (
                <AiFillCloseSquare style={{ fontSize: 35, color: "#f44336" }} />
              )}
            </IconButton>
          </Tooltip> */}
          <Tooltip placement="top" title="Open Closers">
            <a
              href={`https://closers.dealerbids.io/lead/${lead?._id}/view`}
              target="_blank"
              style={{
                position: "relative",
                width: 35,
                height: 42,
                p: 2,
                alignItems: "center",
                justifyContent: "center",
                display: "flex",
              }}
            >
              <img
                src="/closers.png"
                style={{ height: "auto", width: "80%" }}
                alt="Open Closers"
              />
            </a>
          </Tooltip>
          <a
            href={
              lead?.fbmpId
                ? `https://www.facebook.com/marketplace/item/${lead?.fbmpId}`
                : lead?.otherSource
                ? lead?.otherSource
                : "#"
            }
            target="_blank"
            style={{
              alignItems: "center",
              justifyContent: "center",
              display: "flex",
            }}
          >
            {lead?.fbmpId ? (
              <FacebookIcon style={{ color: "#116ecb", fontSize: 35 }} />
            ) : (
              <IosShareIcon style={{ fontSize: 35 }} />
            )}
          </a>
        </Stack>
        <Stack alignItems={"end"}>
          <Typography variant="h6" fontWeight={600}>
            {formatPrice(lead?.listedPrice)}
          </Typography>
          <Typography variant="body2">Listed Price:</Typography>
        </Stack>
      </Stack>
      {/* <OptInStatusModal
        loading={loading}
        onConfirm={updateOptin}
        open={open}
        setOpen={setOpen}
        close
      /> */}
    </>
  )
}
