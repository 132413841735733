import { Grid, Stack } from "@mui/material"
import React from "react"
import DataLine from "./DataLine"
import { formatPrice } from "../../helppers/formatters"

export default function LeadInfo({ lead }) {
  return (
    <Stack
      sx={{ paddingX: "20px", paddingY: "10px" }}
      direction={"column"}
      // spacing={2}
    >
      <Grid container spacing={2} sx={{ paddingTop: "10px" }}>
        <DataLine title="Seller Name" value={lead?.sellerName || "N/A"} />
        <DataLine title="Seller Phone" value={lead?.sellerPhone || "N/A"} />
        <DataLine
          title="Location"
          value={`${lead?.city || "N/A"} / ${lead?.state || "N/A"}`}
        />
        <DataLine title="Title Status" value={lead?.titleStatus || "N/A"} />
        <DataLine title="Market" value={lead?.market?.name || "N/A"} />
        <DataLine title="Vehicle Type" value={lead?.type || "N/A"} />
      </Grid>
      <br />
      <Grid container spacing={2} sx={{ paddingBottom: "10px" }}>
        <DataLine title="Interior Color" value={lead?.intColor || "N/A"} />
        <DataLine title="Exterior Color" value={lead?.extColor || "N/A"} />
        <DataLine title="MSRP" value={formatPrice(lead?.msrp)} />
        <DataLine title="MMR" value={formatPrice(lead?.mmr)} />
        <DataLine title="KBB PP" value={formatPrice(lead?.kbbppv)} />
        <DataLine title="CG IMV" value={formatPrice(lead?.cgimv)} />
        <DataLine title="CG Private" value={formatPrice(lead?.cgPrivate)} />
        <DataLine title="MDS" value={lead?.mds || "N/A"} />
        {/* <DataLine title="MDOM" value={lead?.mdom || "N/A"} /> */}
      </Grid>
    </Stack>
  )
}
