import * as React from "react"
import Table from "@mui/material/Table"
import TableBody from "@mui/material/TableBody"
import TableCell from "@mui/material/TableCell"
import TableContainer from "@mui/material/TableContainer"
import TableHead from "@mui/material/TableHead"
import TableRow from "@mui/material/TableRow"
import Paper from "@mui/material/Paper"
import { formatDate, formatPrice } from "../../helppers/formatters"
import { Link } from "react-router-dom"

function Row({ deal }) {
  return (
    <React.Fragment>
      <TableRow sx={{ "& > *": { borderBottom: "unset" } }}>
        <TableCell component="th" scope="row">
          <Link
            style={{
              color: "inherit",
              fontWeight: 600,
              textDecoration: "none",
            }}
            to={`/leads/item/${deal?._id}`}
          >
            {deal?.title}
          </Link>
        </TableCell>
        <TableCell align="left">{deal.vin}</TableCell>
        <TableCell align="left">{deal.market?.name}</TableCell>
        <TableCell align="left">{deal.winner?.dname}</TableCell>
        <TableCell align="center">{formatDate(deal.purchaseAt)}</TableCell>
        <TableCell align="center">{formatPrice(deal.listedPrice)}</TableCell>
        <TableCell align="center">{formatPrice(deal.purchasePrice)}</TableCell>
        <TableCell align="center">
          <span
            style={{
              color:
                deal.listedPrice - deal.purchasePrice > 0
                  ? "green"
                  : deal.listedPrice - deal.purchasePrice > 0
                  ? "red"
                  : "inherit",
              fontWeight: "bold",
            }}
          >
            {formatPrice(deal.listedPrice - deal.purchasePrice, true)}
          </span>
        </TableCell>
      </TableRow>
    </React.Fragment>
  )
}

export default function DealsTable({ deals }) {
  return (
    <TableContainer component={Paper}>
      <Table aria-label="collapsible table">
        <TableHead>
          <TableRow sx={{ fontWeight: 500 }}>
            <TableCell>Title</TableCell>
            <TableCell align="left">VIN</TableCell>
            <TableCell align="left">Market</TableCell>
            <TableCell align="left">Dealership</TableCell>
            <TableCell align="center">Date</TableCell>
            <TableCell align="center">Listed Price</TableCell>
            <TableCell align="center">Purchased Price</TableCell>
            <TableCell align="center">Delta</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {deals && deals?.map((deal) => <Row key={deal?._id} deal={deal} />)}
        </TableBody>
      </Table>
    </TableContainer>
  )
}
