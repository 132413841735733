import * as React from "react"
import Button from "@mui/material/Button"
import TextField from "@mui/material/TextField"
import Dialog from "@mui/material/Dialog"
import DialogActions from "@mui/material/DialogActions"
import DialogContent from "@mui/material/DialogContent"
import DialogTitle from "@mui/material/DialogTitle"
import { Grid } from "@mui/material"
import { URL } from "../../config/apis"
import axios from "axios"
import Switch from "@mui/material/Switch"
import FormGroup from "@mui/material/FormGroup"
import FormControlLabel from "@mui/material/FormControlLabel"
import useData from "../../context/Data"
import OutlinedInput from "@mui/material/OutlinedInput"
import InputLabel from "@mui/material/InputLabel"
import MenuItem from "@mui/material/MenuItem"
import FormControl from "@mui/material/FormControl"
import ListItemText from "@mui/material/ListItemText"
import Select from "@mui/material/Select"
import Checkbox from "@mui/material/Checkbox"
import { isAllowed } from "../../helppers/checkRoles"
import useAuth from "../../context/Auth"
import SelectMenu from "../createPage/SelectMenu"

const ITEM_HEIGHT = 48
const ITEM_PADDING_TOP = 8
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
}

const INITIAL = {
  email: "",
  f_name: "",
  l_name: "",
  isOwner: false,
  slackId: "",
  role: [],
}

export default function PopUpDialogCreateAgent({
  open,
  setOpen,
  loading,
  setLoading,
  setErr,
  setShow,
  setState,
}) {
  const { user } = useAuth()
  const { setAgents, teams } = useData()
  const [agent, setAgent] = React.useState(INITIAL)

  const handleClose = (e, reason) => {
    if (reason !== "backdropClick") {
      setOpen(false)
    }
  }

  const handleChange = (value, name) => {
    setAgent((prev) => ({ ...prev, [name]: value }))
  }

  const handleBackdropClick = (event) => {
    event.stopPropagation()
    return false
  }

  const submitDeal = async () => {
    if (!isAllowed(user?.role, ["super-admin", "admin", "manager"])) return
    if (!agent?.f_name || !agent?.l_name) {
      setErr("Name is required")
      return setTimeout(() => {
        setErr(null)
      }, 3000)
    }
    if (!agent?.email) {
      setErr("Please Add email")
      return setTimeout(() => {
        setErr(null)
      }, 3000)
    }

    setErr(null)
    setLoading(true)
    setShow(false)
    try {
      const res = await axios.post(`${URL}/auth/new`, agent, {
        headers: {
          "Content-Type": "application/json",
        },
        withCredentials: true,
      })
      if (res.data.ok) {
        setAgents((prev) => [...prev, res.data.user])
        setState((prev) => [...prev, res.data.user])
      }
      setShow(true)
      setLoading(false)
      setAgent(INITIAL)
      setOpen(false)
      setTimeout(() => {
        setShow(false)
      }, 3000)
    } catch (error) {
      setLoading(false)
      setErr(error?.response?.data?.message)
      setTimeout(() => {
        setErr(null)
      }, 3000)
    }
  }

  const handleSelectChange = (event) => {
    const {
      target: { value },
    } = event
    setAgent((prev) => ({
      ...prev,
      role: typeof value === "string" ? value.split(",") : value,
    }))
  }

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      onBackdropClick={handleBackdropClick}
      disableEscapeKeyDown
      maxWidth="sm"
    >
      <DialogTitle>Create New User</DialogTitle>

      <DialogContent sx={{ minWidth: 400 }}>
        <Grid container sx={{ py: 2 }} spacing={2} columns={2}>
          <Grid item sm={1}>
            <TextField
              size="small"
              id="outlined-basic"
              label="First Name"
              variant="outlined"
              fullWidth
              value={agent?.f_name}
              onChange={(e) => handleChange(e.target.value, "f_name")}
            />
          </Grid>
          <Grid item sm={1}>
            <TextField
              size="small"
              id="outlined-basic"
              label="Last Name"
              variant="outlined"
              fullWidth
              value={agent?.l_name}
              onChange={(e) => handleChange(e.target.value, "l_name")}
            />
          </Grid>
          <Grid item sm={2}>
            <TextField
              size="small"
              id="outlined-basic"
              label="Email"
              variant="outlined"
              fullWidth
              value={agent?.email}
              onChange={(e) => handleChange(e.target.value, "email")}
            />
          </Grid>
          {isAllowed(user?.role, ["super-admin"]) && (
            <Grid item sm={1}>
              <SelectMenu
                handleChange={(v) => handleChange(v, "team")}
                label={"Team"}
                name={"team"}
                value={agent.team}
                options={teams}
                sx={{ minWidth: 150 }}
              >
                {teams &&
                  teams?.map((team) => (
                    <MenuItem key={team?._id} value={team?._id}>
                      {team?.name}
                    </MenuItem>
                  ))}
              </SelectMenu>
            </Grid>
          )}
          <Grid item sm={1}>
            <TextField
              size="small"
              id="outlined-basic"
              label="Slack Id"
              variant="outlined"
              fullWidth
              value={agent?.slackId}
              onChange={(e) => handleChange(e.target.value, "slackId")}
            />
          </Grid>
          <Grid item sm={1}>
            <TextField
              size="small"
              id="outlined-basic"
              label="Twilio Phone"
              variant="outlined"
              fullWidth
              value={agent?.twilioPhone}
              onChange={(e) => handleChange(e.target.value, "twilioPhone")}
            />
          </Grid>
          <Grid item sm={2}>
            <FormControl size="small" sx={{ minWidth: 250, width: "100%" }}>
              <InputLabel id="demo-multiple-checkbox-label">Roles</InputLabel>
              <Select
                labelId="demo-multiple-checkbox-label"
                id="demo-multiple-checkbox"
                multiple
                value={agent?.role}
                onChange={handleSelectChange}
                input={<OutlinedInput label="Roles" />}
                renderValue={(selected) => selected.join(", ")}
                MenuProps={MenuProps}
              >
                {[
                  "user",
                  "agent",
                  "manager",
                  "closer",
                  "admin",
                  "super-admin",
                ].map((name) => (
                  <MenuItem key={name} value={name}>
                    <Checkbox checked={agent?.role?.indexOf(name) > -1} />
                    <ListItemText primary={name} />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions sx={{ py: 2 }}>
        <Button disabled={loading} onClick={handleClose}>
          Cancel
        </Button>
        <Button disabled={loading} variant="contained" onClick={submitDeal}>
          {loading ? "Creating..." : "Create"}
        </Button>
      </DialogActions>
    </Dialog>
  )
}
