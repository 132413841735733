import {
  Box,
  Button,
  Container,
  Grid,
  MenuItem,
  Paper,
  Stack,
  Typography,
} from "@mui/material"
import axios from "axios"
import React, { useEffect } from "react"
import { useState } from "react"
import { Link, useNavigate } from "react-router-dom"
import SelectMenu from "../components/createPage/SelectMenu"
import DealershipCard from "../components/dealership/CardBox"
import PopUpDialogCreateOrg from "../components/modals/PopUpDialogCreateOrg"
import { URL } from "../config/apis"
import useData from "../context/Data"
import useAuth from "../context/Auth"
import { isAllowed } from "../helppers/checkRoles"

export default function DealershipsPage() {
  const { user } = useAuth()
  const { orgs, setOrgs } = useData()
  const navigate = useNavigate()
  const cash = JSON.parse(sessionStorage.getItem("dPage"))

  const [dealerships, setDealerships] = useState([])
  const [selected, setSelected] = useState(
    cash?.selected || "646bb68d8a96b766adf29a18"
  )
  const [fetching, setFetching] = useState(false)
  const [loading, setLoading] = useState(true)
  const [err, setErr] = useState(null)

  const [isLoading, setIsLoading] = useState(false)
  const [show, setShow] = useState(false)
  const [open, setOpen] = useState(false)
  const [error, setError] = useState(null)

  const getDealerships = async () => {
    setErr(null)
    setFetching(true)
    try {
      const res = await axios.get(`${URL}/dealerships?group=${selected}`, {
        headers: {
          "Content-Type": "application/json",
        },
        withCredentials: true,
      })
      setDealerships(res.data)
      setLoading(false)
      setFetching(false)
    } catch (error) {
      setLoading(false)
      setFetching(false)
      setErr(error?.response?.data?.message || error.message)
    }
  }

  useEffect(() => {
    getDealerships()

    sessionStorage.setItem("dPage", JSON.stringify({ selected }))
  }, [selected])

  if (loading) {
    return <>Loading...</>
  }

  if (err) {
    return <>{err}</>
  }

  return (
    <>
      <Container maxWidth="100%">
        <Box>
          <Stack
            direction={"row"}
            spacing={5}
            alignItems="center"
            justifyContent={"space-between"}
          >
            <Typography variant="h6">Dealerships</Typography>
            <div
              style={{
                display: "flex",
                justifyContent: "end",
                gap: 5,
                alignItems: "center",
              }}
            >
              <SelectMenu
                sx={{ minWidth: 150 }}
                label="Organisation"
                options={orgs}
                value={selected}
                handleChange={(value, name) => setSelected(value)}
                name={"selected"}
              >
                {orgs &&
                  orgs?.map((o, i) => (
                    <MenuItem key={o._id + i} value={o._id}>
                      {o.name}
                    </MenuItem>
                  ))}
              </SelectMenu>
              {isAllowed(user?.role, ["super-admin"]) && (
                <>
                  <Button
                    onClick={() => setOpen(true)}
                    variant="contained"
                    //  color="info"
                    disabled={!isAllowed(user?.role, ["super-admin"])}
                  >
                    New Organisation
                  </Button>
                  <Button
                    onClick={() => navigate(`/dealerships/new`)}
                    variant="contained"
                    //   color="info"
                    disabled={!isAllowed(user?.role, ["super-admin"])}
                  >
                    New Dealership
                  </Button>
                </>
              )}
            </div>
          </Stack>
          {dealerships?.length === 0 ? (
            <Stack
              sx={{ py: 3, px: 5, minHeight: "500px" }}
              justifyContent={"center"}
              alignItems="center"
              spacing={2}
            >
              <Typography color={"gray"} variant="h5">
                This Organisation has no dealerships
              </Typography>
              <Stack
                alignItems={"center"}
                justifyContent="center"
                direction={"row"}
                spacing={2}
              >
                <Button
                  onClick={() => navigate(`/dealerships/new`)}
                  variant="contained"
                >
                  Create new dealership
                </Button>
              </Stack>
            </Stack>
          ) : (
            <Grid py={3} container spacing={3}>
              {dealerships?.map((d, i) => (
                <Grid key={d?._id + i} xs={6} md={4} xl={3} item>
                  <DealershipCard dealership={d} />
                </Grid>
              ))}
            </Grid>
          )}
        </Box>
      </Container>
      {isAllowed(user?.role, ["super-admin"]) && (
        <PopUpDialogCreateOrg
          setErr={setError}
          loading={isLoading}
          open={open}
          setLoading={setIsLoading}
          setOpen={setOpen}
          setOrgs={setOrgs}
          setShow={setShow}
        />
      )}
    </>
  )
}
