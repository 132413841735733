import * as React from "react"
import Table from "@mui/material/Table"
import TableBody from "@mui/material/TableBody"
import TableCell from "@mui/material/TableCell"
import TableContainer from "@mui/material/TableContainer"
import TableHead from "@mui/material/TableHead"
import TableRow from "@mui/material/TableRow"
import Paper from "@mui/material/Paper"
import { formatDate, formatPrice } from "../../helppers/formatters"
import { Link } from "react-router-dom"
import { Divider, Popover, Stack, Typography } from "@mui/material"
import MouseOverPopover from "../global/PopOverHover"

function Row({ lead }) {
  return (
    <React.Fragment>
      <TableRow sx={{ "& > *": { borderBottom: "unset" } }}>
        <TableCell component="th" scope="row">
          <Link
            style={{
              color: "inherit",
              fontWeight: 600,
              textDecoration: "none",
            }}
            to={`/leads/item/${lead?._id}`}
          >
            {lead?.title}
          </Link>
        </TableCell>
        <TableCell align="left">{lead?.vin?.toUpperCase()}</TableCell>
        <TableCell align="left">{lead.market?.name}</TableCell>
        <TableCell align="left">{lead?.acceptedBy?.dname}</TableCell>
        <TableCell align="center">{formatDate(lead.acceptedAt)}</TableCell>
        <TableCell align="center">
          <Typography fontWeight={600}>
            {formatPrice(lead?.agreedPrice)}
          </Typography>
        </TableCell>
      </TableRow>
    </React.Fragment>
  )
}

export default function AcceptedTable({ data }) {
  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow sx={{ fontWeight: 500 }}>
            <TableCell>Title</TableCell>
            <TableCell align="left">VIN</TableCell>
            <TableCell align="left">Market</TableCell>
            <TableCell align="left">Dealership</TableCell>
            <TableCell align="center">Accepted Date</TableCell>
            <TableCell align="center">Agreed Price</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data && data?.map((lead) => <Row key={lead?._id} lead={lead} />)}
        </TableBody>
      </Table>
    </TableContainer>
  )
}
