import * as React from "react"
import Table from "@mui/material/Table"
import TableBody from "@mui/material/TableBody"
import TableCell from "@mui/material/TableCell"
import TableContainer from "@mui/material/TableContainer"
import TableRow from "@mui/material/TableRow"
import Paper from "@mui/material/Paper"
import { Typography } from "@mui/material"

const RowTableData = ({ title, value }) => {
  return (
    <TableRow sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
      <TableCell component="th" scope="row">
        <Typography sx={{ fontWeight: "500" }} variant="body1">
          {title}
        </Typography>
      </TableCell>
      <TableCell align="right">
        <Typography sx={{ fontWeight: "500" }} variant="subtitle2">
          {value}
        </Typography>
      </TableCell>
    </TableRow>
  )
}

export default function Report({ report }) {
  return (
    <TableContainer component={Paper}>
      <Table aria-label="simple table">
        <TableBody>
          <RowTableData title={"Leads Sent"} value={report?.sentCount} />
          <RowTableData title={"Interested"} value={report?.interstedCount} />
          <RowTableData title={"Bids"} value={report?.counteredCount} />
          <RowTableData title={"Accepted"} value={report?.acceptedCount} />
          <RowTableData
            title={"Appointments"}
            value={report?.appointmentsCount}
          />
          <RowTableData title={"Closed Deals"} value={report?.dealsCount} />
          <RowTableData title={"Declined"} value={report?.declinedCount} />
        </TableBody>
      </Table>
    </TableContainer>
  )
}
