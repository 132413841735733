import { Stack } from "@mui/material"
import React from "react"
import Popper from "@mui/material/Popper"
import Typography from "@mui/material/Typography"
import Grid from "@mui/material/Grid"
import Fade from "@mui/material/Fade"
import Paper from "@mui/material/Paper"
import TopTitleLeftSide from "./TopTitleLeftSide"
import TopTileRightSide from "./TopTileRightSide"

export default function TopTitleSide({
  lead,
  setIsOpen,
  setLead,
  setActivity,
}) {
  const [anchorEl, setAnchorEl] = React.useState(null)
  const [fullText, setFullText] = React.useState(null)
  const [open, setOpen] = React.useState(false)
  const [placement, setPlacement] = React.useState()

  const handleClick = (newPlacement, text) => (event) => {
    setAnchorEl(event.currentTarget)
    setOpen((prev) => placement !== newPlacement || !prev)
    setPlacement(newPlacement)
    setFullText(text)
  }
  return (
    <>
      <Popper open={open} anchorEl={anchorEl} placement={"top"} transition>
        {({ TransitionProps }) => (
          <Fade {...TransitionProps} timeout={350}>
            <Paper>
              <Typography sx={{ p: 2 }}>{fullText}</Typography>
            </Paper>
          </Fade>
        )}
      </Popper>
      <Stack
        sx={{
          paddingX: "20px",
          paddingY: "10px",
          display: "flex",
          width: "100%",
        }}
        direction={{ xs: "column", sm: "row" }}
        spacing={{ xs: 1, sm: 2, md: 4 }}
      >
        <Grid columns={15} container spacing={2}>
          <Grid item sm={12}>
            <TopTitleLeftSide
              setIsOpen={setIsOpen}
              lead={lead}
              handleClick={handleClick}
            />
          </Grid>
          <Grid item sm={3}>
            <TopTileRightSide
              lead={lead}
              setLead={setLead}
              setActivity={setActivity}
            />
          </Grid>
        </Grid>
      </Stack>
    </>
  )
}
