import {
  getDownloadURL,
  getMetadata,
  ref,
  uploadBytesResumable,
  uploadString,
} from "firebase/storage"
import { storage } from "../../config/firebase"
import { generateIds } from "../generators"
import { deleteFromFB } from "./deleteImages"

export const UploadUrlString = (
  url,
  setUploading,
  path = "cars",
  lead,
  setLead
) => {
  setUploading(true)
  const name = generateIds() + new Date().getTime()
  const storageRef = ref(storage, `/us/${path}/${name}`)
  uploadString(storageRef, url, "data_url").then(async (snapshot) => {
    setUploading(true)
    const url = await getDownloadURL(snapshot.ref)
    const meta = await getMetadata(snapshot.ref)

    if (lead?.f_image) {
      await deleteFromFB(lead?.f_image?.path)
    }
    setLead((prev) => ({
      ...prev,
      f_image: {
        url,
        name: meta?.name,
        path: meta.fullPath,
      },
    }))
    setUploading(false)
  })
}

export const UploadUrlStringimgs = (
  url,
  setUploading,
  path = "cars",
  setData
) => {
  setUploading(true)
  const name = generateIds() + new Date().getTime()
  const storageRef = ref(storage, `/us/${path}/${name}`)
  uploadString(storageRef, url, "data_url").then(async (snapshot) => {
    setUploading(true)
    const url = await getDownloadURL(snapshot.ref)
    const meta = await getMetadata(snapshot.ref)
    setData((prev) => ({
      ...prev,
      images: [
        ...prev?.images,
        {
          url,
          name: meta?.name,
          path: meta.fullPath,
        },
      ],
    }))
    setUploading(false)
  })
}

export const UploadUrlStringAc = (
  url,
  setImages,
  setUploading,
  path = "activity"
) => {
  setUploading(true)
  const name = generateIds() + new Date().getTime()
  const storageRef = ref(storage, `/us/${path}/${name}`)
  uploadString(storageRef, url, "data_url").then(async (snapshot) => {
    setUploading(true)
    const url = await getDownloadURL(snapshot.ref)
    const meta = await getMetadata(snapshot.ref)
    setImages((prev) => [
      ...prev,
      {
        url,
        name: meta?.name,
        path: meta.fullPath,
      },
    ])
    setUploading(false)
  })
}

//upload single image
export const uploadImage = (file, setProgress, lead, setLead) => {
  if (!file) return
  setProgress(true)
  const name = generateIds() + new Date().getTime()
  const storageRef = ref(storage, `/us/cars/${name}`)
  const uploadTask = uploadBytesResumable(storageRef, file)

  uploadTask.on(
    "state_changed",
    (snapshot) => {
      const prog = Math.round(
        (snapshot.bytesTransferred / snapshot.totalBytes) * 100
      )
    },
    (err) => {
      console.log(err)
      setProgress(false)
    },
    async () => {
      const url = await getDownloadURL(uploadTask.snapshot.ref)
      const meta = await getMetadata(uploadTask.snapshot.ref)
      if (lead?.f_image) {
        await deleteFromFB(lead?.f_image?.path)
      }
      setLead((prev) => ({
        ...prev,
        f_image: {
          url,
          name: meta?.name,
          path: meta.fullPath,
        },
      }))
      setProgress(false)
    }
  )
}

/*
*stat
@Upload multiple images
*/

export const uploadimages = async (
  files,
  setProgress,
  path = "cars",
  setLead
) => {
  if (files.length === 0) return
  const promises = []
  setProgress(true)
  for (var i = 0; i < files.length; i++) {
    const file = files[i]
    promises.push(uploadImageAsPromise(file, path))
  }
  Promise.all(promises)
    .then((filesData) => {
      setLead((prev) => ({ ...prev, images: [...prev?.images, ...filesData] }))
      setProgress(false)
    })
    .catch((err) => {
      console.log(err)
      setProgress(false)
    })
}

async function uploadImageAsPromise(file, path) {
  return new Promise(function (resolve, reject) {
    const name = generateIds() + new Date().getTime()
    const storageRef = ref(storage, `/us/${path}/${name}`)
    const uploadTask = uploadBytesResumable(storageRef, file)

    uploadTask.on(
      "state_changed",
      (snapshot) => {
        const prog = Math.round(
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100
        )
      },
      function error(err) {
        reject(err)
      },
      async function complete() {
        const url = await getDownloadURL(uploadTask.snapshot.ref)
        const metadata = await getMetadata(uploadTask.snapshot.ref)
        resolve({
          url,
          name: metadata.name,
          path: metadata.fullPath,
        })
      }
    )
  })
}

async function uploadImageAsPromiseAc(file, path) {
  return new Promise(function (resolve, reject) {
    const name = generateIds() + new Date().getTime()
    const storageRef = ref(storage, `/us/${path}/${name}`)
    const uploadTask = uploadBytesResumable(storageRef, file)

    uploadTask.on(
      "state_changed",
      (snapshot) => {
        const prog = Math.round(
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100
        )
      },
      function error(err) {
        reject(err)
      },
      async function complete() {
        const url = await getDownloadURL(uploadTask.snapshot.ref)
        const metadata = await getMetadata(uploadTask.snapshot.ref)
        resolve({
          url,
          name: metadata.name,
          path: metadata.fullPath,
        })
      }
    )
  })
}

export const uploadImagesActivity = async (
  files,
  setLoading,
  setImages,
  path = "activity"
) => {
  if (files.length === 0) return
  const promises = []
  for (var i = 0; i < files.length; i++) {
    const file = files[i]
    promises.push(uploadImageAsPromiseAc(file, path))
  }
  setLoading(true)
  await Promise.all(promises)
    .then((filesData) => {
      setImages((prev) => [...prev, ...filesData])
      setLoading(false)
    })
    .catch((err) => {
      console.log(err)
      setLoading(false)
    })
}
