import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  Chip,
  Paper,
  Stack,
  Typography,
} from "@mui/material"
import moment from "moment"
import momentTz from "moment-timezone"
import React from "react"
import { BiBarcode } from "react-icons/bi"
import { BsSpeedometer2 } from "react-icons/bs"
import { LazyLoadImage } from "react-lazy-load-image-component"
import { Link } from "react-router-dom"
import { formatPrice } from "../../helppers/formatters"

export default function LeadCard({ event }) {
  return (
    <Card>
      <CardMedia sx={{ position: "relative" }}>
        <LazyLoadImage
          src={event?.lead?.f_image?.url}
          width="100%"
          style={{ objectFit: "cover", objectPosition: "center" }}
          height="250px"
          alt={event?.lead?.title}
          placeholderSrc="/placeholder.png"
        />
        <Chip
          sx={{
            position: "absolute",
            bottom: 0,
            right: 0,
            borderRadius: 0,
            fontSize: "12px",
          }}
          label={momentTz
            .tz(event?.start, "America/Chicago")
            .format("MM/DD/YY @ h:mm A")}
          color={
            event?.status === "Deal"
              ? "success"
              : event?.status === "Lost"
              ? "error"
              : event?.status === "Canceled"
              ? "error"
              : "info"
          }
        />
      </CardMedia>
      <CardContent>
        <Typography
          sx={{
            overflow: "hidden",
            whiteSpace: "nowrap",
            textOverflow: "ellipsis",
          }}
          gutterBottom
          variant="h6"
          component="div"
        >
          {event?.lead?.title}
        </Typography>

        {/* <Stack direction={"row"} alignItems="center" spacing={3}>
          <Stack direction={"row"} alignItems="center" spacing={2}>
            <Typography
              display="flex"
              alignItems="center"
              gap={1}
              variant="body2"
              color="text.secondary"
            >
              <BiBarcode fontSize={20} />
              {event?.lead?.vin}
            </Typography>
          </Stack>
          <Stack direction={"row"} alignItems="center" spacing={2}>
            <Typography
              display="flex"
              alignItems="center"
              gap={1}
              variant="body2"
              color="text.secondary"
            >
              <BsSpeedometer2 fontSize={20} />
              {event?.lead?.mileage?.toLocaleString()} mi
            </Typography>
          </Stack>
        </Stack> */}

        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            width: "100%",
          }}
        >
          <Chip
            label={event?.status}
            color={
              event?.status === "Deal"
                ? "success"
                : event?.status === "Lost"
                ? "error"
                : event?.status === "Canceled"
                ? "error"
                : "info"
            }
          />
          <Typography variant="h6" color="text.secondary">
            {formatPrice(event?.lead?.agreedPrice)}
          </Typography>
        </Box>
        <Typography
          sx={{
            overflow: "hidden",
            whiteSpace: "nowrap",
            textOverflow: "ellipsis",
            mt: 1,
            fontWeight: "600",
          }}
          gutterBottom
          variant="body1"
          component="div"
        >
          {event?.dealership?.dname}
        </Typography>
        <Typography
          sx={{
            overflow: "hidden",
            whiteSpace: "nowrap",
            textOverflow: "ellipsis",
            mt: 1,
            fontWeight: "500",
          }}
          variant="body2"
          component="div"
        >
          {momentTz
            .tz(event?.start, event?.tz || "America/Chicago")
            .format("MM/DD/YY @ h:mm A")}{" "}
          - {event?.tz}
        </Typography>
      </CardContent>
      <CardActions>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            width: "100%",
          }}
        >
          <Button
            size="large"
            fullWidth
            variant="outlined"
            to={`/leads/item/${event?.lead?._id}`}
            LinkComponent={Link}
          >
            View this lead
          </Button>
        </Box>
      </CardActions>
    </Card>
  )
}
