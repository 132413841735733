import React from "react"
import { Divider, Stack } from "@mui/material"
import CreateReportBox from "../CreateReportBox"
import DataResults from "./DataResults"

export default function DealerReportBox({
  start,
  setStart,
  end,
  setEnd,
  type,
  setType,
  results,
  loading,
  rangeData,
  ongenerate,
  selected,
  setSelected,
}) {
  return (
    <Stack direction={"row"} spacing={2}>
      <CreateReportBox
        start={start}
        end={end}
        setStart={setStart}
        setEnd={setEnd}
        type={type}
        setType={setType}
        ongenerate={ongenerate}
        rangeData={rangeData}
        loading={loading}
        selected={selected}
        setSelected={setSelected}
        isdb
      />
      <Divider orientation="vertical" flexItem />
      <Stack flex={5}>
        {loading ? (
          <>Loading....</>
        ) : results ? (
          <>
            {results?.length > 0 ? (
              <DataResults
                start={start}
                end={end}
                type={type}
                rangeData={rangeData}
                results={results}
                selected={selected}
              />
            ) : (
              <>No results found!</>
            )}
          </>
        ) : (
          <>Generate new report to view the results</>
        )}
      </Stack>
    </Stack>
  )
}
