import * as React from "react"
import { styled } from "@mui/material/styles"
import List from "@mui/material/List"
import ListItem from "@mui/material/ListItem"
import moment from "moment-timezone"
import ListItemText from "@mui/material/ListItemText"
import { Stack, Typography } from "@mui/material"
import { Link } from "react-router-dom"

const Demo = styled("div")(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
}))

export default function AgentLogs({ activity, err, isLoading }) {
  return (
    <Demo>
      <List dense>
        {isLoading ? (
          <Typography py={5} textAlign={"center"}>
            Loading ...
          </Typography>
        ) : err ? (
          <Typography py={5} textAlign={"center"}>
            {err}
          </Typography>
        ) : activity && activity?.length === 0 ? (
          <Typography py={5} textAlign={"center"}>
            No Activity Found
          </Typography>
        ) : (
          activity?.map((item, i) => (
            <ListItem
              sx={{ overflow: "hidden", overflowY: "auto", maxHeight: 900 }}
              key={item?._id + i}
            >
              <ListItemText
                primary={
                  <Stack spacing={1} direction={"row"} alignItems={"center"}>
                    <span>
                      {moment
                        .tz(item?.createdAt, "America/Chicago")
                        .format("MM/DD/YY h:mm A")}
                      :
                    </span>
                    <b>{item?.type}</b>
                    <Typography
                      sx={{
                        overflow: "hidden",
                        whiteSpace: "nowrap",
                        textOverflow: "ellipsis",
                      }}
                      fontWeight={"500"}
                    >
                      <Link to={`/`} style={{ color: "inherit" }}>
                        {item?.lead?.title}
                      </Link>
                    </Typography>
                  </Stack>
                }
              />
            </ListItem>
          ))
        )}
      </List>
    </Demo>
  )
}
