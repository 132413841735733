import { Box, Button, Dialog, DialogTitle, Divider, Stack } from "@mui/material"
import axios from "axios"
import React from "react"
import { URL } from "../../config/apis"
import useData from "../../context/Data"
import DealershipCriteria from "../dealership/DealershipCriteria"

export default function EditDealershipCriteia({
  data,
  setData,
  open,
  setOpen,
}) {
  const { loading, setLoading, setShow, setErr } = useData()

  const [criteria, setCriteria] = React.useState({
    ...data?.criteria,
  })

  const handleClose = (e, reason) => {
    if (reason !== "backdropClick") {
      setOpen(false)
    }
  }

  const handleBackdropClick = (event) => {
    event.stopPropagation()
    return false
  }

  const updateDealershipCriteria = async () => {
    try {
      const res = await axios.put(
        `${URL}/dealerships/criteria/${data?._id}`,
        criteria,
        {
          headers: {
            "Content-Type": "application/json",
          },
          withCredentials: true,
        }
      )

      if (res.data.ok) {
        setData((prev) => ({ ...prev, criteria }))
        setShow(true)
        setOpen(false)
      } else {
        setErr("Something went wrong!")
      }
      setLoading(false)
      setTimeout(() => {
        setShow(false)
        setErr(null)
      }, 2000)
    } catch (error) {
      setLoading(false)
      setErr(error.response?.data?.message || error.message)
      setTimeout(() => {
        setErr(null)
      }, 3000)
    }
  }

  return (
    <Dialog
      onClose={handleClose}
      open={open}
      onBackdropClick={handleBackdropClick}
      disableEscapeKeyDown
      maxWidth="md"
    >
      <DialogTitle>Update Dealership Criteria</DialogTitle>
      <Box
        sx={{
          "& > :not(style)": { width: "100%" },
          display: "flex",
          flexDirection: "column",
          gap: ".5rem",
        }}
        noValidate
        autoComplete="off"
      >
        <DealershipCriteria criteria={criteria} setCreiteria={setCriteria} />
        {
          <>
            <Divider />
            <Stack
              direction={"row"}
              justifyContent="end"
              alignItems={"center"}
              spacing={2}
              px={2}
              pb={2}
            >
              <Button
                type="button"
                onClick={handleClose}
                variant="contained"
                color="error"
                sx={{ bgcolor: "#CCC" }}
                disabled={loading}
              >
                Close
              </Button>
              <Button
                disabled={loading}
                variant="contained"
                color="success"
                onClick={updateDealershipCriteria}
              >
                Update
              </Button>
            </Stack>
          </>
        }
      </Box>
    </Dialog>
  )
}
