import {
  Avatar,
  Box,
  Button,
  Chip,
  CircularProgress,
  Divider,
  Grid,
  IconButton,
  Paper,
  Stack,
  Typography,
} from "@mui/material"
import React from "react"
import axios from "axios"
import { useState } from "react"
import { Link, useParams } from "react-router-dom"
import { URL } from "../config/apis"
import { useEffect } from "react"
import UsersTable from "../components/dealership/UsersTable"
import PopUpDialogCreateUser from "../components/modals/PopUpDialogCreateUser"
import FeedBackBox from "../components/global/FeedBackBox"
import SimpleDialog from "../components/modals/DialgoSelectUser"
import moment from "moment"
import EditDealershipModal from "../components/modals/EditDealershipModal"
import PopUpDialogEditUser from "../components/modals/PopUpDialogEditUser"
import Report from "../components/dealership/Report"
import DateRangeBox from "../components/dealership/DateRangeBox"
import useAuth from "../context/Auth"
import { isAllowed } from "../helppers/checkRoles"
import { formatPrice } from "../helppers/formatters"
import EditDealershipCriteia from "../components/modals/EditDealershipCriteia"
import ContentCopyIcon from "@mui/icons-material/ContentCopy"

const formatDate = (date) => {
  if (!date) return null
  const formated = moment(date)

  if (formated.isValid()) {
    return formated.format("MM/DD/YYYY")
  }
  return null
}

async function copyTextToClipboard(text) {
  if ("clipboard" in navigator) {
    return await navigator.clipboard.writeText(text)
  } else {
    return document.execCommand("copy", true, text)
  }
}

const handleCopyClick = (text) => {
  copyTextToClipboard(text)
    .then(() => {})
    .catch((err) => {
      console.log(err)
    })
}

export default function DealershipPage() {
  const { id } = useParams()
  const { user } = useAuth()

  const [dealership, setDealership] = useState(null)
  const [loading, setLoading] = useState(true)
  const [fetching, setFiching] = useState(true)
  const [modalOpen, setModalOpen] = useState(false)
  const [open, setOpen] = useState(false)
  const [isOpen, setIsOpen] = useState(false)
  const [isShow, setIsShow] = useState(false)
  const [openEdit, setOpenEdit] = useState(false)
  const [err, setErr] = useState(null)
  const [selected, setSelected] = useState(null)
  const [report, setReport] = useState(null)
  const [fail, setFail] = React.useState(null)

  const [isLoading, setIsLoading] = React.useState(false)
  const [error, setError] = React.useState(null)
  const [show, setShow] = React.useState(false)

  const [dateRange, setDateRange] = React.useState([null, null])
  const [startDate, endDate] = dateRange

  const getDealership = async () => {
    setErr(null)
    try {
      const res = await axios.get(`${URL}/dealerships/profile/${id}`, {
        headers: {
          "Content-Type": "application/json",
        },
        withCredentials: true,
      })

      setDealership(res.data)
      setLoading(false)
    } catch (error) {
      setLoading(false)
      setErr(error.response?.data?.message || error.message)
    }
  }
  const getDealershipReport = async () => {
    const start = formatDate(startDate)
    const end = formatDate(endDate)
    setFail(null)
    setFiching(true)
    try {
      const res = await axios.get(
        `${URL}/dealerships/report/${id}?start=${start}&end=${end}`,
        {
          headers: {
            "Content-Type": "application/json",
          },
          withCredentials: true,
        }
      )

      setReport(res.data)
      setFiching(false)
    } catch (error) {
      setFiching(false)
      setFail(error.response?.data?.message || error.message)
    }
  }

  useEffect(() => {
    getDealership()
  }, [])
  useEffect(() => {
    if (startDate && !endDate) return
    if (!startDate && endDate) return
    getDealershipReport()
  }, [startDate, endDate])

  if (loading) {
    return <>loading...</>
  }

  if (err) {
    return <>{err}</>
  }

  return (
    <>
      <Paper variant="outlined">
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            p: 2,
          }}
        >
          <Stack
            width={"100%"}
            alignItems="center"
            direction={"row"}
            spacing={5}
          >
            {/* <Avatar
              sx={{ width: 120, height: 120, objectFit: "contain" }}
              alt={dealership?.dname}
              src={dealership?.logo?.url || "/nologo.png"}
            /> */}
            <div>
              <img
                src={dealership?.logo?.url || "/nologo.png"}
                style={{ height: 40, width: "auto" }}
                alt="logo"
              />
            </div>
            <Stack minWidth={"40%"} spacing={1}>
              <Typography fontWeight={600} color="#444444" variant="h5">
                {dealership?.dname}{" "}
                <Chip
                  color={
                    dealership?.dateDeleted
                      ? "error"
                      : dealership?.isActive
                      ? "success"
                      : "error"
                  }
                  label={
                    dealership?.dateDeleted
                      ? "Deleted"
                      : dealership?.isActive
                      ? "Active"
                      : "Inactive"
                  }
                  size="small"
                />
                <IconButton
                  sx={{ ml: 2 }}
                  size="small"
                  onClick={() => handleCopyClick(dealership?._id)}
                >
                  <ContentCopyIcon />
                </IconButton>
              </Typography>
              <Typography color="#949494" variant="body1">
                {dealership?.address}
              </Typography>
              <Typography color="#949494" variant="body1">
                {dealership?.city} / {dealership?.state} / {dealership?.zip}
              </Typography>
              {isAllowed(user?.role, ["super-admin"]) && (
                <Stack direction={"row"} spacing={2}>
                  <Button onClick={() => setIsShow(true)} variant="outlined">
                    Quick Edit
                  </Button>
                  <Button
                    LinkComponent={Link}
                    to={`/dealerships/profile/${dealership?._id}/edit`}
                    variant="contained"
                  >
                    Edit
                  </Button>
                  {/* <Button
                    onClick={() => setModalOpen(true)}
                    color="success"
                    variant="contained"
                  >
                    Update Criteria
                  </Button> */}
                </Stack>
              )}
            </Stack>
            <Divider orientation="vertical" variant="middle" flexItem />
            <Stack spacing={1}>
              <Typography variant="body1">
                Group: <b>{dealership?.organisation?.name}</b>
              </Typography>
              <Typography variant="body1">
                Market: <b>{dealership?.market?.name}</b>
              </Typography>
              <Typography variant="body1">
                Date Created:{" "}
                {moment(dealership?.createdAt).format("DD/MM/YYYY")}{" "}
                {dealership?.dateDeleted &&
                  ` - Date Deleted: ${moment(dealership?.dateDeleted).format(
                    "DD/MM/YYYY"
                  )}`}
              </Typography>
              {/* {isAllowed(user?.role, ["super-admin"]) && (
                <Typography variant="body1">
                  Team Attached: {dealership?.team?.name || "N/A"}
                </Typography>
              )} */}
              {isAllowed(user?.role, ["super-admin"]) && (
                <Typography variant="body1">
                  Fee: {formatPrice(dealership?.fee)}
                </Typography>
              )}
              <Stack direction={"row"} gap={2} flexWrap={"wrap"}>
                <Typography variant="body1">
                  Year: {dealership?.criteria?.year?.minVal} -{" "}
                  {dealership?.criteria?.year?.maxVal}
                </Typography>
                <Typography variant="body1">
                  Mileage:{" "}
                  {dealership?.criteria?.mileage?.minVal?.toLocaleString()} -{" "}
                  {dealership?.criteria?.mileage?.maxVal?.toLocaleString()}
                </Typography>
                <Typography variant="body1">
                  Price: $
                  {dealership?.criteria?.price?.minVal?.toLocaleString()} - $
                  {dealership?.criteria?.price?.maxVal?.toLocaleString()}
                </Typography>
              </Stack>
            </Stack>
          </Stack>
        </Box>
      </Paper>
      <Divider />

      <Grid container columns={3} spacing={2}>
        <Grid item sm={3} md={2}>
          <Stack spacing={2}>
            <Stack
              alignItems="center"
              direction={"row"}
              sx={{ pt: 2 }}
              spacing={2}
              justifyContent="space-between"
            >
              <Typography variant="h6">Users</Typography>
              {!dealership?.dateDeleted &&
                isAllowed(user?.role, ["super-admin"]) && (
                  <Stack alignItems="center" direction={"row"} spacing={2}>
                    <Button onClick={() => setIsOpen(true)} variant="outlined">
                      Select User
                    </Button>
                    <Button onClick={() => setOpen(true)} variant="contained">
                      New User
                    </Button>
                  </Stack>
                )}
            </Stack>
            <Divider />
            {dealership?.users?.length === 0 ? (
              <Paper>
                <Stack
                  sx={{ py: 3, px: 5 }}
                  justifyContent={"center"}
                  alignItems="center"
                  spacing={2}
                >
                  <Typography color={"gray"} variant="h5">
                    This dealership has no users!
                  </Typography>
                  {!dealership?.dateDeleted &&
                    isAllowed(user?.role, ["super-admin"]) && (
                      <Stack
                        alignItems={"center"}
                        justifyContent="center"
                        direction={"row"}
                        spacing={2}
                      >
                        <Button
                          onClick={() => setIsOpen(true)}
                          variant="outlined"
                        >
                          Select user
                        </Button>
                        <Typography>Or</Typography>
                        <Button
                          onClick={() => setOpen(true)}
                          variant="contained"
                        >
                          Create new
                        </Button>
                      </Stack>
                    )}
                </Stack>
              </Paper>
            ) : (
              <UsersTable
                users={dealership?.users}
                setDealership={setDealership}
                admins={dealership?.admins}
                setSelected={setSelected}
                setOpen={setOpenEdit}
                isAllowed={isAllowed(user?.role, ["super-admin"])}
                notDeleted={dealership?.dateDeleted == null}
                handleCopyClick={handleCopyClick}
              />
            )}
          </Stack>
        </Grid>

        <Grid item sm={3} md={1}>
          <Stack sx={{ pt: 2.5, width: "100%" }} spacing={2}>
            <Stack
              direction={"row"}
              width="100%"
              justifyContent="space-between"
              spacing={2}
              alignItems="center"
            >
              <Typography flex={1} variant="h6">
                Report
              </Typography>
              {report &&
              report?.range &&
              report?.range?.start != null &&
              report?.range?.end != null ? (
                <Typography flex={1} variant="caption">
                  {`${report?.range?.start} - ${report?.range?.end}`}
                </Typography>
              ) : (
                <Typography flex={1} variant="caption">
                  All Time
                </Typography>
              )}
              <DateRangeBox
                endDate={endDate}
                setDateRange={setDateRange}
                startDate={startDate}
              />
            </Stack>
            <Divider />
            {fetching ? (
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  py: 10,
                }}
              >
                <CircularProgress />
              </Box>
            ) : fail ? (
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  py: 10,
                }}
              >
                <Typography color={"#be0b30"} fontWeight="500" variant="body1">
                  {fail}
                </Typography>
              </Box>
            ) : report ? (
              <Report report={report?.data} />
            ) : (
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  py: 10,
                }}
              >
                <Typography color={"#be0b30"} fontWeight="500" variant="body1">
                  {"Something went wrong!"}
                </Typography>
              </Box>
            )}
          </Stack>
        </Grid>
      </Grid>
      {isAllowed(user?.role, ["super-admin"]) && (
        <>
          <PopUpDialogCreateUser
            loading={isLoading}
            setDealership={setDealership}
            setErr={setError}
            setLoading={setIsLoading}
            setShow={setShow}
            id={id}
            open={open}
            setOpen={setOpen}
          />

          {selected && (
            <PopUpDialogEditUser
              loading={isLoading}
              setDealership={setDealership}
              setErr={setError}
              setLoading={setIsLoading}
              setShow={setShow}
              open={openEdit}
              setOpen={setOpenEdit}
              selected={selected}
              setSelected={setSelected}
            />
          )}

          <EditDealershipModal
            data={dealership}
            getData={getDealership}
            open={isShow}
            setOpen={setIsShow}
          />

          <SimpleDialog
            setOpen={setIsOpen}
            open={isOpen}
            dealership={dealership}
            setDealership={setDealership}
          />
          <EditDealershipCriteia
            open={modalOpen}
            setOpen={setModalOpen}
            data={dealership}
            setData={setDealership}
          />
        </>
      )}
      <FeedBackBox loading={isLoading} show={show} err={error} />
    </>
  )
}
