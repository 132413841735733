import React from "react"
import SelectMarkets from "../components/settings/SelectMarkets"
import { Typography } from "@mui/material"

export default function SettingPage() {
  return (
    <div>
      <Typography variant="h5">Settings</Typography>
      <br />
      <SelectMarkets />
    </div>
  )
}
