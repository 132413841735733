import Container from "@mui/material/Container"
import Alert from "@mui/material/Alert"
import React from "react"
import axios from "axios"
import Stack from "@mui/material/Stack"
import Typography from "@mui/material/Typography"
import useAuth from "../context/Auth"
import Grid from "@mui/material/Grid"
import PassedAppointments from "../components/dashboard/PassedAppointments"
import UpcomingAppointmnets from "../components/dashboard/UpcomingAppointmnets"
import RightSide from "../components/dashboard/RightSide"
import { URL } from "../config/apis"
import { Divider } from "@mui/material"
import AppointmentsBox from "../components/dashboard/AppointmentsBox"
import LatestAccepted from "../components/dashboard/LatestAccepted"
import LatestDeals from "../components/dashboard/LatestDeals"
import LatestCounters from "../components/dashboard/LatestCounters"

export const DashboardContext = React.createContext()

export default function Dashboard() {
  // const cash = JSON.parse(sessionStorage.getItem("updates"))
  const { user } = useAuth()
  // const [selected, setSelected] = React.useState(null)
  // const [loading, setLoading] = React.useState(true)
  // const [passed, setPassed] = React.useState([])
  // const [upcoming, setUpcoming] = React.useState([])
  // const [accepted, setAcceted] = React.useState([])
  // const [counters, setCounters] = React.useState([])
  // const [deals, setDeals] = React.useState([])
  // const [updates, setUpdates] = React.useState(cash?.updates || [])

  // const getData = async () => {
  //   try {
  //     const [p, u, a, c, d] = await Promise.all([
  //       axios.get(`${URL}/calendar/dashboard/passed`, {
  //         headers: {
  //           "Content-Type": "application/json",
  //         },
  //         withCredentials: true,
  //       }),
  //       axios.get(`${URL}/calendar/dashboard/upcoming`, {
  //         headers: {
  //           "Content-Type": "application/json",
  //         },
  //         withCredentials: true,
  //       }),
  //       axios.get(`${URL}/dashboard/accepted`, {
  //         headers: {
  //           "Content-Type": "application/json",
  //         },
  //         withCredentials: true,
  //       }),
  //       axios.get(`${URL}/dashboard/counters`, {
  //         headers: {
  //           "Content-Type": "application/json",
  //         },
  //         withCredentials: true,
  //       }),
  //       axios.get(`${URL}/dashboard/deals`, {
  //         headers: {
  //           "Content-Type": "application/json",
  //         },
  //         withCredentials: true,
  //       }),
  //     ])
  //     setPassed(p.data)
  //     setUpcoming(u.data)
  //     setAcceted(a.data)
  //     setCounters(c.data)
  //     setDeals(d.data)
  //   } catch (error) {
  //     console.log(error)
  //   }
  //   setLoading(false)
  // }

  // React.useEffect(() => {
  //   getData()
  // }, [])

  // const values = React.useMemo(() => {
  //   return {
  //     selected,
  //     setSelected,
  //     passed,
  //     upcoming,
  //     loading,
  //     updates,
  //     setUpdates,
  //     accepted,
  //     counters,
  //     deals,
  //   }
  // }, [selected, passed, upcoming, loading, updates, accepted, counters, deals])

  return (
    <DashboardContext.Provider value={{}}>
      <Container maxWidth="100%">
        <Alert icon={false} severity="primary">
          <Stack>
            <Typography color={"#424242"} variant="h5" fontWeight={700}>
              Welcome {user?.fname}!
            </Typography>
            <Typography sx={{ color: "gray" }} variant="body2" fontWeight={400}>
              {new Date().toDateString()}
            </Typography>
          </Stack>
        </Alert>
        <br />
        {/* <Stack>
          <Grid container columns={14} spacing={3}>
            <Grid item sm={8}>
              <Grid container columns={8} spacing={3}>
                <PassedAppointments />
                <UpcomingAppointmnets />
              </Grid>
            </Grid>
            <Grid item sm={6}>
              <RightSide />
            </Grid>
          </Grid>
          <Divider sx={{ my: 2 }} />
          <Grid container spacing={3}>
            <LatestAccepted />
            <LatestCounters />
            <LatestDeals />
          </Grid>
        </Stack> */}
      </Container>
    </DashboardContext.Provider>
  )
}
