import React from "react"
import FullCalendar from "@fullcalendar/react"
import dayGridPlugin from "@fullcalendar/daygrid"
import momentTimezonePlugin from "@fullcalendar/moment-timezone"
import { Popper, Typography } from "@mui/material"
import Fade from "@mui/material/Fade"
import Paper from "@mui/material/Paper"
import LeadCard from "./LeadCard"

export default function Calendar({ getAppointments, appointments }) {
  const [anchorEl, setAnchorEl] = React.useState(null)
  const [open, setOpen] = React.useState(false)
  const [placement, setPlacement] = React.useState(null)

  const handleClick = (event) => {
    const { id, extendedProps } = event.event
    setAnchorEl(event.el)
    setOpen((prev) => placement?._id !== id || !prev)
    setPlacement(extendedProps)
  }

  const events = React.useMemo(() => {
    return appointments?.map((ev) => {
      const { lead, status, start, _id } = ev

      let startTime = new Date(start)
      //    let endTime = new Date(end)
      return {
        id: _id,
        title: lead?.title,
        start: startTime,
        // end: endTime,

        display: "block",
        textColor: "#fff",
        color:
          status === "Deal"
            ? "#4caf50"
            : status === "Lost"
            ? "#d32f2f"
            : status === "Canceled"
            ? "#9b2727"
            : status === "No Show"
            ? "#9b2727"
            : "#42a5f5",
        extendedProps: { ...ev },
      }
    })
  }, [appointments])

  return (
    <div>
      <Popper
        sx={{ zIndex: 20 }}
        open={open}
        anchorEl={anchorEl}
        placement={"bottom"}
        transition
      >
        {({ TransitionProps }) => (
          <Fade {...TransitionProps} timeout={350}>
            <div style={{ width: 320 }}>
              <LeadCard event={placement} />
            </div>
          </Fade>
        )}
      </Popper>
      <FullCalendar
        timeZone="America/Chicago"
        plugins={[dayGridPlugin, momentTimezonePlugin]}
        initialView="dayGridWeek"
        headerToolbar={{
          left: "prev,next",
          center: "title",
          right: "dayGridDay,dayGridWeek,dayGridMonth", // user can switch between the two
        }}
        datesSet={async (info) => {
          const { start, end } = info
          await getAppointments(new Date(start), new Date(end))
        }}
        events={events}
        eventClick={handleClick}
      />
    </div>
  )
}
