import {
  Button,
  Chip,
  Divider,
  FormControlLabel,
  FormGroup,
  Stack,
  Switch,
  TextField,
  Typography,
} from "@mui/material"
import React, { useState } from "react"
import { timePicker } from "../../constants/timePicker"

export default function AdditionalInfoForm({
  dealership,
  setDealership,
  saveInfo,
  loading,
}) {
  const [timeFrame, setTimeFrame] = useState(
    dealership?.timeFrame?.length > 0 ? dealership?.timeFrame : timePicker
  )
  const [isClosedOnSundays, setIsClosedOnSundays] = useState(
    dealership?.isClosedOnSundays || false
  )
  const [moreInfo, setMoreInfo] = useState(
    dealership?.moreInfo || {
      paymentMethod: "",
      sellingOptions: "",
      additionalInfo: "",
    }
  )

  const selectTime = (id) => {
    setTimeFrame((prev) => {
      return prev?.map((x) => {
        if (x.start === id) {
          return { ...x, isSelected: !x.isSelected }
        } else {
          return x
        }
      })
    })
  }

  const handleChange = (val, name) => {
    setMoreInfo((prev) => ({ ...prev, [name]: val }))
  }

  const updateInfo = async () => {
    const data = {
      timeFrame,
      isClosedOnSundays,
      moreInfo,
    }
    await saveInfo(data)
    setDealership((prev) => ({ ...prev, ...data }))
  }

  return (
    <Stack spacing={2}>
      <Stack spacing={2}>
        <Typography variant="h6" fontSize={16}>
          Appointment Times
        </Typography>
        <Stack
          direction={"row"}
          flexWrap={"wrap"}
          gap={2}
          alignItems={"center"}
        >
          {timeFrame &&
            timeFrame?.map((item, i) => (
              <Chip
                label={item?.start}
                key={item?.start + i}
                color={item?.isSelected ? "primary" : "default"}
                onClick={() => selectTime(item?.start)}
                sx={{ cursor: "pointer" }}
                disabled={loading}
              />
            ))}
        </Stack>
      </Stack>
      <Divider />
      <Stack>
        <FormGroup>
          <FormControlLabel
            control={
              <Switch
                checked={isClosedOnSundays}
                disabled={loading}
                onChange={(e, checked) => setIsClosedOnSundays(checked)}
              />
            }
            label="Closed on Sundays"
          />
        </FormGroup>
      </Stack>
      <Divider />
      <Stack spacing={2}>
        <TextField
          size="small"
          id="outlined-basic"
          label="Payment Method"
          variant="outlined"
          fullWidth
          value={moreInfo?.paymentMethod}
          onChange={(e) => handleChange(e.target.value, "paymentMethod")}
          disabled={loading}
        />
        <TextField
          size="small"
          id="outlined-basic"
          label="Selling Options"
          variant="outlined"
          fullWidth
          value={moreInfo?.sellingOptions}
          onChange={(e) => handleChange(e.target.value, "sellingOptions")}
          disabled={loading}
        />
        <TextField
          size="small"
          id="outlined-basic"
          label="Additional Info"
          variant="outlined"
          fullWidth
          value={moreInfo?.additionalInfo}
          onChange={(e) => handleChange(e.target.value, "additionalInfo")}
          disabled={loading}
        />
      </Stack>
      <Divider />
      <Stack
        direction={"row"}
        justifyContent="end"
        alignItems={"center"}
        spacing={2}
        px={2}
        pb={2}
      >
        <Button
          disabled={loading}
          variant="contained"
          color="success"
          onClick={updateInfo}
        >
          Update
        </Button>
      </Stack>
    </Stack>
  )
}
