import React from "react"
import Stack from "@mui/material/Stack"
import TextField from "@mui/material/TextField"

export default function YearRange({ title = "Year", setCreiteria, selected }) {
  const handleSelect = (e, theKey, nKey) => {
    setCreiteria((prev) => {
      const t = prev?.year
      if (!t) {
        return { ...prev, year: { [theKey]: e, [nKey]: selected[nKey] } }
      }
      return { ...prev, year: { [theKey]: e, [nKey]: t[nKey] } }
    })
  }
  return (
    <Stack direction={"row"} spacing={1}>
      <TextField
        id="ninVal-year"
        size="small"
        label={`Min ${title}`}
        value={selected?.minVal}
        onChange={(e) =>
          handleSelect(
            parseFloat((e.target.value || "0").replace(/,/g, "")),
            "minVal",
            "maxVal"
          )
        }
      />
      <TextField
        id="maxVal-year"
        size="small"
        label={`Max ${title}`}
        value={selected?.maxVal}
        onChange={(e) =>
          handleSelect(
            parseFloat((e.target.value || "0").replace(/,/g, "")),
            "maxVal",
            "minVal"
          )
        }
      />
    </Stack>
  )
}
