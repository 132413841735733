import React, { useState } from "react"
import moment from "moment"
import momentTz from "moment-timezone"
import { useSearchParams } from "react-router-dom"
import ClaimedLeads from "../components/reports/ClaimedLeads"
import CreatedLeads from "../components/reports/CreatedLeads"
import ReportsResults from "../components/reports/ReportsResults"
import SelectReportType from "../components/reports/SelectReportType"
import ReportContainer from "../components/reports/ReportContainer"
import axios from "axios"
import { URL } from "../config/apis"

const setLocalZone = (date) => {
  return moment(date).format("MM/DD/YYYY")
}

function ReportsPage() {
  const [searchParams, setSearchParams] = useSearchParams()
  const [repType, setRepType] = React.useState("Created")
  const [done, setDone] = React.useState(false)
  const [start, setStart] = React.useState(setLocalZone(new Date()))
  const [end, setEnd] = React.useState(setLocalZone(new Date()))
  const [results, setResults] = React.useState(null)
  const [rangeData, setRangeData] = React.useState(null)
  const [loading, setLoading] = useState(false)

  const ongenerate = async () => {
    if (!start || !end || !repType) return
    setResults(null)
    setLoading(true)
    try {
      const res = await axios.get(
        `${URL}/reports/leads/${
          repType === "Created By Market" ? "markets" : repType
        }?start=${start}&end=${end}`,
        {
          headers: {
            "Content-Type": "application/json",
          },
          withCredentials: true,
        }
      )
      setResults(res.data.data)
      setRangeData(res.data.range)
    } catch (error) {
      console.log(error)
    } finally {
      setLoading(false)
    }
  }

  // const ReportTypes = {
  //   Created: (
  //     <CreatedLeads
  //       start={start}
  //       end={end}
  //       setStart={setStart}
  //       setEnd={setEnd}
  //       setDone={setDone}
  //       onCancel={onCancel}
  //       onGenerate={ongenerate}
  //     />
  //   ),
  //   Claimed: (
  //     <ClaimedLeads
  //       start={start}
  //       end={end}
  //       setStart={setStart}
  //       setEnd={setEnd}
  //       setDone={setDone}
  //       onCancel={onCancel}
  //       onGenerate={ongenerate}
  //     />
  //   ),
  // }

  // if (!repType) {
  //   return <SelectReportType setReportType={setRepType} />
  // }

  // if (!done) {
  //   return (
  //     <CreatedLeads
  //       start={start}
  //       end={end}
  //       setStart={setStart}
  //       setEnd={setEnd}
  //       setDone={setDone}
  //       onCancel={onCancel}
  //       onGenerate={ongenerate}
  //       range={range}
  //       setRange={setRange}
  //     />
  //   )
  //   // return <div>{ReportTypes[repType]}</div>
  // }

  // return <ReportsResults onCancel={onCancel} />
  return (
    <ReportContainer
      start={start}
      end={end}
      setStart={setStart}
      setEnd={setEnd}
      type={repType}
      setType={setRepType}
      results={results}
      loading={loading}
      rangeData={rangeData}
      ongenerate={ongenerate}
    />
  )
}

export default ReportsPage
