import {
  getDownloadURL,
  getMetadata,
  ref,
  uploadBytesResumable,
} from "firebase/storage"
import { storage } from "../../config/firebase"
import { saveAvatar, saveDealerLogo } from "../apiCalls/save-avatar"
import { deleteFromFB } from "./deleteImages"

//upload single image
export const uploadImageAvatar = async (file, old, setUser) => {
  if (!file) return
  const name = file?.name + new Date().getTime()
  const storageRef = ref(storage, `/us/avatars/${name}`)
  const uploadTask = uploadBytesResumable(storageRef, file)

  uploadTask.on(
    "state_changed",
    (snapshot) => {
      const prog = Math.round(
        (snapshot.bytesTransferred / snapshot.totalBytes) * 100
      )
    },
    (err) => {
      console.log(err)
    },
    async () => {
      const url = await getDownloadURL(uploadTask.snapshot.ref)
      const meta = await getMetadata(uploadTask.snapshot.ref)
      if (old?.path) {
        deleteFromFB(old?.path)
      }
      const data = { url, name: meta?.name, path: meta.fullPath }
      await saveAvatar(data)
      setUser((prev) => ({ ...prev, avatar: data }))
    }
  )
}

//upload Dealership Logo
export const uploadDealershipLogo = async (file, old, setDealership,id) => {
  if (!file) return
  const name = file?.name + new Date().getTime()
  const storageRef = ref(storage, `/us/dealerships/${name}`)
  const uploadTask = uploadBytesResumable(storageRef, file)

  uploadTask.on(
    "state_changed",
    (snapshot) => {
      const prog = Math.round(
        (snapshot.bytesTransferred / snapshot.totalBytes) * 100
      )
    },
    (err) => {
      console.log(err)
    },
    async () => {
      const url = await getDownloadURL(uploadTask.snapshot.ref)
      const meta = await getMetadata(uploadTask.snapshot.ref)
      if (old?.path) {
        deleteFromFB(old?.path)
      }
      const data = { url, name: meta?.name, path: meta.fullPath }
      await saveDealerLogo({ logo: data }, id)
      setDealership((prev) => ({ ...prev, logo: data }))
    }
  )
}
