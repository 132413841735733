import { Chip, Stack } from "@mui/material"
import React, { useMemo } from "react"
import { formatPrice } from "../../../helppers/formatters"
import { MdOutlineAccessTime } from "react-icons/md"
import moment from "moment-timezone"
import DealershipsBox from "../../global/DealershipsBox"

export const SentToDealers = ({ str }) => {
  const parsed = useMemo(() => {
    return JSON.parse(str)
  }, [str])
  return (
    <Stack direction={"row"} flexWrap="wrap">
      Sent To :{" "}
      {parsed?.dealers?.map((d, i) => (
        <Chip
          label={d?.dname}
          key={d?._id - i}
          sx={{
            fontSize: 12,
            px: 0.2,
            py: 0.5,
            height: "max-content",
            fontWeight: 500,
            ml: 1,
          }}
        />
      ))}
    </Stack>
  )
}

export const RsentToDealers = ({ str }) => {
  const parsed = useMemo(() => {
    return JSON.parse(str)
  }, [str])
  return (
    <Stack direction={"row"} flexWrap="wrap">
      {parsed?.txt}{" "}
      {parsed?.dealers?.map((d, i) => (
        <Chip
          label={d?.dname}
          key={d?._id - i}
          sx={{
            fontSize: 12,
            px: 0.2,
            py: 0.5,
            height: "max-content",
            fontWeight: 500,
            ml: 1,
          }}
        />
      ))}
    </Stack>
  )
}

export const UnSentToDealers = ({ str }) => {
  const parsed = useMemo(() => {
    return JSON.parse(str)
  }, [str])
  return (
    <Stack direction={"row"} flexWrap="wrap">
      {parsed?.txt} :{" "}
      <Chip
        label={parsed?.dealer}
        color="warning"
        sx={{
          fontSize: 12,
          px: 0.2,
          py: 0.5,
          height: "max-content",
          fontWeight: 500,
          ml: 1,
        }}
      />
    </Stack>
  )
}

export const CounterSent = ({ str }) => {
  const parsed = useMemo(() => {
    return JSON.parse(str)
  }, [str])
  return (
    <Stack direction={"row"} spacing={2} flexWrap="wrap">
      <span>{parsed?.txt} of:</span>
      <b>{formatPrice(parsed?.amount)}</b>
    </Stack>
  )
}

export const PreNegSent = ({ str }) => {
  const parsed = useMemo(() => {
    return JSON.parse(str)
  }, [str])
  return (
    <Stack direction={"row"} spacing={2} flexWrap="wrap">
      <span>{parsed?.txt} of:</span>
      <b>{formatPrice(parsed?.amount)}</b>
    </Stack>
  )
}

export const AcceptCounter = ({ str }) => {
  const parsed = useMemo(() => {
    return JSON.parse(str)
  }, [str])
  return (
    <Stack direction={"row"} spacing={1} flexWrap="wrap">
      <span>{parsed?.txt} of:</span>
      <b>{formatPrice(parsed?.amount)} from:</b>
      <b style={{ color: "green" }}>{parsed?.dealer}</b>
    </Stack>
  )
}

export const DealLine = ({ str }) => {
  const parsed = useMemo(() => {
    return JSON.parse(str)
  }, [str])
  return (
    <Stack direction={"row"} spacing={1} flexWrap="wrap">
      <b>{parsed?.dealer}</b>
      <span>Purchased this Vehicle for</span>
      <b style={{ color: "green" }}>{formatPrice(parsed?.amount)}</b>
    </Stack>
  )
}

export const GenerateOfferLine = ({ str }) => {
  const parsed = useMemo(() => {
    return JSON.parse(str)
  }, [str])
  return (
    <Stack direction={"row"} spacing={1} flexWrap="wrap">
      <span>
        {parsed?.txt} : <b>{parsed?.dealer?.dname}</b> for{" "}
        <b style={{ color: "green" }}>{formatPrice(parsed?.amount)}</b> Valid
        Until :{" "}
        {moment
          .tz(parsed?.offer?.offerValidUntilDate, "America/Chicago")
          .format("MM/DD/YYYY")}
      </span>
    </Stack>
  )
}

export const GenerateOfferLineAPI = ({ str }) => {
  const parsed = useMemo(() => {
    return JSON.parse(str)
  }, [str])
  return (
    <>
      <Stack direction={"row"} spacing={1} flexWrap="wrap">
        <span>
          {parsed?.txt} : <b>{parsed?.offerData?.mappedStore}</b> for{" "}
          <b style={{ color: "green" }}>
            {formatPrice(parsed?.offerData?.finalOffer)}
          </b>{" "}
          Valid Until :{" "}
          {!!parsed?.offerData?.offerValidUntilDate
            ? moment
                .tz(parsed?.offerData?.offerValidUntilDate, "America/Chicago")
                .format("MM/DD/YYYY")
            : "N/A"}
        </span>
      </Stack>
      {!!parsed?.offerData?.offerMessage?.message && (
        <span>
          <b>Message:</b> {parsed?.offerData?.offerMessage?.message}
        </span>
      )}
    </>
  )
}

export const FollowUpLine = ({ str }) => {
  const parsed = useMemo(() => {
    return JSON.parse(str)
  }, [str])
  return (
    <Stack direction={"row"} spacing={1} flexWrap="wrap">
      <span>
        {parsed?.txt} for{" "}
        <b>{moment.tz(parsed?.date, "America/Chicago").format("MM/DD/YYYY")}</b>
      </span>
    </Stack>
  )
}

export const AppointmentCreated = ({ str }) => {
  const parsed = useMemo(() => {
    return JSON.parse(str)
  }, [str])
  return (
    <Stack direction={"row"} spacing={1} flexWrap="wrap">
      <span>{parsed?.txt} for:</span>
      <b>{parsed?.dealer}</b>

      <span style={{ display: "flex", alignItems: "center", color: "#444ce7" }}>
        <MdOutlineAccessTime fontSize={20} />
        <b style={{ marginLeft: 2 }}> {parsed?.start || parsed?.date}</b>
      </span>
    </Stack>
  )
}

export const BidsSentLine = ({ str }) => {
  const parsed = useMemo(() => {
    try {
      return JSON.parse(str)
    } catch (error) {
      return { txt: str }
    }
  }, [str])

  return (
    <Stack spacing={1}>
      <span>{parsed?.txt}</span>
      {parsed?.data && (
        <Stack>
          {parsed?.data?.map((item, i) => (
            <Stack
              key={item?.id + i}
              direction={"row"}
              spacing={1}
              flexWrap="wrap"
            >
              <DealershipsBox id={item?.dealer} />
              <span style={{}}>
                Offer Amount:{" "}
                {item?.from === item?.to ? (
                  <b style={{ color: "green" }}>{formatPrice(item?.from)}</b>
                ) : (
                  <>
                    From:{" "}
                    <b style={{ color: "red" }}>{formatPrice(item?.from)}</b>{" "}
                    &rarr; To:{" "}
                    <b style={{ color: "green" }}>{formatPrice(item?.to)}</b>
                  </>
                )}
              </span>
            </Stack>
          ))}
        </Stack>
      )}
    </Stack>
  )
}

export const LeadLostLine = ({ str }) => {
  const parsed = useMemo(() => {
    try {
      return JSON.parse(str)
    } catch (error) {
      return { txt: str }
    }
  }, [str])

  return (
    <Stack>
      <span>{parsed?.txt}</span>
      {parsed?.reason && (
        <span>
          <b>Lost Reason: </b>
          {parsed?.reason}
        </span>
      )}
    </Stack>
  )
}
