import * as React from "react"
import Table from "@mui/material/Table"
import TableBody from "@mui/material/TableBody"
import TableCell from "@mui/material/TableCell"
import TableContainer from "@mui/material/TableContainer"
import TableHead from "@mui/material/TableHead"
import TableRow from "@mui/material/TableRow"
import Paper from "@mui/material/Paper"
import VerifiedUserIcon from "@mui/icons-material/VerifiedUser"
import GppBadIcon from "@mui/icons-material/GppBad"
import { ButtonGroup, IconButton, Tooltip } from "@mui/material"
import EditIcon from "@mui/icons-material/Edit"
import axios from "axios"
import LockResetIcon from "@mui/icons-material/LockReset"
import { URL } from "../../config/apis"
import useData from "../../context/Data"
import { formatDate } from "../../helppers/formatters"
import ContentCopyIcon from "@mui/icons-material/ContentCopy"

const UserRow = ({
  user,
  isAdmin,
  handleSelect,
  isAllowed,
  notDeleted,
  handleCopyClick,
}) => {
  const { loading, setLoading, setShow, setErr } = useData()
  const resetPassword = async () => {
    if (!isAllowed || !notDeleted) return
    setErr(null)
    setLoading(true)
    setShow(false)
    try {
      const res = await axios.post(
        `${URL}/users/reset`,
        { email: user?.email },
        {
          headers: {
            "Content-Type": "application/json",
          },
          withCredentials: true,
        }
      )
      if (res.data.ok) {
      }
      setShow(true)
      setLoading(false)
      setTimeout(() => {
        setShow(false)
      }, 3000)
    } catch (error) {
      setLoading(false)
      setErr(error?.response?.data?.message || error.message)
      setTimeout(() => {
        setErr(null)
      }, 3000)
    }
  }
  return (
    <TableRow sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
      <TableCell component="th" scope="row">
        {user?.fname}{" "}
        <IconButton size="small" onClick={() => handleCopyClick(user?._id)}>
          <ContentCopyIcon />
        </IconButton>
      </TableCell>
      {isAllowed && (
        <>
          <TableCell align="left">{user?.email}</TableCell>
          <TableCell align="left">{user?.phone}</TableCell>
        </>
      )}
      <TableCell align="center">
        {user?.isOwner ? (
          <VerifiedUserIcon color="success" />
        ) : (
          <GppBadIcon color="error" />
        )}
      </TableCell>
      <TableCell align="center">
        {isAdmin ? (
          <VerifiedUserIcon color="success" />
        ) : (
          <GppBadIcon color="error" />
        )}
      </TableCell>
      <TableCell align="center">
        {formatDate(user?.lastLogin, "MM/DD/YY h:mm a")}
      </TableCell>
      <TableCell align="center">
        {user?.lastActive
          ? formatDate(user?.lastActive, "MM/DD/YY h:mm a")
          : "N/A"}
      </TableCell>
      {isAllowed && notDeleted && (
        <TableCell align="center">
          <ButtonGroup
            variant="outlined"
            aria-label="outlined primary button group"
          >
            <IconButton
              onClick={() => handleSelect(user)}
              color="primary"
              component="button"
              disabled={loading || !isAllowed}
            >
              <EditIcon />
            </IconButton>
            <Tooltip title="Reset Password" placement="top">
              <IconButton
                disabled={loading || !isAllowed}
                color="info"
                onClick={resetPassword}
                component="button"
              >
                <LockResetIcon />
              </IconButton>
            </Tooltip>
          </ButtonGroup>
        </TableCell>
      )}
    </TableRow>
  )
}

export default function UsersTable({
  users,
  setDealership,
  admins,
  setSelected,
  setOpen,
  isAllowed,
  notDeleted,
  handleCopyClick,
}) {
  const handleSelect = (user) => {
    setSelected(user)
    setOpen(true)
  }
  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>Name</TableCell>
            {isAllowed && (
              <>
                <TableCell align="left">Email</TableCell>
                <TableCell align="left">Phone</TableCell>
              </>
            )}
            <TableCell align="center">Is Owner</TableCell>
            <TableCell align="center">Is Admin</TableCell>
            <TableCell align="center">Last Login</TableCell>
            <TableCell align="center">Last Available</TableCell>
            {isAllowed && notDeleted && (
              <TableCell align="center">Actions</TableCell>
            )}
          </TableRow>
        </TableHead>
        <TableBody>
          {users &&
            users?.map((user) => (
              <UserRow
                user={user}
                key={user?._id}
                isAdmin={admins?.includes(user?._id)}
                handleSelect={handleSelect}
                isAllowed={isAllowed}
                notDeleted={notDeleted}
                handleCopyClick={handleCopyClick}
              />
            ))}
        </TableBody>
      </Table>
    </TableContainer>
  )
}
