export const checkRoles = (myRoles) => {
  if (myRoles?.length === 0) return "NONE"
  const res = myRoles
    ?.map((role) => ["manager", "admin", "super-admin"].includes(role))
    .find((val) => val === true)
  if (!res) return "agent"

  if (myRoles?.includes("super-admin")) {
    return "admin"
  }
  // if (myRoles?.includes("admin") || myRoles?.includes("super-admin")) {
  //   return "admin"
  // }
  if (myRoles.includes("manager")) {
    return "manager"
  }
}

export const isAllowed = (myRoles, roles) => {
  const rolesArray = [...roles]
  const result = myRoles
    ?.map((role) => rolesArray?.includes(role))
    .find((val) => val === true)

  if (!result) return false
  return true
}
