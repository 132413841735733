import { Button, MenuItem, Stack, TextField, Typography } from "@mui/material"
import React from "react"
import SelectMenu from "../createPage/SelectMenu"
import moment from "moment"
import useData from "../../context/Data"

export default function CreateReportBox({
  start,
  setStart,
  end,
  setEnd,
  type,
  setType,
  ongenerate,
  rangeData,
  loading,
  isdb = false,
  selected,
  setSelected,
}) {
  const { dealerships, orgs } = useData()
  const handleChangeStart = (value) => {
    if (new Date(value).getTime() > new Date(end).getTime()) {
      setStart(end)
    } else {
      setStart(value)
    }
  }
  const handleChangeEnd = (value) => {
    if (new Date(value).getTime() < new Date(start).getTime()) {
      setEnd(start)
    } else {
      setEnd(value)
    }
  }
  return (
    <Stack
      spacing={2}
      flex={2}
      p={2}
      bgcolor={"white"}
      maxHeight={"max-content"}
    >
      <Typography variant="h6">Create New Report</Typography>
      <Stack width={"100%"} spacing={2}>
        <Stack direction={"row"} alignItems={"center"} spacing={2}>
          <TextField
            type="date"
            label="Start Date"
            fullWidth
            value={start == null ? null : moment(start).format("YYYY-MM-DD")}
            onChange={({ target }) => {
              handleChangeStart(moment(target.value).format("MM/DD/YYYY"))
            }}
          />
          <TextField
            label="End Date"
            type="date"
            fullWidth
            value={end == null ? null : moment(end).format("YYYY-MM-DD")}
            onChange={({ target }) => {
              handleChangeEnd(moment(target.value).format("MM/DD/YYYY"))
            }}
          />
        </Stack>
        <SelectMenu
          label={"Report Type"}
          value={type}
          name={"type"}
          handleChange={(value) => {
            setType(value)
            if (typeof setSelected !== "undefined") {
              setSelected(null)
            }
          }}
          options={
            isdb
              ? [
                  "Dealerships",
                  //  "Organisations"
                ]
              : [
                  "Created",
                  "Created By Market",
                  "Claimed",
                  "closed",
                  "Sent",
                  "bids",
                  "Deals",
                ]
          }
        >
          {(isdb
            ? [
                "Dealerships",
                //  "Organisations"
              ]
            : [
                "Created",
                "Created By Market",
                "Claimed",
                "closed",
                "Sent",
                "bids",
                "Deals",
              ]
          )?.map((o, i) => (
            <MenuItem
              key={o + i}
              value={o}
              sx={{ textTransform: "capitalize" }}
            >
              {o}
            </MenuItem>
          ))}
        </SelectMenu>
        {typeof setSelected !== "undefined" && (
          <SelectMenu
            handleChange={(v) => {
              setSelected(v)
            }}
            label={"Dealership"}
            name={"dealership"}
            value={selected}
            options={type === "Dealerships" ? dealerships : orgs}
            sx={{ minWidth: 150 }}
          >
            {(type === "Dealerships" ? dealerships : orgs)?.map((d, i) => (
              <MenuItem key={d?._id + i} value={d?._id}>
                {d?.dname || d?.name}
              </MenuItem>
            ))}
          </SelectMenu>
        )}
        <Button onClick={ongenerate} disabled={loading} variant="contained">
          Generate
        </Button>
      </Stack>
    </Stack>
  )
}
