import * as React from "react"
import OutlinedInput from "@mui/material/OutlinedInput"
import InputLabel from "@mui/material/InputLabel"
import MenuItem from "@mui/material/MenuItem"
import FormControl from "@mui/material/FormControl"
import ListItemText from "@mui/material/ListItemText"
import Select from "@mui/material/Select"
import Checkbox from "@mui/material/Checkbox"

const ITEM_HEIGHT = 48
const ITEM_PADDING_TOP = 8
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
}

export default function MultiSelectInput({
  selected,
  setSelected,
  label,
  options,
  size = "small",
}) {
  const handleChange = (event) => {
    const {
      target: { value },
    } = event
    setSelected(typeof value === "string" ? value.split(",") : value)
  }

  return (
    <div>
      <FormControl fullWidth size={size}>
        <InputLabel id={`demo-multiple-checkbox-${label}`}>{label}</InputLabel>
        <Select
          labelId={`demo-multiple-checkbox-${label}`}
          id={`demo-multiple-checkbox-${label}`}
          multiple
          value={selected}
          onChange={handleChange}
          input={<OutlinedInput sx={{textTransform: 'uppercase'}} label={label} />}
          renderValue={(selected) => selected.join(", ")}
          MenuProps={MenuProps}
        >
          {options &&
            options.map((item, i) => (
              <MenuItem key={(item._id || item) + i} value={item?._id || item}>
                <Checkbox checked={selected?.indexOf(item?._id || item) > -1} />
                <ListItemText
                sx={{textTransform:'uppercase'}}
                  primary={item?._id ? `${item?._id} (${item?.total})` : item}
                />
              </MenuItem>
            ))}
        </Select>
      </FormControl>
    </div>
  )
}
