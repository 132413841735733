import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material"
import React from "react"

function Row({  }) {
  return (
    <React.Fragment>
      <TableRow sx={{ "& > *": { borderBottom: "unset" } }}>
        <TableCell component="th" scope="row">
          <div style={{height:15, width:250, backgroundColor:'#eeebeb'}} />
        </TableCell>
        <TableCell align="left">
          <div style={{height:15, width:100, backgroundColor:'#eeebeb'}} />
        </TableCell>
        <TableCell align="left">
          <div style={{height:15, width:80, backgroundColor:'#eeebeb'}} />
        </TableCell>
        <TableCell align="left">
          <div style={{height:15, width:120, backgroundColor:'#eeebeb'}} />
        </TableCell>
        <TableCell align="center">
          <div style={{height:15, width:70, backgroundColor:'#eeebeb'}} />
        </TableCell>
        <TableCell align="center">
          <div style={{height:15, width:60, backgroundColor:'#eeebeb'}} />
        </TableCell>
      </TableRow>
    </React.Fragment>
  )
}

export default function SleletonTable() {
  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow sx={{ fontWeight: 500 }}>
            <TableCell>
              <div style={{height:10, width:100, backgroundColor:'#eeebeb'}} />
            </TableCell>
            <TableCell align="left">
              <div style={{height:10, width:100, backgroundColor:'#eeebeb'}} />
            </TableCell>
            <TableCell align="left">
              <div style={{height:10, width:100, backgroundColor:'#eeebeb'}} />
            </TableCell>
            <TableCell align="left">
              <div style={{height:10, width:100, backgroundColor:'#eeebeb'}} />
            </TableCell>
            <TableCell align="center">
              <div style={{height:10, width:100, backgroundColor:'#eeebeb'}} />
            </TableCell>
            <TableCell align="center">
              <div style={{height:10, width:100, backgroundColor:'#eeebeb'}} />
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {[...Array(25)].map((_, i) => (
            <Row key={i} />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  )
}
