import { Grid, Stack } from "@mui/material"
import React from "react"

export default function DataLine({ title, value }) {
  return (
    <Grid item sm={2}>
      <Stack>
        <strong>{title}</strong>
        <span>{value}</span>
      </Stack>
    </Grid>
  )
}
