import React from "react"
import Grid from "@mui/material/Grid"
import Box from "@mui/material/Box"
import Skeleton from "@mui/material/Skeleton"
import Container from "@mui/material/Container"
import { Stack, Typography } from "@mui/material"

const CardScketon = () => {
  return (
    <Grid item xs={6} md={4} xl={3}>
      <Skeleton variant="rectangular" width={"100%"} height={250} />
      <Box sx={{ pt: 0.5 }}>
        <Typography variant="h4">
          {" "}
          <Skeleton width="90%" />
        </Typography>
        <Stack direction={"row"} justifyContent="space-between" spacing={3}>
          <Skeleton width="60%" />
          <Skeleton width="40%" />
        </Stack>
        <Stack direction={"row"} justifyContent="space-between" spacing={3}>
          <Typography width="40%" variant="h5">
            <Skeleton width="100%" />
          </Typography>

          <Skeleton width="40%" />
          <Skeleton width={10} />
        </Stack>
        <Typography
          display={"flex"}
          justifyContent={"center"}
          textAlign={"center"}
          width="100%"
          variant="h3"
        >
          <Skeleton width="98%" />
        </Typography>
      </Box>
    </Grid>
  )
}

export default function SkeletonCard() {
  return (
    <Container maxWidth="100%">
      <Grid spacing={5} container>
        {Array.from(new Array(15)).map((_, i) => (
          <CardScketon key={i} />
        ))}
      </Grid>
    </Container>
  )
}
