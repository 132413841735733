import Stack from "@mui/material/Stack"
import React from "react"
import MenuItem from "@mui/material/MenuItem"
import { VscSettings } from "react-icons/vsc"
import useData from "../../context/Data"
import SelectMenu from "../createPage/SelectMenu"
import { IconButton, Typography } from "@mui/material"
import useAuth from "../../context/Auth"

export default function LeadsFilters({
  market,
  setMarket,
  agent,
  setAgent,
  setPage,
  count,
  claimer,
  setClaimer,
  dealership,
  setDealership,
  isDealer = false,
  isAgent = true,
  sent = false,
  openFilters,
  phoneStatus,
  setPhoneStatus,
  setOptin,
  optin,
  src,
  setSrc,
}) {
  const { user } = useAuth()

  const { agents, markets, dealerships } = useData()

  return (
    <Stack
      direction={"row"}
      justifyContent="space-between"
      sx={{ mb: 2 }}
      spacing={2}
    >
      <Stack direction={"row"} spacing={2}>
        {isAgent && (
          <SelectMenu
            handleChange={(v) => {
              setAgent(v)
              setPage(1)
            }}
            label={"Agents"}
            name={"agent"}
            value={agent}
            options={agents?.filter((a) => a?.isActive)}
            sx={{ minWidth: 150 }}
          >
            <MenuItem value="All">
              <em>All</em>
            </MenuItem>
            {agents &&
              agents
                ?.filter((a) => a?.isActive)
                ?.map((u, i) => (
                  <MenuItem key={u?._id + i} value={u?._id}>
                    {u?.fname}
                  </MenuItem>
                ))}
          </SelectMenu>
        )}
        <SelectMenu
          handleChange={(v) => {
            setClaimer(v)
            setPage(1)
          }}
          label={"Claimer"}
          name={"claimer"}
          value={claimer}
          options={agents?.filter((a) => a?.isActive)}
          sx={{ minWidth: 150 }}
        >
          <MenuItem value="All">
            <em>All</em>
          </MenuItem>
          <MenuItem value="Unclaimed">
            <em>Unclaimed</em>
          </MenuItem>
          <MenuItem
            style={{
              color: "#be0b30",
              fontWeight: 700,
            }}
            value={user?._id}
          >
            <em>Mine</em>
          </MenuItem>
          {agents &&
            agents
              ?.filter((a) => a?._id !== user?._id)
              ?.filter((a) => a?.isActive)
              ?.map((u, i) => (
                <MenuItem key={u?._id + i} value={u?._id}>
                  {u?.fname}
                </MenuItem>
              ))}
        </SelectMenu>
        <SelectMenu
          handleChange={(v) => {
            setMarket(v)
            setPage(1)
          }}
          label={"Markets"}
          name={"matket"}
          value={market}
          options={markets}
          sx={{ minWidth: 150 }}
        >
          <MenuItem value="All">
            <em>All</em>
          </MenuItem>
          {markets &&
            markets?.map((u, i) => (
              <MenuItem key={u?._id + i} value={u?._id}>
                {u?.name}
              </MenuItem>
            ))}
        </SelectMenu>
        {isDealer && (
          <SelectMenu
            handleChange={(v) => {
              setDealership(v)
              setPage(1)
            }}
            label={"Dealership"}
            name={"dealership"}
            value={dealership}
            options={dealerships}
            sx={{ minWidth: 150 }}
          >
            <MenuItem value="All">
              <em>All</em>
            </MenuItem>
            {sent && (
              <MenuItem value="empty">
                <em>Not Sent to Dealerships</em>
              </MenuItem>
            )}
            {dealerships &&
              dealerships?.map((d, i) => (
                <MenuItem key={d?._id + i} value={d?._id}>
                  {d?.dname}
                </MenuItem>
              ))}
          </SelectMenu>
        )}
        {typeof setOptin !== "undefined" && (
          <SelectMenu
            handleChange={(v) => {
              setOptin(v)
              setPage(1)
            }}
            label={"Opt-In Status"}
            name={"optin"}
            value={optin}
            options={["All", "YES", "NO"]}
            sx={{ minWidth: 150 }}
          >
            {["All", "YES", "NO"]?.map((item, i) => (
              <MenuItem key={item + i} value={item}>
                {item}
              </MenuItem>
            ))}
          </SelectMenu>
        )}
        {typeof setPhoneStatus !== "undefined" && (
          <SelectMenu
            handleChange={(v) => {
              setPhoneStatus(v)
              setPage(1)
            }}
            label={"Phone Status"}
            name={"phoneStatus"}
            value={phoneStatus}
            options={["All", "With Phone", "Without Phone"]}
            sx={{ minWidth: 150 }}
          >
            {["All", "With Phone", "Without Phone"]?.map((item, i) => (
              <MenuItem key={item + i} value={item}>
                {item}
              </MenuItem>
            ))}
          </SelectMenu>
        )}
        {typeof setSrc !== "undefined" && (
          <SelectMenu
            handleChange={(v) => {
              setSrc(v)
              setPage(1)
            }}
            label={"Sources"}
            name={"Sources"}
            value={src}
            options={["All", "Agents", "Craigslist"]}
            sx={{ minWidth: 150 }}
          >
            {["All", "Agents", "Craigslist"]?.map((item, i) => (
              <MenuItem key={item + i} value={item}>
                {item === "Craigslist" ? "CL Scraper" : item}
              </MenuItem>
            ))}
          </SelectMenu>
        )}
      </Stack>
      <Stack direction={"row"} spacing={2} alignItems={"center"}>
        {typeof openFilters !== "undefined" && (
          <IconButton color="primary" onClick={openFilters}>
            <VscSettings />
          </IconButton>
        )}
        <Typography variant="h6">{count}</Typography>
      </Stack>
    </Stack>
  )
}
