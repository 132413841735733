import { Avatar } from "@mui/material"
import React from "react"

export default function ProfileImage({ user }) {
  return (
    <div>
      <Avatar
        alt={user?.fname}
        src={user?.avatar?.url}
        sx={{ width: 100, height: 100 }}
      />
    </div>
  )
}
