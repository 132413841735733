import * as React from "react"
import Box from "@mui/material/Box"
import Card from "@mui/material/Card"
import CardActions from "@mui/material/CardActions"
import CardContent from "@mui/material/CardContent"
import Button from "@mui/material/Button"
import Typography from "@mui/material/Typography"
import { Avatar, Stack, Tooltip } from "@mui/material"
import { Link } from "react-router-dom"
import useAuth from "../../context/Auth"
import { isAllowed } from "../../helppers/checkRoles"
import moment from "moment-timezone"

const bull = (
  <Box
    component="span"
    sx={{ display: "inline-block", mx: "2px", transform: "scale(0.8)" }}
  >
    •
  </Box>
)

function stringToColor(string) {
  let hash = 0
  let i

  /* eslint-disable no-bitwise */
  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash)
  }

  let color = "#"

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff
    color += `00${value.toString(16)}`.slice(-2)
  }
  /* eslint-enable no-bitwise */

  return color
}

function stringAvatar(fname, lname) {
  return {
    sx: {
      bgcolor: stringToColor(`${fname} ${lname}`),
    },
    children: `${fname[0]}${lname[0]}`,
  }
}

export default function AgentCard({ agent }) {
  const { user } = useAuth()
  return (
    <Box sx={{ minWidth: 275, opacity: agent?.isActive ? 1 : 0.5 }}>
      <Card variant="outlined">
        <CardContent>
          <Stack direction={"row"} alignItems="center" spacing={2}>
            <Avatar
              src={agent?.avatar?.url}
              {...stringAvatar(agent?.f_name, agent?.l_name)}
            />
            <Stack>
              <Tooltip title={agent?.fname} placement="top">
                <Typography
                  variant="h6"
                  component="div"
                  sx={{
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                  }}
                >
                  {agent?.fname}{" "}
                  <small>{!agent?.isActive && " (disabled)"}</small>
                </Typography>
              </Tooltip>
              <Typography color="text.secondary">{agent?.email}</Typography>
              {isAllowed(user?.role, ["super-admin"]) && agent?.lastAction && (
                <Typography variant="subtitle2" color="text.secondary">
                  {moment
                    .tz(agent?.lastAction, "America/Chicago")
                    .format("MM/DD/YYYY h:mm A")}
                </Typography>
              )}

              {isAllowed(user?.role, ["super-admin"]) && (
                <Typography variant="caption" color="text.secondary">
                  {agent?.team ? `${agent?.team?.name} Team` : "Admins"}
                </Typography>
              )}
            </Stack>
          </Stack>
        </CardContent>
        <CardActions>
          <Button size="small">
            <Link
              to={`/users/profile/${agent?._id}`}
              style={{ textDecoration: "none", color: "inherit" }}
            >
              View Profile
            </Link>
          </Button>
        </CardActions>
      </Card>
    </Box>
  )
}
