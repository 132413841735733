import {
  Alert,
  Button,
  Chip,
  Divider,
  Paper,
  Snackbar,
  Stack,
  Typography,
} from "@mui/material"
import React from "react"
import ProfileImage from "./ProfileImage"
import useAuth from "../../context/Auth"
import { isAllowed } from "../../helppers/checkRoles"
import { Link } from "react-router-dom"
import ConfirmModal from "./ConfirmModal"
import { URL } from "../../config/apis"
import axios from "axios"

export default function UserDetails({ user, open, setOpen, setState }) {
  const { user: c_user } = useAuth()
  const [isOpen, setIsOpen] = React.useState(false)

  const [loading, setLoading] = React.useState(false)
  const [err, setErr] = React.useState(null)
  const [success, setSuccess] = React.useState(null)

  const deleteAgent = async () => {
    if (!isAllowed(c_user?.role, ["super-admin"])) return
    if (user?._id === c_user?._id) return

    setErr(null)
    setLoading(true)
    setSuccess(null)
    try {
      const res = await axios.delete(`${URL}/agents/delete/${user?._id}`, {
        headers: {
          "Content-Type": "application/json",
        },
        withCredentials: true,
      })
      if (res.data.ok) {
        setState((prev) => ({
          ...prev,
          isActive: false,
          dateDeleted: new Date(),
        }))
      }
      setSuccess("Agent Deleted")
      setLoading(false)
      setIsOpen(false)
      setTimeout(() => {
        setSuccess(null)
      }, 3000)
    } catch (error) {
      setLoading(false)
      setErr(error?.response?.data?.message || error.message)
      setTimeout(() => {
        setErr(null)
      }, 3000)
    }
  }

  return (
    <>
      <Paper
        component={Stack}
        sx={{ maxHeight: "max-content" }}
        //flex={{ xs: 1, lg: 2 }}
      >
        <Stack alignItems={"center"} py={3} px={2} spacing={2}>
          {/* image */}

          <ProfileImage user={user} />
          <Divider flexItem />
          <Stack alignItems={"center"} spacing={1}>
            {/* full name */}
            <Typography variant="h5" fontWeight={"600"}>
              {user?.fname}
            </Typography>
            {/* email */}
            <Typography variant="body1">{user?.email}</Typography>
            {/* phone */}
            <Typography variant="body1">{`Slack ID: ${user?.slackId}`}</Typography>
          </Stack>
          <Divider flexItem />
          {/* dealerships */}
          <Stack
            direction={"row"}
            justifyContent="center"
            flexWrap="wrap"
            gap={2}
            sx={{ width: "100%" }}
          >
            {user?.role &&
              user?.role?.map((r) => (
                <Chip
                  sx={{ borderRadius: 1, fontWeight: "500" }}
                  key={r}
                  color={"default"}
                  label={r?.toUpperCase()}
                  size="medium"
                  variant="outlined"
                />
              ))}
          </Stack>
          <Divider flexItem />
          <Stack
            direction={"row"}
            justifyContent="center"
            flexWrap="wrap"
            gap={2}
            sx={{ width: "100%" }}
            alignItems={"center"}
          >
            {isAllowed(c_user?.role, ["super-admin", "admin", "manager"]) &&
            user?._id !== c_user?._id ? (
              <>
                <Button
                  onClick={() => setOpen((prev) => !prev)}
                  color={open ? "error" : "primary"}
                  variant="contained"
                >
                  {open ? "Close" : "Edit"}
                </Button>
              </>
            ) : user?._id === c_user?._id ? (
              <Link
                to="/profile"
                style={{ color: "inherit", textDecoration: "none" }}
              >
                <Button color={"primary"} variant="contained">
                  View My Profile
                </Button>
              </Link>
            ) : (
              <Typography></Typography>
            )}
            {isAllowed(c_user?.role, ["super-admin"]) &&
              user?._id !== c_user?._id && (
                <>
                  {user?.isActive ? (
                    <Button
                      onClick={() => setIsOpen(true)}
                      color={"error"}
                      variant="contained"
                    >
                      Delete
                    </Button>
                  ) : (
                    <Chip
                      sx={{ borderRadius: 1, fontWeight: "500", py: 2.2 }}
                      color={"error"}
                      label={"Deleted"}
                      size="medium"
                      variant="filled"
                    />
                  )}
                </>
              )}
          </Stack>
        </Stack>
      </Paper>
      <ConfirmModal
        open={isOpen}
        setOpen={setIsOpen}
        handleDisable={deleteAgent}
        loading={loading}
      />
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={success !== null}
        autoHideDuration={2000}
      >
        <Alert severity="success" sx={{ width: "100%" }}>
          {success}
        </Alert>
      </Snackbar>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={err !== null}
        autoHideDuration={3000}
      >
        <Alert severity="error" sx={{ width: "100%" }}>
          {err}
        </Alert>
      </Snackbar>
    </>
  )
}
