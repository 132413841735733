import * as React from "react"
import Card from "@mui/material/Card"
import CardActions from "@mui/material/CardActions"
import CardContent from "@mui/material/CardContent"
import CardMedia from "@mui/material/CardMedia"
import Button from "@mui/material/Button"
import Typography from "@mui/material/Typography"
import moment from "moment"

import IconButton from "@mui/material/IconButton"
import Menu from "@mui/material/Menu"
import MenuItem from "@mui/material/MenuItem"
import MoreVertIcon from "@mui/icons-material/MoreVert"
import Box from "@mui/material/Box"
import Grid from "@mui/material/Grid"
import BorderColorIcon from "@mui/icons-material/BorderColor"
import CancelIcon from "@mui/icons-material/Cancel"
import ListItemIcon from "@mui/material/ListItemIcon"
import DeleteIcon from "@mui/icons-material/Delete"
import { Link, useNavigate } from "react-router-dom"
import { BiBarcode } from "react-icons/bi"
import { BsSpeedometer2 } from "react-icons/bs"
import { Chip, Stack } from "@mui/material"
import { formatPrice } from "../../helppers/formatters"
import { markLostSold, moveTrash } from "../../helppers/leadActions"
import { LazyLoadImage } from "react-lazy-load-image-component"

export default function ImgMediaCard({
  lead,
  setLeads,
  setLoading,
  setShow,
  setErr,
  getLeads,
}) {
  const navigate = useNavigate()

  const [anchorEl, setAnchorEl] = React.useState(null)
  const open = Boolean(anchorEl)
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  return (
    <Card variant="outlined">
      <CardMedia sx={{ position: "relative" }}>
        <LazyLoadImage
          src={lead?.f_image?.url}
          width="100%"
          style={{ objectFit: "cover", objectPosition: "center" }}
          height="250px"
          alt={lead?.title}
          placeholderSrc="/placeholder.png"
        />
        <Chip
          sx={{
            position: "absolute",
            bottom: 0,
            right: 0,
            borderRadius: 0,
            fontSize: "12px",
          }}
          label={moment(lead?.createdAt).format("MM/DD/YY @ h:mm A")}
          color="primary"
        />
        {!!lead?.leadSource && (
          <Chip
            sx={{
              position: "absolute",
              bottom: 0,
              left: 0,
              borderRadius: 0,
              fontSize: "12px",
            }}
            label={lead?.leadSource}
            color="success"
          />
        )}
      </CardMedia>
      <CardContent>
        <Typography
          sx={{
            overflow: "hidden",
            whiteSpace: "nowrap",
            textOverflow: "ellipsis",
          }}
          gutterBottom
          variant="h6"
          component="div"
        >
          {lead?.title}
        </Typography>
        <Stack direction={"row"} alignItems="center" spacing={3}>
          <Stack direction={"row"} alignItems="center" spacing={2}>
            <Typography
              display="flex"
              alignItems="center"
              gap={1}
              variant="body2"
              color="text.secondary"
            >
              {/* VRM: WV20 FXD */}
              <BiBarcode fontSize={20} />
              {lead?.vin}
            </Typography>
          </Stack>
          <Stack direction={"row"} alignItems="center" spacing={2}>
            <Typography
              display="flex"
              alignItems="center"
              gap={1}
              variant="body2"
              color="text.secondary"
            >
              <BsSpeedometer2 fontSize={20} />
              {lead?.mileage?.toLocaleString()} mi
            </Typography>
          </Stack>
        </Stack>
      </CardContent>
      <CardContent>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            width: "100%",
          }}
        >
          <Typography variant="h6" color="text.secondary">
            {formatPrice(lead?.listedPrice)}
          </Typography>

          <Chip label={lead?.market?.name} sx={{ px: 0.5, fontWeight: 600 }} />

          <IconButton
            onClick={handleClick}
            size="small"
            sx={{ ml: 2 }}
            aria-controls={open ? "account-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
          >
            <MoreVertIcon />
          </IconButton>
        </Box>
      </CardContent>
      <CardActions>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            width: "100%",
          }}
        >
          <Button
            size="large"
            fullWidth
            variant="outlined"
            to={`/leads/item/${lead?._id}`}
            LinkComponent={Link}
          >
            View this lead
          </Button>
        </Box>
      </CardActions>
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            mt: 1.5,
            "& .MuiAvatar-root": {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            "&:before": {
              content: '""',
              display: "block",
              position: "absolute",
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: "background.paper",
              transform: "translateY(-50%) rotate(45deg)",
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: "right", vertical: "center" }}
        anchorOrigin={{ horizontal: "left", vertical: "center" }}
      >
        <MenuItem onClick={() => navigate(`/leads/edit/${lead?._id}`)}>
          <ListItemIcon>
            <BorderColorIcon fontSize="small" />
          </ListItemIcon>
          Edit
        </MenuItem>
        {/* <MenuItem
          onClick={() =>
            markLostSold(
              lead?._id,
              setLeads,
              setErr,
              setLoading,
              setShow,
              getLeads
            )
          }
        >
          <ListItemIcon>
            <CancelIcon fontSize="small" />
          </ListItemIcon>
          Lost/Sold
        </MenuItem> */}
        {/* <MenuItem
          onClick={() =>
            moveTrash(
              lead?._id,
              setLeads,
              setErr,
              setLoading,
              setShow,
              getLeads
            )
          }
        >
          <ListItemIcon>
            <DeleteIcon fontSize="small" />
          </ListItemIcon>
          Trash
        </MenuItem> */}
      </Menu>
    </Card>
  )
}
