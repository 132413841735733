import * as React from "react"
import Grid from "@mui/material/Grid"
import Chip from "@mui/material/Chip"
import PaperWrrapper from "./PaperWrrapper"
import { Stack } from "@mui/material"

export default function CarFaxBox({ data, isClean }) {
  return (
    <PaperWrrapper>
      <Grid item>
        <Stack direction={"row"} gap={2} flexWrap="wrap">
          <Chip
            label={isClean ? "Clean" : "Not Clean"}
            color={isClean ? "success" : "error"}
            sx={{ px: 0.5, py: 0.5 }}
          />
          {/* <Chip
            label={`Accident Reported: ${data?.accidents}`}
            color={data?.accidents > 0 ? "error" : "default"}
            sx={{ px: 0.5, py: 0.5 }}
          /> */}
          <Chip
            label={`Accident Reported`}
            color={data?.accedent === true ? "error" : "default"}
            sx={{ px: 0.5, py: 0.5 }}
          />
          <Chip
            label={`Minor`}
            color={data?.minor === true ? "error" : "default"}
            sx={{ px: 0.5, py: 0.5 }}
          />
          <Chip
            label={`Moderate`}
            color={data?.moderate === true ? "error" : "default"}
            sx={{ px: 0.5, py: 0.5 }}
          />
          <Chip
            label={`Severe`}
            color={data?.severe === true ? "error" : "default"}
            sx={{ px: 0.5, py: 0.5 }}
          />
          {/* <Chip
            label={`Branded Title Check: ${
              data?.branded === true ? "Branded" : "Not Branded"
            }`}
            color={data?.branded === true ? "error" : "success"}
            sx={{ px: 0.5, py: 0.5 }}
          /> */}
          {/* <Chip
            label={`Airbag Deployed: ${data?.issues?.airbag}`}
            color={data?.issues?.airbag > 0 ? "error" : "default"}
            sx={{ px: 0.5, py: 0.5 }}
          /> */}
          {/* <Chip
            label={`Structural Damage: ${data?.issues?.structural_damage}`}
            color={data?.issues?.structural_damage > 0 ? "error" : "default"}
            sx={{ px: 0.5, py: 0.5 }}
          /> */}
          {/* <Chip
            label={`Total Loss: ${data?.issues?.total}`}
            color={data?.issues?.total > 0 ? "error" : "default"}
            sx={{ px: 0.5, py: 0.5 }}
          /> */}
          {/* <Chip
            label={`Previous Owners: ${data?.previous_owners}`}
            sx={{ px: 0.5, py: 0.5 }}
          />
          <Chip
            label={`Last Recorded Odometer: ${data?.odometer_last?.toLocaleString()}`}
            sx={{ px: 0.5, py: 0.5 }}
          /> */}
        </Stack>
      </Grid>
    </PaperWrrapper>
  )
}
