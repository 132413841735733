import {
  Box,
  Button,
  Container,
  Grid,
  MenuItem,
  Stack,
  Typography,
} from "@mui/material"
import axios from "axios"
import React, { useEffect } from "react"
import { useState } from "react"
import AgentCard from "../components/agent/AgentCard"
import SelectMenu from "../components/createPage/SelectMenu"
import PopUpDialogCreateAgent from "../components/modals/PopUpDialogCreateAgent"
import { URL } from "../config/apis"
import useAuth from "../context/Auth"
import useData from "../context/Data"
import { isAllowed } from "../helppers/checkRoles"

export default function AgentsPage() {
  const { user } = useAuth()
  const { teams } = useData()

  const [agents, setAgents] = useState([])
  const [fetching, setFetching] = useState(false)
  const [loading, setLoading] = useState(true)
  const [err, setErr] = useState(null)
  const [selected, setSelected] = useState("All")

  const [isLoading, setIsLoading] = useState(false)
  const [show, setShow] = useState(false)
  const [open, setOpen] = useState(false)
  const [error, setError] = useState(null)

  const getAgents = async () => {
    setFetching(true)
    try {
      const res = await axios.get(
        `${URL}/agents?team=${selected}&page=agents`,
        {
          headers: {
            "Content-Type": "application/json",
          },
          withCredentials: true,
        }
      )
      setAgents(res.data)
      setLoading(false)
      setFetching(false)
    } catch (error) {
      setLoading(false)
      setFetching(false)
      setErr(error?.response?.data?.message)
    }
  }

  useEffect(() => {
    getAgents()
  }, [selected])

  if (loading) {
    return <>Loading...</>
  }

  if (!loading && err) {
    return <>{err}</>
  }

  return (
    <>
      <Container maxWidth="100%">
        <Box>
          <Stack
            direction={"row"}
            spacing={5}
            alignItems="center"
            justifyContent={"space-between"}
          >
            <Typography variant="h6">Users</Typography>
            <Stack direction={"row"} spacing={3} alignItems="center">
              {isAllowed(user?.role, ["super-admin"]) && (
                <SelectMenu
                  handleChange={(v) => setSelected(v)}
                  label={"Teams"}
                  name={"teams"}
                  value={selected}
                  options={teams}
                  sx={{ minWidth: 150 }}
                >
                  <MenuItem value="All">
                    <em>All</em>
                  </MenuItem>
                  <MenuItem value="Admins">
                    <em>Admins</em>
                  </MenuItem>

                  {teams &&
                    teams?.map((team) => (
                      <MenuItem key={team?._id} value={team?._id}>
                        {team?.name}
                      </MenuItem>
                    ))}
                </SelectMenu>
              )}
              {isAllowed(user?.role, ["super-admin", "admin", "manager"]) && (
                <Button
                  onClick={() => setOpen(true)}
                  variant="contained"
                  disabled={
                    !isAllowed(user?.role, ["super-admin", "admin", "manager"])
                  }
                >
                  New User
                </Button>
              )}
            </Stack>
          </Stack>
          {agents?.length === 0 ? (
            <Stack
              sx={{ py: 3, px: 5, minHeight: "500px" }}
              justifyContent={"center"}
              alignItems="center"
              spacing={2}
            >
              <Typography color={"gray"} variant="h5">
                We don't have any Agents
              </Typography>
              {isAllowed(user?.role, ["super-admin", "admin", "manager"]) && (
                <Stack
                  alignItems={"center"}
                  justifyContent="center"
                  direction={"row"}
                  spacing={2}
                >
                  <Button
                    disabled={
                      !isAllowed(user?.role, [
                        "super-admin",
                        "admin",
                        "manager",
                      ])
                    }
                    onClick={() => setOpen(true)}
                    variant="contained"
                  >
                    Create New User
                  </Button>
                </Stack>
              )}
            </Stack>
          ) : (
            <Grid py={3} container spacing={3}>
              {agents?.map((d, i) => (
                <Grid key={d?._id + i} xs={6} md={4} xl={3} item>
                  <AgentCard agent={d} />
                </Grid>
              ))}
            </Grid>
          )}
        </Box>
      </Container>
      <PopUpDialogCreateAgent
        setErr={setError}
        loading={isLoading}
        open={open}
        setLoading={setIsLoading}
        setOpen={setOpen}
        setShow={setShow}
        setState={setAgents}
      />
    </>
  )
}
