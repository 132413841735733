import React from "react"
import Checkbox from "@mui/material/Checkbox"
import RadioGroup from "@mui/material/RadioGroup"
import FormControlLabel from "@mui/material/FormControlLabel"
import FormControl from "@mui/material/FormControl"
import FormLabel from "@mui/material/FormLabel"
import { TextField } from "@mui/material"

export default function ResponseClarification({
  question,
  category,
  questionId,
  handleChangeResp,
  repId,
  handleChangeText,
}) {
  return (
    <FormControl sx={{ px: 5 }}>
      <FormLabel id={question?.id}>{question?.text}</FormLabel>
      <RadioGroup aria-labelledby={question?.id} name={question?.text}>
        {question?.responseOptions &&
          question?.responseOptions?.map((rep, i) => (
            <>
              <FormControlLabel
                value={rep.id}
                control={<Checkbox />}
                label={rep?.text}
                key={rep?.id + i}
                onChange={(e, checked) =>
                  handleChangeResp(rep.id, category, checked, repId)
                }
                checked={rep?.isSelected}
              />
              {rep?.allowFreeText && rep?.isSelected && (
                <TextField
                  size="small"
                  label="Explain"
                  value={
                    rep?.freeTextType === "String" ? "" : rep?.freeTextType
                  }
                  onChange={(e) =>
                    handleChangeText(
                      e.target.value,
                      rep,
                      category,
                      questionId,
                      repId
                    )
                  }
                />
              )}
            </>
          ))}
      </RadioGroup>
    </FormControl>
  )
}
