import axios from "axios"
import { URL } from "../../config/apis"

export const importListingData = async (
  vin,
  setState,
  setLoading,
  setData,
  setError
) => {
  if (!vin || vin?.length !== 17) {
    setError("VIN not valid")
    return setTimeout(() => {
      setError(null)
    }, 2000)
  }
  setData(null)
  setError(null)
  try {
    setLoading(true)
    const { data } = await axios.get(`${URL}/leads/import/${vin}`, {
      headers: {
        "Content-Type": "application/json",
      },
      withCredentials: true,
    })
    const d = data.listing

    setData(d)
    setState((prev) => ({
      ...prev,
      ...d,
      transmission: {
        short: d?.transmission,
        full: d?.transmission,
      },
      engine: {
        short: d?.engine,
        full: d?.engine,
      },
    }))
    setLoading(false)
  } catch (error) {
    console.log(error)

    if (error.response) {
      setError(error.response?.data?.message)
    } else {
      setError(error.message)
    }
    setLoading(false)
  }
}
