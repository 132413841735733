import * as React from "react"
import Dialog from "@mui/material/Dialog"
import DialogContent from "@mui/material/DialogContent"

export default function SimpleModal({
  open,
  setOpen,
  setTitle,
  children,
  isBack = false,
  ...props
}) {
  const handleClose = (e, reason) => {
    if (isBack) {
      if (isBack && reason !== "backdropClick") {
        setOpen(false)
        return
      }
      return
    }
    setOpen(false)
    setTitle(null)
  }

  const handleBackdropClick = (event) => {
    if (isBack) {
      event.stopPropagation()
      return false
    }
    return true
  }

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      onBackdropClick={handleBackdropClick}
      disableEscapeKeyDown={isBack}
      {...props}
    >
      <DialogContent>{children}</DialogContent>
    </Dialog>
  )
}
