import React, { useState } from "react"
import moment from "moment"
import ReportContainer from "../components/reports/ReportContainer"
import axios from "axios"
import { URL } from "../config/apis"
import DealerReportBox from "../components/reports/dealer-report/DealerReportBox"

const setLocalZone = (date) => {
  return moment(date).format("MM/DD/YYYY")
}

function DealersReportsPage() {
  const [repType, setRepType] = React.useState("Dealerships")
  const [start, setStart] = React.useState(setLocalZone(new Date()))
  const [end, setEnd] = React.useState(setLocalZone(new Date()))
  const [results, setResults] = React.useState(null)
  const [rangeData, setRangeData] = React.useState(null)
  const [selected, setSelected] = React.useState(null)
  const [loading, setLoading] = useState(false)

  const ongenerate = async () => {
    if (!start || !end || !repType || !selected) return
    setResults(null)
    setLoading(true)
    try {
      const res = await axios.get(
        `${URL}/${repType}/report/${selected}?start=${start}&end=${end}`,
        {
          headers: {
            "Content-Type": "application/json",
          },
          withCredentials: true,
        }
      )
      setResults(repType === "Dealerships" ? [res.data.data] : res.data.data)
      setRangeData(res.data.range)
    } catch (error) {
      console.log(error)
    } finally {
      setLoading(false)
    }
  }

  return (
    <DealerReportBox
      start={start}
      end={end}
      setStart={setStart}
      setEnd={setEnd}
      type={repType}
      setType={setRepType}
      results={results}
      loading={loading}
      rangeData={rangeData}
      ongenerate={ongenerate}
      selected={selected}
      setSelected={setSelected}
    />
  )
}

export default DealersReportsPage
