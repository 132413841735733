import * as React from "react"
import Button from "@mui/material/Button"
import TextField from "@mui/material/TextField"
import Dialog from "@mui/material/Dialog"
import DialogActions from "@mui/material/DialogActions"
import DialogContent from "@mui/material/DialogContent"
import DialogTitle from "@mui/material/DialogTitle"
import { Chip, Stack } from "@mui/material"
import { URL } from "../../config/apis"
import axios from "axios"
import { timePicker } from "../../constants/timePicker"
import { selectTime } from "../../helppers/selectTime"
import moment from "moment"
import momentTZ from "moment-timezone"
import useData from "../../context/Data"
import { PricesInputSimple } from "../createPage/PricesInput"

export default function UpdateAppointment({
  open,
  setOpen,
  setActivity,
  setAppointmnets,
  app,
  lead,
}) {
  const { loading, setLoading, setErr, setShow } = useData()
  const [agreedPrice, setAgreedPrice] = React.useState(lead?.agreedPrice)
  const [date, setDate] = React.useState(
    momentTZ.tz(app?.start, app?.tz).format("YYYY-MM-DD")
  )
  const [time, setTime] = React.useState(
    momentTZ.tz(app?.start, app?.tz).format("H:mm")
  )

  const handleClose = (e, reason) => {
    if (reason !== "backdropClick") {
      setOpen(false)
    }
  }

  const handleBackdropClick = (event) => {
    event.stopPropagation()
    return false
  }

  const submitUpdate = async () => {
    if (!date || !time) {
      setErr("Please Select Date & time")
      return setTimeout(() => {
        setErr(null)
      }, 3000)
    }
    const data = {
      start: selectTime(app?.tz, date, time),
      status: "Pending",
      agreedPrice
    }

    setErr(null)
    setLoading(true)
    setShow(false)

    try {
      const res = await axios.put(
        `${URL}/calendar/appointment/${app?._id}`,
        data,
        {
          headers: {
            "Content-Type": "application/json",
          },
          withCredentials: true,
        }
      )
      if (res.data.ok) {
        setActivity((prev) => [res.data.activity, ...prev])
        setAppointmnets((prev) =>
          prev?.map((a) => {
            if (a?._id === app?._id) {
              return { ...a, start: data?.start }
            } else {
              return a
            }
          })
        )
      }
      setShow(true)
      setLoading(false)
      setOpen(false)
      setTimeout(() => {
        setShow(false)
      }, 3000)
    } catch (error) {
      setLoading(false)
      setErr(error?.response?.data?.message || error.message)
      setTimeout(() => {
        setErr(null)
      }, 3000)
    }
  }

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      onBackdropClick={handleBackdropClick}
      disableEscapeKeyDown
      maxWidth="sm"
    >
      <DialogTitle>Reschedule Appointment</DialogTitle>

      <DialogContent sx={{ minWidth: 400 }}>
        <Stack pt={2} spacing={2}>
          <Stack direction={"row"} spacing={2}>
            <PricesInputSimple
              lable="Agreed Price"
              value={agreedPrice}
              onValueChange={setAgreedPrice}
            />
            <TextField
              fullWidth
              size="small"
              type={"date"}
              value={date == null ? null : moment(date).format("YYYY-MM-DD")}
              onChange={({ target }) => {
                setDate(moment(target.value).format("MM/DD/YYYY"))
              }}
            />
          </Stack>
          {date && (
            <Stack spacing={2}>
              <div>Select Time</div>
              <Stack direction={"row"} gap={1} flexWrap="wrap">
                {timePicker.map((item, i) => (
                  <Chip
                    key={`app-time-${item.format + i}`}
                    label={item.start}
                    color={time === item.format ? "primary" : "default"}
                    onClick={() => setTime(item.format)}
                  />
                ))}
              </Stack>
            </Stack>
          )}
        </Stack>
      </DialogContent>
      <DialogActions sx={{ py: 2 }}>
        <Button disabled={loading} onClick={handleClose}>
          Cancel
        </Button>
        {date && time && app && (
          <Button disabled={loading} variant="contained" onClick={submitUpdate}>
            {loading ? "Saving..." : "Reschedule"}
          </Button>
        )}
      </DialogActions>
    </Dialog>
  )
}
