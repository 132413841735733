import * as React from "react"
import Button from "@mui/material/Button"
import TextField from "@mui/material/TextField"
import Dialog from "@mui/material/Dialog"
import DialogActions from "@mui/material/DialogActions"
import DialogContent from "@mui/material/DialogContent"
import DialogContentText from "@mui/material/DialogContentText"
import DialogTitle from "@mui/material/DialogTitle"

export default function PopUpDialog({ open, setOpen, value, onChange }) {
  //"backdropClick" | "escapeKeyDown"
  const handleClose = (reason) => {
    // if (reason === "backdropClick") {
    //   console.log(reason)
    // } else {
    //   setOpen(false)
    // }
    setOpen(false)
  }

  const handleBackdropClick = (event) => {
    event.stopPropagation()
    return false
  }

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      onBackdropClick={handleBackdropClick}
      //disableEscapeKeyDown
      maxWidth="lg"
    >
      <DialogTitle>Add Note</DialogTitle>

      <DialogContent sx={{ minWidth: 400 }}>
        <DialogContentText>
          Add details about what type of new vehicle the seller is looking to
          purchase
        </DialogContentText>
        <TextField
          autoFocus
          margin="dense"
          id="note"
          label="Note"
          type="text"
          fullWidth
          variant="outlined"
          multiline
          rows={4}
          value={value}
          onChange={(e) => onChange(e.target.value)}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Finish</Button>
      </DialogActions>
    </Dialog>
  )
}
