import { Avatar, Badge, Button, Stack, Tooltip } from "@mui/material"
import React from "react"
import AddCircleIcon from "@mui/icons-material/AddCircle"
import { uploadDealershipLogo } from "../../helppers/images/profileImage"

export default function ImageUploader({
  dealership,
  setDealership,
  isLoading,
}) {
  const [loading, setLoading] = React.useState(false)
  const imgRef = React.useRef()

  const handleImageUpload = async (file) => {
    if (!file) return
    setLoading(true)
    try {
      await uploadDealershipLogo(
        file,
        dealership?.logo,
        setDealership,
        dealership?._id
      )
    } catch (error) {
      console.log(error)
    } finally {
      setLoading(false)
      if (imgRef.current) {
        imgRef.current.value = ""
        imgRef.current.files = []
      }
    }
  }

  return (
    <Stack direction={"row"} alignItems={"center"} spacing={2}>
      {dealership?.logo?.url && (
        <div>
          <img
            src={dealership?.logo?.url}
            style={{ height: 50, width: "auto", opacity: loading ? 0.5 : 1 }}
            alt="logo"
          />
        </div>
      )}
      <Tooltip title="Upload image formats .png/.jpg/.jpeg" placement="right">
        <Button
          color="success"
          aria-label="upload picture"
          component="label"
          variant="contained"
          startIcon={<AddCircleIcon />}
          size="small"
          disabled={loading || isLoading}
        >
          <input
            hidden
            ref={imgRef}
            onChange={(e) => handleImageUpload(e.target.files[0])}
            accept="image/png, image/jpeg"
            type="file"
            disabled={loading || isLoading}
          />
          Upload Logo
        </Button>
      </Tooltip>
    </Stack>
  )
}
