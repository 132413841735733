import { Grid, Stack } from "@mui/material"
import React, { useMemo } from "react"
import CheckMarkSelect from "./CheckMarkSelect"
import NumberRange from "./NumberRange"
import YearRange from "./YearRange"

export default function DealershipCriteria({
  criteria,
  setCreiteria,
  isModal = true,
}) {
  const excludlist = useMemo(() => {
    return [{ name: "Moderate" }, { name: "Severe" }].filter(
      (i) => !criteria?.carfax?.includes(i.name)
    )
  }, [criteria?.carfax])

  const cfxlist = useMemo(() => {
    return [
      { name: "Accident Reported" },
      { name: "Clean" },
      { name: "Minor" },
      { name: "Moderate" },
      { name: "Severe" },
    ].filter((i) => !criteria?.exclude?.includes(i.name))
  }, [criteria?.exclude])

  return (
    <Stack sx={{ p: isModal ? 2 : 0, width: "100% !important" }} spacing={2}>
      {isModal && <div>Buying Criteria</div>}
      <Grid sx={{ width: "100% !important" }} container columns={3} spacing={2}>
        <Grid item sm={3} md={1}>
          <YearRange selected={criteria?.year} setCreiteria={setCreiteria} />
        </Grid>
        <Grid item sm={3} md={1}>
          <NumberRange
            xkey={"price"}
            sign={true}
            title="Price"
            selected={criteria?.price}
            setCreiteria={setCreiteria}
          />
        </Grid>
        <Grid item sm={3} md={1}>
          <NumberRange
            xkey={"mileage"}
            title="Mileage"
            selected={criteria?.mileage}
            setCreiteria={setCreiteria}
          />
        </Grid>
      </Grid>
      <CheckMarkSelect
        data={cfxlist}
        selected={criteria?.carfax}
        setCreiteria={setCreiteria}
        title="Select CarFax"
        xkey="carfax"
      />
      {excludlist?.length > 0 && (
        <CheckMarkSelect
          data={excludlist}
          selected={criteria?.exclude}
          setCreiteria={setCreiteria}
          title="Always Exclude"
          xkey="exclude"
        />
      )}
      <CheckMarkSelect
        data={[
          { name: "Title In Hand" },
          { name: "Loan on Vehicle" },
          { name: "Lease on Vehicle" },
        ]}
        selected={criteria?.titleStatus}
        setCreiteria={setCreiteria}
        title="Select Title Status"
        xkey="titleStatus"
      />
    </Stack>
  )
}
