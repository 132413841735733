import axios from "axios"
import { URL } from "../config/apis"

export const moveToTrash = async (
  id,
  setLead,
  setErr,
  setLoading,
  setOpen,
  isTrash,
  setActivity
) => {
  setLoading(true)
  try {
    const res = await axios.put(
      `${URL}/leads/item/${id}/trash`,
      { isTrash: !isTrash },
      {
        headers: {
          "Content-Type": "application/json",
        },
        withCredentials: true,
      }
    )
    if (res.data?.ok) {
      setLead((prev) => ({ ...prev, isTrash: res.data.isTrash }))
      setActivity((prev) => [res.data.activity, ...prev])
      setLoading(false)
      setOpen(true)
      setTimeout(() => {
        setOpen(false)
      }, 2000)
    }
    setLoading(false)
  } catch (error) {
    setLoading(false)
    setErr(error.response?.data?.message)
    setTimeout(() => {
      setErr(null)
    }, 3000)
  }
}

export const moveTrash = async (
  id,
  setLeads,
  setErr,
  setLoading,
  setOpen,
  getLeads
) => {
  setLoading(true)
  try {
    const res = await axios.put(
      `${URL}/leads/item/${id}/trash`,
      { isTrash: true },
      {
        headers: {
          "Content-Type": "application/json",
        },
        withCredentials: true,
      }
    )
    if (res.data?.ok) {
      setLeads((prev) => [...prev?.filter((l) => l?._id !== id)])
      setLoading(false)
      setOpen(true)
      getLeads()
      setTimeout(() => {
        setOpen(false)
      }, 2000)
    }
    setLoading(false)
  } catch (error) {
    setLoading(false)
    setErr(error.response?.data?.message)
    setTimeout(() => {
      setErr(null)
    }, 3000)
  }
}

export const moveToLostSold = async (
  id,
  setLead,
  setErr,
  setLoading,
  setOpen,
  isLost,
  setActivity,
  reason,
  setReason,
  setLOpen
) => {
  if (isLost === false && (!reason || reason?.length < 1)) {
    setErr("Please Select Lost reason")
    return setTimeout(() => {
      setErr(null)
    }, 3000)
  }
  setErr(null)
  setLoading(true)
  try {
    const res = await axios.put(
      `${URL}/leads/item/${id}/lost`,
      { isLost: !isLost, reason },
      {
        headers: {
          "Content-Type": "application/json",
        },
        withCredentials: true,
      }
    )
    if (res.data?.ok) {
      setLead((prev) => ({ ...prev, isLost: res.data.isLost }))
      setActivity((prev) => [res.data.activity, ...prev])
      setLoading(false)
      setReason("")
      setOpen(true)
      setLOpen(false)
      setTimeout(() => {
        setOpen(false)
      }, 2000)
    }
    setLoading(false)
  } catch (error) {
    setLoading(false)
    setErr(error.response?.data?.message || error?.message)
    setTimeout(() => {
      setErr(null)
    }, 3000)
  }
}

export const markLostSold = async (
  id,
  setLeads,
  setErr,
  setLoading,
  setOpen,
  getLeads
) => {
  setLoading(true)
  try {
    const res = await axios.put(
      `${URL}/leads/item/${id}/lost`,
      { isLost: true },
      {
        headers: {
          "Content-Type": "application/json",
        },
        withCredentials: true,
      }
    )
    if (res.data?.ok) {
      setLeads((prev) => [...prev?.filter((l) => l?._id !== id)])
      setLoading(false)
      setOpen(true)
      getLeads()
      setTimeout(() => {
        setOpen(false)
      }, 2000)
    }
    setLoading(false)
  } catch (error) {
    setLoading(false)
    setErr(error.response?.data?.message)
    setTimeout(() => {
      setErr(null)
    }, 3000)
  }
}

export const claimLead = async (
  id,
  setLead,
  setErr,
  setLoading,
  setOpen,
  setActivity
) => {
  setLoading(true)
  try {
    const res = await axios.put(
      `${URL}/leads/item/${id}/claim`,
      {},
      {
        headers: {
          "Content-Type": "application/json",
        },
        withCredentials: true,
      }
    )
    if (res.data?.ok) {
      setLead((prev) => ({ ...prev, claimer: res.data.userId }))
      setActivity((prev) => [res.data.activity, ...prev])
      setLoading(false)
      setOpen(true)
      setTimeout(() => {
        setOpen(false)
      }, 2000)
    }
    setLoading(false)
  } catch (error) {
    setLoading(false)
    setErr(error.response?.data?.message)
    setTimeout(() => {
      setErr(null)
    }, 3000)
  }
}

export const unClaimLead = async (
  id,
  setLead,
  setErr,
  setLoading,
  setOpen,
  setActivity
) => {
  setLoading(true)
  try {
    const res = await axios.put(
      `${URL}/leads/item/${id}/unclaim`,
      {},
      {
        headers: {
          "Content-Type": "application/json",
        },
        withCredentials: true,
      }
    )
    if (res.data?.ok) {
      setLead((prev) => ({ ...prev, claimer: null }))
      setActivity((prev) => [res.data.activity, ...prev])
      setLoading(false)
      setOpen(true)
      setTimeout(() => {
        setOpen(false)
      }, 2000)
    }
    setLoading(false)
  } catch (error) {
    setLoading(false)
    setErr(error.response?.data?.message)
    setTimeout(() => {
      setErr(null)
    }, 3000)
  }
}
