import React from "react"
import { useMemo } from "react"
import useData from "../../context/Data"

export default function DealershipsBox({ id }) {
  const { dealerships } = useData()
  const dealership = useMemo(() => {
    return dealerships?.find((a) => a?._id === id)
  }, [id, dealerships])

  return <b>{dealership?.dname}:</b>
}
