import { Stack, Typography } from "@mui/material"
import React from "react"
import Radio from "@mui/material/Radio"
import RadioGroup from "@mui/material/RadioGroup"
import FormControlLabel from "@mui/material/FormControlLabel"
import FormControl from "@mui/material/FormControl"
import FormLabel from "@mui/material/FormLabel"
import ResponseClarification from "./ResponseClarification"

export default function SingleResponseByButtons({
  responseOptions,
  handleChange,
  category,
  handleChangeResp,
  handleChangeText,
}) {
  return (
    <FormControl>
      {/* <FormLabel id={question?.id}>{question?.text}</FormLabel> */}
      {/* {question.id === "TEST_DRIVE_ISSUES" && (
        <small>{question?.tooltips[0]?.verbiage}</small>
      )} */}
      <RadioGroup
        onChange={(e) => handleChange(e.target.value, category)}
        //aria-labelledby={question?.id}
        //  name={question?.text}
        // value={val}
      >
        {responseOptions &&
          responseOptions?.map((rep, i) => (
            <>
              <FormControlLabel
                value={rep?.id}
                control={<Radio />}
                label={rep?.text}
                key={rep?.id + i}
                checked={rep?.isSelected}
              />
              {/* {rep?.isVehicleIneligibleIfSelected && rep?.isSelected && (
                <small style={{ color: "red", paddingLeft: 20 }}>
                  {rep?.inelibilityErrorMessage}
                </small>
              )} */}
              {!!rep?.responseClarification && rep?.isSelected && (
                <ResponseClarification
                  question={rep?.responseClarification}
                  key={rep?.id + i + "clar"}
                  category={category}
                  questionId={""}
                  handleChangeResp={handleChangeResp}
                  repId={rep?.id}
                  handleChangeText={handleChangeText}
                />
              )}
            </>
          ))}
      </RadioGroup>
    </FormControl>
  )
}
